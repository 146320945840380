import logo from '../Shared/logo.svg'

function LoginLogoutContainer(props) {
    const Width = props.Width;

    return (
        <div className='loginContainer mt5'>
            <div className='bg-white ba b--primary shadow-4' style={{width: Width}}>
                <div className="flex justify-center">
                    <div>
                        <div className="flex flex-row items-end mt5">
                            <img src={logo} alt='ACER logo'/>
                            <div className="f4 primary ml3">Workflow</div>
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginLogoutContainer;

