import GreenTick from '../Shared/GreenTick'

function ImportantAction(props) {

    const actionDescription = props.actionDescription
    const status = props.status

    return (
        <div className='flex flex-row items-center h2'>
            <div className='flex flex-row'>
                <div className='ml2 mr1'>&#8226;</div>
                <div>{actionDescription}</div>
            </div>
            {status === 'loading' &&
                <div className="nowLoading mb2 ml2">
                    <span className="primary f4">.</span>
                    <span className="primary f4">.</span>
                    <span className="primary f4">.</span>
                </div>}
            {status === 'done' &&
                <div className='mt1 mb1 ml1'>
                    <GreenTick />
                </div>}
            {status === 'blank' &&
                <div className='mt1 mb1 ml1'>
                </div>}
        </div>
    )
}

export default ImportantAction