import AppService from './AppService'
const baseURL = '/api/tasks'
const API = AppService.initAPI(baseURL)

const Services = {
    view: async function (id) {
        return await API.get(`/${id}`)
    },

    find: async function (params) {
        return await API.get(``, { params })
    },

    delete: async function (id) {
        return await API.delete(`/${id}`)
    },

    create: async function (body) {
        return await API.post(``, body)
    },

    update: async function (id, body) {
        return await API.put(`/${id}`, body)
    },
    findTargetDates: async function (params) {
        return await API.get(`/target-dates`, { params })
    },

    deleteTargetDate: async function (id, stepUuid) {
        return await API.delete(`/${id}/target-dates/${stepUuid}`)
    },

    updateTargetDate: async function (id, stepUuid, body) {
        return await API.put(`/${id}/target-dates/${stepUuid}`, body)
    },

    bulkUpdateTargetDates: async function (body) {
        return await API.post(`upload/target-dates`, body)
    },

    uploadTasksDueDates: async function (body) {
        return await API.post(`upload/tasks-due-dates`, body)
    },
};
export default Services