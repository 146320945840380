import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Modal from '../../../Shared/Modal'
import addManyTasksExample from './addManyTasksExample.png'
import CreateCancelSubmitLoading from '../../../Shared/CreateCancelSubmitLoading'
import Papa from 'papaparse';
import { createBulkTasksService } from '../../../Services/WorkflowAppServices'

function AddManyTasksModal(props){

    const fileInputTaskUpload = useRef(null)
    const setRenderManyNewTasksModal = props.setRenderManyNewTasksModal
    const [csvData, setCsvData] = useState(null)
    const [clickedSubmit, setClickedSubmit] = useState(false)
    const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false)    
    const [errorMessage, setErrorMessage] = useState('')
    const { projectName } = useParams()
    const fetchGroupsAndWorkflows = props.fetchGroupsAndWorkflows

    function handleCSVUpload(){
        cleanUpErrorMessages()
        if (fileInputTaskUpload.current){
            Papa.parse(fileInputTaskUpload.current.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    let bulkTasksList = []
                    for (let i = 0; i < results.data.length; i++) {
                        if ("Task name" in results.data[i] && "Workflow name" in results.data[i] && "Group name" in results.data[i]) {
                            bulkTasksList.push({
                                taskName: results.data[i]["Task name"].trim(),
                                workflowName: results.data[i]["Workflow name"].trim(),
                                groupName: results.data[i]["Group name"].trim(),
                                localeName: results.data[i]["Locale"]?.trim(),
                                categoryName: results.data[i]["Category"]?.trim()
                            })
                        } else {
                            setIsSomethingWentWrong(true)
                            setErrorMessage('Invalid fields in the CSV file.')
                        }                      
                    }
                    setCsvData(bulkTasksList)
                }
              });
        }
    }

    async function handleCreateBulkTasks() {
        if (csvData) {
            setClickedSubmit(true)
            try {
                const response = await createBulkTasksService(csvData, projectName)
                if (response && response.status === 201) {
                    setClickedSubmit(false)
                    setRenderManyNewTasksModal(false)
                    fetchGroupsAndWorkflows()
                }
            } 
            catch (error) {
                setIsSomethingWentWrong(true)
                setErrorMessage(error.response.data.messageHTML)
                setClickedSubmit(false)
            }
            
        }
    }

    function cleanUpErrorMessages() {
        setIsSomethingWentWrong(false)
        setErrorMessage('')
    }

    return (
        <Modal modalTitle='Add many new tasks' onClickClose={_ => setRenderManyNewTasksModal(false) }>
            <div>
                <p>Create a spreadsheet with the following structure.</p>
                <img src={addManyTasksExample} alt='bulk upload tasks csv example'/>
                <p>Save the spreadsheet as a <strong>.csv</strong> file and upload.</p>
                <input className='' type='file' name='TaskUploadCSV' ref={fileInputTaskUpload} onChange={handleCSVUpload} accept='.csv'/>
                <div className='mt3 mb2 white'>This is a feedback message about the upload</div>
                <CreateCancelSubmitLoading 
                    isSomethingWentWrong={isSomethingWentWrong}
                    isNowLoading={clickedSubmit}
                    createButtonText='Submit'
                    errorMessage={errorMessage}
                    handleCreateResourceClick={handleCreateBulkTasks}
                    handleCloseErrorBoxClick={cleanUpErrorMessages}
                    buttonPosition='right'
                />
            </div>
        </Modal>
        
    )
}

export default AddManyTasksModal
