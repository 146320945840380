import { Link } from 'react-router-dom'

function ProjectInstance(props) {

    const project = props.project
    const dateCreated = new Date(props.project.createdAt)
    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
    return (
        <div className='bg-solid-primary flex ba mt4 br2'>
            <div className='flex items-center ph3 gap-6 flex-direction-column'>
                <div className='white' style={{ width: '275px', wordBreak: 'break-all' }}><b>Project name:</b> {project.projectNameDisplayed ?? project.projectName}</div>
                <div className='white'><b>Created on:</b> {dateCreated.toLocaleDateString(undefined, dateOptions)}</div>
                <Link to={`/${project.projectName}`} className='project-instance' style={{ textDecoration: 'none' }}>
                    <p className='white b--white pointer ba pa2 show-details b bw1 br2'>Choose Project</p>
                </Link>
            </div>
        </div>
    )
}

export default ProjectInstance