import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './tachyons.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';

import './index.css';

import AuthenticationApp from './Authentication/AuthenticationApp';

import MainMenuBar from './Menu/MainMenuBar';
import ProjectConfigs from './Workflow/Project/ProjectConfigs.js'
import ProjectList from './Workflow/ProjectList/ProjectList'
import IndexPage from './Workflow/IndexPage/IndexPage'

import TasksList from './TaskView/TasksList.js'
import TaskInstance from './TaskView/TaskInstance/TaskInstance'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'


// TO DEMO
// File upload permissions
// Code commit API (mostly AI generated)

// QUESTIONS
// Repo structure, getting this working with OmegaT
// Sample repos somewhere?
// Windows machine

// TODO
// Code commit APIs
// Connection between workflow and preview

// remove app permissions, calculate from role permissions
// improve view summary modal
// Bulk upload tasks
// server side permissions
// pull out custom hooks, refactor
// refactor services
// santize inputs, regex... throughout
// refactor services

// pagination of users and tasks

// TODO styling
// consistent spacing for tables throughout
// same height for user assignment, role assignment tables, consistent throughout
// align edit, from, to, and cross.
// a little more vertical padding for the workflow step boxes
// increase spacing above table when table height min height increases

// make task summary a table

const queryClient = new QueryClient()

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/signin" element={<AuthenticationApp />} />
          <Route path="/" element={<MainMenuBar />}>
            <Route path="/:projectName/tasks/:taskUuid" element={<TaskInstance />} />
            <Route path="/:projectName/tasks/*" element={<TasksList />} />
            <Route path="/:projectName/workflow-management/*" element={<ProjectConfigs />} />
            <Route path="/:projectName" element={<IndexPage />} />
            <Route index element={<ProjectList />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);