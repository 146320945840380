import NowLoading from "./NowLoading"
import addNew from './addNew.svg'

function AddNew(props){

    const isLoading = props.isLoading
    const onClick = props.onClick
    const width = props.width
    const label = props.label

    return (
        <div className='flex flex-row' style={{height: '35px', width}}>
            {!isLoading &&
                <div className='mt3 pointer add-new flex items-center' style={{height: '35px'}} onClick={onClick}>
                    <img src={addNew} width='20px' alt='add new'/>
                    <label className='dark-grey ml1'>{label}</label>
                </div>}
            {isLoading &&
                <div className='flex justify-center' style={{width}}>
                    <NowLoading />
                </div>}
        </div>
    )
}

export default AddNew