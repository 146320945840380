import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import Services from '../Services/DefaultTargetDatesService'

export const useDefaultTargetDates = (projectUuid) => {
    const queryClient = useQueryClient()
    const getDefaultTargetDatesQuery = useQuery({
        queryKey: ['default-target-dates', projectUuid],
        queryFn: async () => {
            const response = await Services.find({ projectUuid })
            return response.data
        },
        staleTime: 1000,
        refetchOnWindowFocus: false,
    })

    const upsertDefaultTargetDatesMutation = useMutation({
        mutationFn: async ({ payload }) => { await Services.upsert({ projectUuid, ...payload }) },
        onSuccess: () => {
            queryClient.invalidateQueries(['default-target-dates', projectUuid]);
        },
    })

    const upsertDefaultTargetDates = ({ payload, onSuccess, onError }) => {
        upsertDefaultTargetDatesMutation.mutate({ payload }, { onSuccess, onError })
    }

    const uploadDefaultTargetDatesMutation = useMutation({
        mutationFn: async ({ payload }) => { await Services.upload({ projectUuid, ...payload }) },
        onSuccess: () => {
            queryClient.invalidateQueries(['default-target-dates', projectUuid]);
        },
    })

    const uploadDefaultTargetDates = ({ payload, onSuccess, onError }) => {
        uploadDefaultTargetDatesMutation.mutate({ payload }, { onSuccess, onError })
    }

    const deleteDefaultTargetDatesMutation = useMutation({
        mutationFn: async ({ payload }) => { await Services.delete({ projectUuid, ...payload }) },
        onSuccess: () => {
            queryClient.invalidateQueries(['default-target-dates', projectUuid]);
        },
    })

    const deleteDefaultTargetDates = ({ payload, onSuccess, onError }) => {
        deleteDefaultTargetDatesMutation.mutate({ payload }, { onSuccess, onError })
    }
    return {
        defaultTargetDates: getDefaultTargetDatesQuery.data,
        loading: getDefaultTargetDatesQuery.isLoading,
        upsertDefaultTargetDates,
        uploadDefaultTargetDates,
        deleteDefaultTargetDates
    }
}