import saveIcon from './saveIcon.svg'

function SaveButton(props){

    const isNowLoading = props.isNowLoading
    const onClick = props.onClick
    const tooltipText = props.tooltipText
    const disableBorder = props.disableBorder

    let border
    disableBorder ? border = '' : border = 'ba'
    
    return (
        <div className={`tc pt1 b--moon-gray pointer tooltip ${border}`} style={{width: '25px', height: '30px'}}>
            {!isNowLoading && 
                <>
                    <span style={{width: '25px'}} className='save-button' onClick={onClick}>
                        <img src={saveIcon} width='16px' height='16px' alt='save icon'/>
                    </span>
                    <span className='tooltiptext f6 b'>{tooltipText}</span>
                </>}
            {isNowLoading &&
            <div className='flex justify-center'>
                <div className="nowLoading" style={{width: '16px'}}>
                    <span className="primary f6">.</span>
                    <span className="primary f6">.</span>
                    <span className="primary f6">.</span>
                </div>
            </div>}
        </div>
    )
}

export default SaveButton