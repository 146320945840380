import React from 'react';

const PrimaryButton = React.forwardRef((props, ref) => {
    const { onClick, buttonText, noClick } = props

    return (
        <button
            ref={ref}
            className={`bg-primary ba white pa1 pl3 pr3 b--primary grow br2 ${noClick ? '' : 'pointer'} primary-button`}
            onClick={noClick ? () => { } : onClick}
        >
            {buttonText}
        </button>
    )
})

export default PrimaryButton;