import cross from './cross.svg'

function Cross(props){

    const onClick = props.onClick
    
    return (
        <div className='pointer cross-button' style={{width: '20px'}} onClick={onClick}>
            <img src={cross} alt='close'/>
        </div>
    )
}

export default Cross