function ActionsList(props){

    const actionsList = props.actionsList
        .map(action => {
            return action.label
        })
        .sort()
        .map(label => {
            return <>{label} <br /></>
        })

    return (
        <div className='f6 flex flex-row mt1'>
            <span className='b ml1 workflow-config-step-title'>
                Actions list:
            </span>
            <span className='ml1 workflow-config-step-value'>
                {actionsList}
            </span>
        </div>
    )
}

export default ActionsList