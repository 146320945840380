import { useState, useRef } from 'react'
import PrimaryButton from '../../Shared/PrimaryButton'
import { getFileService, deleteFileService } from '../../Services/TaskViewServices'
import { regenerateDiffreportService } from '../../Services/CodeCommitServices'
import { Toast } from 'primereact/toast'

function FileInstance(props) {
    const file = props.file
    const fetchTask = props.fetchTask
    const step = props.step
    const setError = props.setError
    const taskUuid = props.taskUuid
    const workflowSteps = props.workflowSteps
    const projectPermissions = props.projectPermissions
    const projectName = props.projectName
    const toast = useRef(null)

    let date = ''

    if (file.key && file.key.startsWith('diff')) {
        const fileKeyArray = file.key.split('_').reverse()
        const timestamp = parseInt(fileKeyArray[0]);
        const dateObj = new Date(timestamp);
        date = dateObj.toLocaleDateString()
    } else if (file.key) {
        const fileKeyArray = file.key.split('.');
        const timestamp = parseInt(fileKeyArray[0]);
        const dateObj = new Date(timestamp);
        date = dateObj.toLocaleDateString()
    }

    const [isNowLoading, setIsNowLoading] = useState(false)
    const [isDiffLoading, setIsDiffLoading] = useState(false)

    const fromStepName = workflowSteps.find(workflowStep => workflowStep.stepUuid === step.fromStepUuid).stepName
    const toStepName = workflowSteps.find(workflowStep => workflowStep.stepUuid === step.toStepUuid).stepName

    async function handleFileAction(action = 'download') {
        try {
            setIsNowLoading(true)
            setError(false)

            let serviceProps = {
                fileKey: file.key,
                taskUuid
            }

            if (action === 'download') {
                let response = await getFileService(serviceProps)
                window.open(response.data)
            } else {
                await deleteFileService(serviceProps)
                await fetchTask()
            }
        } catch (err) {
            console.error(err)
            setError(err.response.data)
        } finally {
            setIsNowLoading(false)
        }
    }

    async function handleRegenerateDiffReport() {
        try {
            setError(false)
            setIsDiffLoading(true)
            let serviceProps = {
                fileKey: file.key,
                taskUuid,
                projectName
            }

            const response = await regenerateDiffreportService(serviceProps)
            if (response.status === 200) {
                toast && toast.current.show({ severity: 'success', summary: 'Success', detail: 'Diff report is regenerating, please wait for a couple of minutes.' })
            }
        } catch (err) {
            console.error(err)
            setError(err.response.data)
        } finally {
            setIsDiffLoading(false)
        }
    }

    const isDiffReport = file.originalname.startsWith('diff_report')

    return (
        <>
            <Toast ref={toast} />
            <tr>
                <td>{file.originalname}</td>
                <td>
                    {`${fromStepName} \u279c ${toStepName}`}
                </td>
                <td>
                    {date}
                </td>
                <td>
                    <div className='flex justify-center gap-2'>
                        <div className='flex justify-center gap-2' style={{ whiteSpace: 'nowrap', height: '27px' }}>
                            {!isNowLoading &&
                                <PrimaryButton buttonText='Download' onClick={() => handleFileAction('download')} />}
                            {!isNowLoading && projectPermissions?.canDeleteTaskFiles &&
                                <PrimaryButton buttonText='Delete' onClick={() => handleFileAction('delete')} />}
                            {isNowLoading &&
                                <div>
                                    <div className='nowLoading f4'>
                                        <span>.</span>
                                        <span>.</span>
                                        <span>.</span>
                                    </div>
                                </div>
                            }
                        </div>
                        {isDiffReport && projectPermissions?.canRegenerateDiff && <div className='flex justify-center' style={{ whiteSpace: 'nowrap', height: '27px' }}>
                            {!isDiffLoading &&
                                <PrimaryButton buttonText='Regenerate diff report' onClick={handleRegenerateDiffReport} />}
                            {isDiffLoading &&
                                <div>
                                    <div className='nowLoading f4'>
                                        <span>.</span>
                                        <span>.</span>
                                        <span>.</span>
                                    </div>
                                </div>
                            }
                        </div>}
                    </div>
                </td>
            </tr>
        </>
    )
}

export default FileInstance
