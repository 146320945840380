import { useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import IsNowLoading from '../../../Shared/IsNowLoading'
import WorkflowName from './WorkflowName'
import StepLibraryStep from './StepLibraryStep'
import WorkflowBuilderStep from './WorkflowBuilderStep'
import DeleteButton from '../../../Shared/DeleteButton'
import { alphabeticalSortByKey } from '../../../Shared/sortHelper'
import Modal from '../../../Shared/Modal'

import {
    getWorkflowBuilderStateService,
    deleteWorkflowService
} from '../../../Services/WorkflowAppServices'
import EmailTemplateService from '../../../Services/EmailTemplateService'

function Workflow(props) {

    const navigateTo = useNavigate()

    const { projectName, workflowName } = useParams()
    const projectUuid = props.project.projectUuid

    const [workflow, setWorkflow] = useState({ assignedSteps: [], connections: [] })
    const [allWorkflowSteps, setAllWorkflowSteps] = useState([])
    const [workflowStepLibraryList, setWorkflowStepLibraryList] = useState([])
    const [projectPermissions, setProjectPermissions] = useState({})
    const [roles, setRoles] = useState([])
    const [messages, setMessages] = useState([])
    const [emailTemplateOptions, setEmailTemplateOptions] = useState([{ value: 'default', label: 'default' }])

    const [isNowLoading, setIsNowLoading] = useState(true)
    const [isNowLoadingDelete, setIsNowLoadingDelete] = useState(false)
    const [renderCannotDeleteModal, setRenderCannotDeleteModal] = useState(false)
    const [moveStepLoading, setMoveStepLoading] = useState(false)

    const fetchEmailTemplates = useCallback(async _ => {
        try {
            const response = await EmailTemplateService.find({ projectUuid: projectUuid })
            const templates = response.data
            const emailTemplateOptionsTemp = templates.map(template => {
                return { value: template.templateUuid, label: template.templateName }
            })
            emailTemplateOptionsTemp.unshift({ value: 'default', label: 'default' })
            setEmailTemplateOptions(emailTemplateOptionsTemp)
        } catch (error) {
            console.error(error)
        }
    }, [projectUuid])

    useEffect(_ => {
        fetchEmailTemplates()
    }, [fetchEmailTemplates])

    async function handleDeleteWorkflowClick() {
        setIsNowLoadingDelete(true)
        let workflowUuid = workflow.workflowUuid
        let serviceProps = { projectName, workflowUuid }

        let response = await deleteWorkflowService(serviceProps)

        if (response.status === 200) {
            if (response.data.canDelete) {
                setIsNowLoadingDelete(false)
                navigateTo(`/${projectName}/workflow-management/workflows`)
            } else {
                setRenderCannotDeleteModal(true)
                setIsNowLoadingDelete(false)
            }
        }
    }

    const fetchWorkflowBuilderState = useCallback(async _ => {
        let serviceProps = { projectName, workflowName }
        let response = await getWorkflowBuilderStateService(serviceProps)

        if (response.status === 200) {
            let workflowStepsTemp = response.data.workflowSteps
            let workflowTemp = response.data.workflow
            let rolesTemp = response.data.roles
            let projectPermissionsTemp = response.data.projectPermissions
            let messagesTemp = response.data.messages

            setMessages(messagesTemp)

            setAllWorkflowSteps(workflowStepsTemp)

            workflowStepsTemp = workflowStepsTemp.filter(workflowStep => !workflowTemp.assignedSteps.includes(workflowStep.stepUuid))
            setWorkflowStepLibraryList(alphabeticalSortByKey(workflowStepsTemp, 'stepName'))
            setWorkflow(workflowTemp)
            setRoles(rolesTemp)
            setProjectPermissions(projectPermissionsTemp)


            setIsNowLoading(false)
        }
    }, [projectName, workflowName])

    useEffect(_ => {
        fetchWorkflowBuilderState()
    }, [fetchWorkflowBuilderState])

    let workflowStepLibraryListRender = workflowStepLibraryList.map(step => {
        return (
            <StepLibraryStep
                key={step._id}
                step={step}
                projectPermissions={projectPermissions}
                fetchWorkflowBuilderState={fetchWorkflowBuilderState} />
        )
    })

    let workflowRender = workflow.assignedSteps.map((stepUuid, index) => {
        return (
            <WorkflowBuilderStep
                key={stepUuid}
                roles={roles}
                messages={messages}
                index={index + 1}
                projectUuid={projectUuid}
                workflowUuid={workflow.workflowUuid}
                projectPermissions={projectPermissions}
                assignedSteps={workflow.assignedSteps}
                step={allWorkflowSteps.find(step => step.stepUuid === stepUuid)}
                allSteps={allWorkflowSteps}
                stepConnections={workflow.connections.filter(connection => connection.fromStep === stepUuid)}
                fetchWorkflowBuilderState={fetchWorkflowBuilderState}
                moveStepLoading={moveStepLoading}
                setMoveStepLoading={setMoveStepLoading}
                emailTemplateOptions={emailTemplateOptions}
            />
        )
    })

    return (
        <>
            {renderCannotDeleteModal &&
                <Modal
                    modalTitle='Cannot delete workflow'
                    onClickClose={_ => { setRenderCannotDeleteModal(false) }}>
                    <p>The workflow <strong>{workflowName}</strong> is assigned to at least one task.</p>
                    <p>Please delete tasks that have this workflow assigned and then try again.</p>
                </Modal>}
            <IsNowLoading isNowLoading={isNowLoading}>
                <div className='flex justify-center' style={{ marginTop: '40px' }}>
                    <div className='ba bw1 pa1 b--secondary flex flex-column'>
                        <div className='flex justify-between ma1'>
                            <WorkflowName
                                workflowName={workflowName}
                                projectPermissions={projectPermissions}
                                workflowUuid={workflow.workflowUuid} />
                            {projectPermissions?.canDeleteWorkflows &&
                                <div className='delete-workflow-button'>
                                    <DeleteButton
                                        onClick={handleDeleteWorkflowClick}
                                        tooltipText={<span>&#9888; Permanently delete {workflowName}</span>}
                                        isNowLoading={isNowLoadingDelete}
                                        projectPermissions={projectPermissions} />
                                </div>}
                        </div>
                        <div className='flex flex-row'>
                            <div>
                                <div className='mt2 b ma1 primary'>Step library</div>
                                <div className='bw1 b--secondary ba' style={{ width: '300px', height: '70vh', overflowY: 'scroll' }}>
                                    {workflowStepLibraryListRender}
                                </div>
                            </div>
                            <div className='flex justify-center items-center' style={{ width: '75vw' }}>
                                <div className='flex flex-row flex-wrap ml2 mr2'>
                                    {workflowRender}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IsNowLoading>
        </>
    )
}

export default Workflow