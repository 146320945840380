import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputNumber } from 'primereact/inputnumber';
import { FilterMatchMode } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import Papa from 'papaparse';
import moment from 'moment';
import { useDefaultTargetDates } from '../../../hooks/useDefaultTargetDates';
import { useTasks } from '../../../hooks/useTasks';
import { useWorkflowSteps } from '../../../hooks/useWorkflowSteps';
import { useWorkflows } from '../../../hooks/useWorkflows';
import defaultTargetDatesExample from './defaultTargetDatesExample.png'
import { validateFieldsStartChar } from '../../../Shared/validators';

const DefaultTargetDatesTable = (props) => {
    const projectUuid = props.project.projectUuid
    const { defaultTargetDates, upsertDefaultTargetDates, uploadDefaultTargetDates, deleteDefaultTargetDates, loading } = useDefaultTargetDates(projectUuid)
    const { tasks } = useTasks(projectUuid)
    const { workflowSteps } = useWorkflowSteps(projectUuid)
    const { workflows } = useWorkflows(projectUuid)
    const [taskNameOptions, setTaskNameOptions] = useState([])
    const [csvData, setCsvData] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [workflowsInUse, setWorkflowsInUse] = useState([])
    const [stepsInUse, setStepsInUse] = useState([])
    const emptyItem = {
        taskName: null,
        workflowStepUuid: null,
        workflowUuid: null,
        targetDate: null,
        duration: 0
    }
    const fileUpload = useRef(null)
    const [item, setItem] = useState(emptyItem);
    const [itemDialog, setItemDialog] = useState(false);
    const [renderUploadDialog, setRenderUploadDialog] = useState(false)
    const toast = useRef(null);
    const [projectPermissions, setProjectPermissions] = useState({})

    const [filters] = useState({
        taskName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        stepName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });


    useEffect(() => {
        if (tasks) {
            setTaskNameOptions([...new Set(tasks.map(task => task.taskName))].map(taskName => ({ taskName })));
        }
    }, [tasks]);

    useEffect(() => {
        if (tasks && workflows && workflowSteps) {
            const workflowsInUse = workflows.filter(workflow => tasks.map(task => task.workflowUuid).includes(workflow.workflowUuid));
            const stepUuidsInUse = [...new Set(workflowsInUse.flatMap(workflow => workflow.assignedSteps))];
            const stepsInUse = workflowSteps.filter(step => stepUuidsInUse.includes(step.stepUuid))
            setWorkflowsInUse(workflowsInUse)
            setStepsInUse(stepsInUse)
        }
    }, [workflows, tasks, workflowSteps]);

    useEffect(() => {
        setProjectPermissions(props.projectPermissions)
    }, [props.projectPermissions]);

    const editItem = (item) => {
        setItem({ ...item });
        setIsEdit(true)
        setItemDialog(true);
    };
    const hideItemDialog = () => {
        setItem(emptyItem)
        setItemDialog(false);
        setIsEdit(false)
    };

    const saveItem = async () => {
        if (!item.taskName) {
            toast.current.show({ severity: 'error', summary: 'Task required', detail: `Please select a task`, life: 5000 });
            return
        }
        if (!item.workflowStepUuid) {
            toast.current.show({ severity: 'error', summary: 'Step required', detail: `Please select a step`, life: 5000 });
            return
        }
        if (!item.targetDate && !item.duration) {
            toast.current.show({ severity: 'error', summary: 'Duration or target date required', detail: `Please provide at least a duration or a target date.`, life: 5000 });
            return
        }
        const parsedMoment = moment(item.targetDate)

        upsertDefaultTargetDates({
            payload: {
                taskName: item.taskName,
                workflowStepUuid: item.workflowStepUuid,
                targetDate: item.targetDate && parsedMoment.isValid() ? parsedMoment.format('YYYY-MM-DD') + 'T00:00:00Z' : undefined,
                duration: item.duration,
                workflowUuid: item.workflowUuid
            },
            onSuccess: () => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Target date saved', life: 3000 });
            }
        })
        setItemDialog(false);
        setItem(emptyItem);
        setIsEdit(false)
    };

    const handleFileChanged = () => {
        if (fileUpload.current) {
            try {
                Papa.parse(fileUpload.current.files[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results) => {
                        let uploadedTargetDates = []
                        for (let i = 0; i < results.data.length; i++) {
                            const parsedMoment = moment(results.data[i].targetDate);
                            if (results.data[i].targetDate !== '' && !parsedMoment.isValid()) {
                                toast.current.show({ severity: 'error', summary: 'Invalid date format', detail: `${results.data[i].targetDate} is not support, please provide targetDate field in the csv file as ISO format (YYYY-MM-DD).`, life: 5000 });
                                fileUpload.current.value = null;
                                return
                            }
                            if (!Number.isInteger(Number(results.data[i].duration)) || Number(results.data[i].duration) < 0) {
                                toast.current.show({ severity: 'error', summary: 'Invalid duration', detail: `${results.data[i].duration} is not valid, duration field should be a positive integer`, life: 5000 });
                                fileUpload.current.value = null;
                                return
                            }
                            const result = validateFieldsStartChar(results.data[i])
                            if (!result.isValid) {
                                toast.current.show({ severity: 'error', summary: 'Invalid first character', detail: `The value of ${result.key} cannot starts with =, +, - or @: ${result.value}`, life: 5000 });
                                fileUpload.current.value = null;
                                return
                            }
                        }
                        results.data.forEach(columnData => {
                            if (columnData.targetDate !== '' || (columnData.duration !== '' && Number(columnData.duration) !== 0)) { // ignore the column if both duration and targetDate are empty
                                uploadedTargetDates.push({
                                    taskName: columnData.taskName,
                                    workflowStepUuid: columnData.workflowStepUuid,
                                    workflowUuid: columnData.workflowUuid,
                                    duration: columnData.duration === '' ? undefined : Number(columnData.duration),
                                    targetDate: columnData.targetDate === '' ? undefined : moment(columnData.targetDate).format('YYYY-MM-DD') + 'T00:00:00Z'
                                });
                            }
                        });
                        setCsvData(uploadedTargetDates)
                    }
                });
            } catch (e) {
                console.error(e)
            }

        }
    }
    const handleFileSubmit = () => {
        if (csvData) {
            uploadDefaultTargetDates({
                payload: {
                    csvData
                },
                onSuccess: () => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Target dates uploaded', life: 3000 });
                    setRenderUploadDialog(false)
                }
            })
        }
    }

    const handleExportTargetDatesTemplate = (event) => {
        if (taskNameOptions && workflowSteps) {
            event.preventDefault();
            event.stopPropagation();
            const link = document.createElement('a');
            let exportData = []
            for (let i = 0; i < taskNameOptions.length; i++) {
                for (let j = 0; j < stepsInUse.length; j++) {
                    for (let k = 0; k < workflowsInUse.length; k++) {
                        exportData.push({
                            ...taskNameOptions[i],
                            stepName: stepsInUse[j].stepName,
                            workflowStepUuid: stepsInUse[j].stepUuid,
                            workflowName: workflowsInUse[k].workflowName,
                            workflowUuid: workflowsInUse[k].workflowUuid,
                            duration: 0,
                            targetDate: undefined
                        })
                    }
                }
            }
            exportData = exportData.filter((item) => {
                // Filter out items which fields value starts with =, +, - or @ before exporting to csv file to avoid malicious injection
                const result = validateFieldsStartChar(item)
                return result.isValid;
            })
            const csvData = Papa.unparse(exportData);
            link.href = URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
            link.download = 'default-target-dates-template.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label='Create' icon='pi pi-plus' onClick={() => setItemDialog(true)} />
            </div>
        )
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                {projectPermissions?.canUpdateTargetDates &&
                    <Button label="Upload csv" icon="pi pi-plus" severity="success" onClick={() => setRenderUploadDialog(true)} />
                }
            </div>
        );
    };
    const confirmDeleteItem = (item) => {
        setItem(item);
        setDeleteItemDialog(true);
    };
    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    };

    const deleteItem = async () => {
        deleteDefaultTargetDates({
            payload: {
                taskName: item.taskName,
                projectUuid: item.projectUuid,
                workflowStepUuid: item.workflowStepUuid,
                workflowUuid: item.workflowUuid
            },
            onSuccess: () => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 3000 });
                setDeleteItemDialog(false);
                setItem(emptyItem);
            },
            onError: () => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Delete failed', life: 5000 });
            }
        })
    };

    const itemDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideItemDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveItem} />
        </React.Fragment>
    );

    const deleteItemDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteItem} />
        </React.Fragment>
    );

    const uploadDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={() => setRenderUploadDialog(false)} />
            <Button label="Upload" icon="pi pi-check" onClick={handleFileSubmit} />
        </React.Fragment>
    )

    const tasksRowFilterTemplate = (options) => {
        return (
            <Dropdown name='taskUuid' value={options.value} options={taskNameOptions} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="taskName" optionValue="taskName" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const stepsRowFilterTemplate = (options) => {
        return (
            <Dropdown name='workflowStepUuid' value={options.value} options={workflowSteps} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="stepName" optionValue="stepName" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const workflowsRowFilterTemplate = (options) => {
        return (
            <Dropdown name='workflowUuid' value={options.value} options={workflows} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="workflowName" optionValue="workflowName" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <>
                    {projectPermissions?.canUpdateTargetDates &&
                        <Button icon="pi pi-pencil" outlined className="mr-2 bg-primary right-0" onClick={() => editItem(rowData)} />}
                    {projectPermissions?.canDeleteTargetDates &&
                        <Button icon="pi pi-trash" outlined severity="danger bg-primary right-0" onClick={() => confirmDeleteItem(rowData)} />}
                </>
            </React.Fragment>
        )
    }

    return (
        <>
            <div className="card">
                <Toast ref={toast} />
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable value={defaultTargetDates} tableStyle={{}} loading={loading}
                    filters={filters} filterDisplay="row"
                    sortField="createdAt" sortOrder="-1">
                    <Column field="taskName" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Task" filterElement={tasksRowFilterTemplate}></Column>
                    <Column field="stepName" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Step" filterElement={stepsRowFilterTemplate}></Column>
                    <Column field="workflowName" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Workflow" filterElement={workflowsRowFilterTemplate}></Column>
                    <Column field="targetDate" sortable header="Target date" body={(rowData) => {
                        let localDate = ''
                        if (rowData.targetDate) {
                            const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' };
                            localDate = new Date(rowData.targetDate).toLocaleString(undefined, options);
                        }
                        return <span>{localDate}</span>;
                    }}>
                    </Column>
                    <Column field="duration" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Duration"></Column>
                    <Column body={actionBodyTemplate} header='Action' exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={itemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Create default target date" modal className="p-fluid" footer={itemDialogFooter} onHide={hideItemDialog}>
                <div className="field">
                    <label htmlFor="taskName" className="font-bold">
                        Task name
                    </label>
                    <Dropdown
                        name='taskName'
                        value={item.taskName}
                        options={taskNameOptions}
                        onChange={(e) => setItem(prevItem => ({
                            ...prevItem,
                            taskName: e.value
                        }))}
                        optionLabel="taskName"
                        optionValue="taskName"
                        placeholder="Select One"
                        className="p-column-filter"
                        filter
                        disabled={isEdit}
                        style={{ minWidth: '12rem' }}
                    />
                </div>
                <div className="field">
                    <label htmlFor="workflows" className="font-bold">
                        Workflows
                    </label>
                    <Dropdown
                        name='workflows'
                        value={item.workflowUuid}
                        options={workflowsInUse}
                        onChange={(e) => setItem(prevItem => ({
                            ...prevItem,
                            workflowUuid: e.value
                        }))}
                        optionLabel="workflowName"
                        optionValue="workflowUuid"
                        placeholder="Select One"
                        className="p-column-filter"
                        filter
                        disabled={isEdit}
                        style={{ minWidth: '12rem' }}
                    />
                </div>
                <div className="field">
                    <label htmlFor="workflowSteps" className="font-bold">
                        Workflow steps
                    </label>
                    <Dropdown
                        name='workflowSteps'
                        value={item.workflowStepUuid}
                        options={stepsInUse}
                        onChange={(e) => setItem(prevItem => ({
                            ...prevItem,
                            workflowStepUuid: e.value
                        }))}
                        optionLabel="stepName"
                        optionValue="stepUuid"
                        placeholder="Select One"
                        className="p-column-filter"
                        filter
                        disabled={isEdit}
                        style={{ minWidth: '12rem' }}
                    />
                </div>
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Target date
                    </label>
                    <Calendar
                        value={item?.targetDate ? new Date(item.targetDate) : null}
                        onChange={(e) => setItem(prevItem => ({
                            ...prevItem,
                            targetDate: e.value
                        }))}
                        showIcon
                        showButtonBar
                        minDate={new Date()}
                    />
                </div>
                <div className="field">
                    <label htmlFor="duration" className="font-bold">
                        Duration
                    </label>
                    <InputNumber
                        id="duration"
                        value={item.duration}
                        onChange={(e) => setItem(prevItem => ({
                            ...prevItem,
                            duration: e.value
                        }))}
                    />
                </div>
            </Dialog>

            <Dialog visible={renderUploadDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Upload default target dates" modal className="p-fluid" footer={uploadDialogFooter} onHide={() => setRenderUploadDialog(false)}>
                <div>
                    <p>You can retrieve a <strong>template</strong> with existing steps and tasks by exporting it.</p>
                    <Button label="Export template" icon="pi pi-upload" className="p-button-info" onClick={handleExportTargetDatesTemplate} />
                    <p>Or create a spreadsheet at least contains following fields.</p>
                    <img src={defaultTargetDatesExample} alt='default target dates csv example' />
                    <p>Save the spreadsheet as a <strong>.csv</strong> file and upload.</p>
                    <input className='' type='file' name='TaskUploadCSV' ref={fileUpload} onChange={handleFileChanged} accept='.csv' />
                    <div className='mt3 mb2 white'>This is a feedback message about the upload</div>
                </div>
            </Dialog>
            <Dialog visible={deleteItemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {item && (
                        <span>
                            Are you sure you want to delete the <b>target date</b> of <b>{item.taskName}</b> in <b>{item.stepName}</b>?
                        </span>
                    )}
                </div>
            </Dialog>
        </>

    );
}

export default DefaultTargetDatesTable