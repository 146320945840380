import reactHtmlParser from 'react-html-parser'

function Step(props) {

    const step = props.step
    const bg = props.bg

    return (
        <div className={`${bg} mt2 mb2 pl3 pr3 pt3 ba ml4 mr4 b--primary br2`} style={{ minHeight: '80px', width: '620px' }}>
            <div className='flex flex-row'>
                <div className='b'>{step.stepName}</div>
            </div>
            <div className='mt2 pb3 dark-gray'>{reactHtmlParser(step.stepDescription)}</div>
        </div>
    )
}

export default Step