export const alphabeticalSortByKey = (array, key) => {
    if (!array) {
        return array
    }

    const allKeysExist = array.every(obj => obj.hasOwnProperty(key));
    if (!allKeysExist) {
        return array;
    }

    return array.sort((a, b) => {
        let x = a[key].toLowerCase();
        let y = b[key].toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
    });
}