import updateButton from './updateButton.svg'

function UpdateButton(props){

    const onClick = props.onClick
    const tooltipText = props.tooltipText
    const disableBorder = props.disableBorder

    let border
    disableBorder ? border = '' : border = 'ba'

    return (
        <div className={`pl1 pr1 pt1 b primary pointer tooltip b--moon-gray ${border} update-button`} style={{width: '25px', height: '30px'}} onClick={onClick}>
            <img src={updateButton} style={{transform: 'scaleX(-1)'}} alt='update button'/>
            <span className="tooltiptext f6">{tooltipText}</span>
        </div>
    )
}

export default UpdateButton