import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import EmailHistoryService  from '../../../Services/EmailHistoryService'

export default function ItemTable(props) {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [filters] = useState({
        sender: { value: null, matchMode: FilterMatchMode.CONTAINS },
        recipients: { value: null, matchMode: FilterMatchMode.CONTAINS },
        subject: { value: null, matchMode: FilterMatchMode.CONTAINS },
        succeed: { value: null, matchMode: FilterMatchMode.EQUALS },
    })
    
    async function fetchItems() {
        setLoading(true)
        try {
            const response = await EmailHistoryService.find({ projectUuid: props.project.projectUuid })

            const emailHistories = response.data
            const modifiedEmailHistories = emailHistories.map((emailHistory) => {
                const toEmails = emailHistory.to.concat(emailHistory.cc, emailHistory.bcc).map(user => user.email)

                return {
                    sender: `${emailHistory.from.firstName} ${emailHistory.from.lastName}`,
                    recipients:  toEmails.join(', '),
                    subject: emailHistory.subject,
                    succeed: emailHistory.succeed,
                    createdAt: emailHistory.createdAt
                }
            })

            setItems(modifiedEmailHistories)
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.projectPermissions && props.project.projectUuid) {
            fetchItems()
        }
    }, [props.project])

    const succeedBodyTemplate = (rowData) => {
        return rowData.succeed ? 'Yes' : 'No'
    }

    const convertDateToLocaleString = (date) => {
        const convertedDate = new Date(date).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})
        return convertedDate
    }

   const createdAtBodyTemplate = (rowData) => {
    const formatedDate = convertDateToLocaleString(rowData.createdAt)
    return formatedDate
   }

    const succeedRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={[{label: 'Yes', value: true}, {label: 'No', value: false}]} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="label" optionValue="value" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '8rem' }} />
        )
    }

    return (
        <>
            <div className="card" style={{height: "calc(100vh - 180px)"}}>
                <DataTable value={items} size='small' tableStyle={{}} loading={loading}
                    scrollable scrollHeight="flex"
                    filters={filters} filterDisplay="row"
                    sortField="createdAt" sortOrder="-1">
                    <Column field="sender" header="Sender" sortable filter showFilterMenu={false}></Column>
                    <Column field="recipients" header="Recipients" sortable filter showFilterMenu={false}></Column>
                    <Column field="subject" header="Subject" sortable filter showFilterMenu={false}></Column>
                    <Column field="succeed" body={succeedBodyTemplate} header="Succeed" sortable  filter showFilterMenu={false} filterElement={succeedRowFilterTemplate}></Column>
                    <Column field="createdAt" body={createdAtBodyTemplate} sortable header="Date Created" style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>
        </>

    )
}