import axios from 'axios'
import AddTokenInterceptor from "../interceptors/addToken";
import SaveTokenInterceptor from "../interceptors/saveToken";

const Services = {
    initAPI:  function (baseURL) {
        const API = axios.create({ baseURL })
        AddTokenInterceptor(API);
        SaveTokenInterceptor(API);
        return API
    },
};

export default Services