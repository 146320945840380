import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import LocaleService from '../../../Services/LocaleService'
import { validateFieldsStartChar } from '../../../Shared/validators'

export default function ItemTable(props) {
    let emptyItem = {
        _id: null,
        localeName: null,
        projectUuid: props.project.projectUuid
    };
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [item, setItem] = useState(emptyItem);
    const [error, setError] = useState(null);
    const [itemDialog, setItemDialog] = useState(false);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const [projectPermissions, setProjectPermissions] = useState({})
    const [filters] = useState({
        localeName: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const fetchItems = useCallback(async () => {
        setLoading(true);
        try {
            let response = await LocaleService.find({ projectUuid: props.project.projectUuid })
            setItems(response.data);
        } catch (err) {
            console.error(err)
            setError(err.response.data)
        } finally {
            setLoading(false);
        }
    }, [props.project])

    useEffect(() => {
        setProjectPermissions(props.projectPermissions)
        fetchItems();
    }, [props.projectPermissions, fetchItems]);

    const editItem = (item) => {
        setItem({ ...item });
        setItemDialog(true);
    };

    const confirmDeleteItem = (item) => {
        setItem(item);
        setDeleteItemDialog(true);
    };
    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    };

    const hideItemDialog = () => {
        setSubmitted(false);
        setItemDialog(false);
    };

    const saveItem = async () => {
        setSubmitted(true);
        const result = validateFieldsStartChar(item)
        if (!result.isValid) {
            toast.current.show({ severity: 'error', summary: 'Invalid value', detail: `The value of ${result.key} cannot starts with =, +, - or @: ${result.value}`, life: 5000 })
            setSubmitted(false)
            return
        }
        try {
            if (item.localeUuid) {
                await LocaleService.update(item.localeUuid, item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Updated', life: 3000 });
            } else {
                await LocaleService.create(item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Created', life: 3000 });
            }
            setItemDialog(false);
            setItem(emptyItem);
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 });
        }
    };

    const deleteItem = async () => {
        try {
            await LocaleService.delete(item.localeUuid).then(data => fetchItems())
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 3000 });
            setDeleteItemDialog(false);
            setItem(emptyItem);
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 });
        }

    };
    const itemDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideItemDialog} />
            <Button label="Save" icon="pi pi-check" disabled={item.localeName?.length ? false : true} onClick={saveItem} />
        </React.Fragment>
    );

    const deleteItemDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteItem} />
        </React.Fragment>
    );

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _item = { ...item };

        _item[`${name}`] = val;

        setItem(_item);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <>
                    {projectPermissions?.canUpdateLocales &&
                        <Button icon="pi pi-pencil" outlined className="mr-2 bg-primary right-0" onClick={() => editItem(rowData)} />}
                    {projectPermissions?.canDeleteLocales &&
                        <Button icon="pi pi-trash" outlined severity="danger bg-primary right-0" onClick={() => confirmDeleteItem(rowData)} />}
                </>
            </React.Fragment>
        );
    };

    const openNew = () => {
        setItem(emptyItem);
        setSubmitted(false);
        setItemDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                {projectPermissions?.canCreateLocales &&
                    <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
                }
            </div>
        );
    };

    return (
        <>
            <div className="card" style={{ height: "calc(100vh - 300px)" }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                <DataTable value={items} tableStyle={{}} loading={loading}
                    scrollable scrollHeight="flex"
                    filters={filters} filterDisplay="row"
                    sortField="createdAt" sortOrder="-1">
                    <Column field="localeName" filter showFilterMenu={false} sortable header="Name"></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={deleteItemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {item && (
                        <span>
                            Are you sure you want to delete <b>{item.localeName}</b>?
                        </span>
                    )}
                    {error && <small className="p-error">{error}</small>}
                </div>
            </Dialog>

            <Dialog visible={itemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Details" modal className="p-fluid" footer={itemDialogFooter} onHide={hideItemDialog}>
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Name
                    </label>
                    <InputText id="localeName" value={item.localeName} onChange={(e) => onInputChange(e, 'localeName')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.localeName })} />
                    {submitted && !item.localeName && <small className="p-error">Name is required.</small>}
                </div>
            </Dialog>
        </>

    );
}