function ProjectIndexTile(props) {

    const name = props.name;
    const logo = props.logo;
    const description = props.description;
    // width 320px height 195px for logo
    return (
        <div className='ba pointer mr4 mt4 bg-near-white shadow-4 b--primary bw1 br2 grow' style={{ width: '195px', height: '195px' }}>
            <div className='flex justify-center mt3'>
                <div className='flex justify-center items-center'  style={{width: '95px', height: '95px'}}>
                    <img src={logo} alt="logo"/>
                </div>
            </div>
            <div className='ml3 mt3 f5 primary b'>{name}</div>
            <div className='f6 ml3 mr3 mt1 black'>{description}</div>
        </div>
    );
}

export default ProjectIndexTile
