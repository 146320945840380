import AppService from './AppService'
const baseURL = '/api/taskview'
const API = AppService.initAPI(baseURL)

export async function getFileService(serviceProps) {
    const fileKey = serviceProps.fileKey
    const taskUuid = serviceProps.taskUuid
    return await API.get(`/tasks/${taskUuid}/files/${fileKey}`)
}

export async function deleteFileService(serviceProps) {
    const fileKey = serviceProps.fileKey
    const taskUuid = serviceProps.taskUuid
    return await API.delete(`/tasks/${taskUuid}/files/${fileKey}`)
}

export async function getTaskService(serviceProps) {
    const taskUuid = serviceProps.taskUuid
    return await API.get(`/tasks/${taskUuid}`)
}

export async function getTasksService(params) {
    return await API.get(`/tasks`, { params })
}

export async function getTasksFilterFieldsService(params) {
    return await API.get(`/filterFields`, { params })
}

export async function updateOnStepForTaskService(taskUuid, serviceProps) {
    return await API.put(
        `/tasks/${taskUuid}`,
        serviceProps,
        {
            headers: { "Content-Type": "multipart/form-data" }
        })
}

export async function uploadFileInCurrentStep(taskUuid, serviceProps) {
    return await API.post(
        `/tasks/${taskUuid}/files`,
        serviceProps,
        {
            headers: { "Content-Type": "multipart/form-data" }
        })
}

export async function getProjectAssignedRoles(projectUuid) {
    return await API.get(`/assignedRoles/${projectUuid}`)
}