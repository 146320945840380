import reactHtmlParser from 'react-html-parser'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Editor } from "primereact/editor"

import UpdateButton from '../../../Shared/UpdateButton'
import DeleteButton from '../../../Shared/DeleteButton'
import SaveButton from '../../../Shared/SaveButton'
import CancelButton from '../../../Shared/CancelButton'
import PreviewButton from '../../../Shared/PreviewButton'

import { 
    updateMessageService, 
    deleteMessageService 
} from '../../../Services/WorkflowAppServices'

import Modal from '../../../Shared/Modal'
import TransitionModalPreview from './TransitionModalPreview'


function MessageRow(props){

    const { projectName } = useParams()

    const message = props.message
    const fetchMessages = props.fetchMessages
    const projectPermissions = props.projectPermissions

    const messageHTML = message.messageHTML
    const messageUuid = message.messageUuid

    const [renderUpdateFunctionality, setRenderUpdateFunctionality] = useState(false)
    const [renderPreviewMessageModal, setRenderPreviewMessageModal] = useState(false)
    const [renderCannotDeleteModal, setRenderCannotDeleteModal] = useState(false)
    const [messageHTMLToUpdate, setMessageHTMLToUpdate] = useState(messageHTML)
    const [isNowLoadingSave, setIsNowLoadingSave] = useState(false)
    const [isNowLoadingDelete, setIsNowLoadingDelete] = useState(false)

    async function handleDeleteMessageClick(){
        setIsNowLoadingDelete(true)

        let serviceProps = { messageUuid, projectName }
        let response = await deleteMessageService(serviceProps)

        if (response.status === 200) {
            if (response.data.canDelete){
                fetchMessages()
            } else {
                setRenderCannotDeleteModal(true)
            }
            setIsNowLoadingDelete(false)
        }
    }

    async function handleSaveNewMessageClick(){
        setIsNowLoadingSave(true)

        let serviceProps = { messageUuid, messageHTMLToUpdate, projectName }
        let response = await updateMessageService(serviceProps)

        if (response.status === 201){
            fetchMessages()
            setTimeout( _ => {
                setIsNowLoadingSave(false)
                setRenderUpdateFunctionality(false)
            }, 1000)
        }
    }

    return (
        <>
        {renderCannotDeleteModal && 
            <Modal 
                modalTitle='Cannot delete message'
                onClickClose={ _ => { setRenderCannotDeleteModal(false) }}>
                    <p>This message is assigned to a workflow.</p>
                    <p>Please remove the message from the workflow first and then delete it.</p>
            </Modal>}
        {renderPreviewMessageModal && 
            <TransitionModalPreview 
                stepFrom={{
                    stepName: 'Sample step name 1', 
                    stepDescription: 'This is a description about this step'
                }}
                stepTo={{
                    stepName: 'Sample step name 2', 
                    stepDescription: 'This is a description about this step'
                }}
                task={{taskName: 'Sample task name'}}
                isUpload={true}
                fetchTasks={false}
                messageHTML={messageHTML}
                connection={{}}
                setRenderTransitionModal={setRenderPreviewMessageModal}
            />}
        {!renderUpdateFunctionality &&
            <div className='flex flex-row mt3 mb3'>
                <div className='flex flex-column'>
                    {projectPermissions.canUpdateWorkflowMessages &&
                        <UpdateButton 
                            onClick={ _ => setRenderUpdateFunctionality(true) }
                            tooltipText='Update message'/>}
                    <PreviewButton 
                        onClick={ _ => setRenderPreviewMessageModal(true) }
                        tooltipText='Preview message'/>
                    {projectPermissions.canDeleteWorkflowMessages &&
                        <DeleteButton 
                            onClick={handleDeleteMessageClick} 
                            tooltipText='Delete message'
                            isNowLoading={isNowLoadingDelete}/>}
                </div>
                <div className='ba b--secondary bw1 pl3 pr3 br2' style={{width: '727px', minHeight: '100px'}}>
                    {reactHtmlParser(messageHTML)}
                </div>

            </div>
            
            }
        {renderUpdateFunctionality &&
            <div className='flex flex-row mt3 mb3'>
                <div className='flex flex-column bg-light-secondary' style={{height: '60px'}}>
                    <SaveButton 
                        isNowLoading={isNowLoadingSave}
                        onClick={handleSaveNewMessageClick}
                        tooltipText='Save changes'/>
                    <CancelButton  
                        tooltipText='Cancel changes'
                        onClick={ _ => setRenderUpdateFunctionality(false) }/>
                </div>
                <div className='ba b--secondary bw1 br2 bg-light-secondary' style={{width: '727px', height: '100%'}}>
                    <Editor value={messageHTMLToUpdate} onTextChange={(e) => setMessageHTMLToUpdate(e.htmlValue)} style={{ minHeight: '100px' }} />
                </div>
            </div>}
        </>
    )
}

export default MessageRow
