import { useState } from 'react'
import DataTable from './DataTable'
import IsNowLoading from '../../../Shared/IsNowLoading'
import ProjectPageHeading from '../../../Shared/ProjectPageHeading'

function CustomTemplates(props){
    const [isNowLoading] = useState(false)
    const [error, setError] = useState(false)

    return (
        <IsNowLoading isNowLoading={isNowLoading} error={error} setError={setError}>
            <ProjectPageHeading pageTitle='Custom Templates'>
                <div>
                    <DataTable projectPermissions={props.projectPermissions} project={props.project} setError={setError} />
                </div>
            </ProjectPageHeading>
        </IsNowLoading>
    )
}

export default CustomTemplates