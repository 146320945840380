import AppService from './AppService'
const baseURL = '/api/default-target-dates'
const API = AppService.initAPI(baseURL)

const Services = {

    find: async function (params) {
        return await API.get(``, { params })
    },

    upsert: async function (body) {
        return await API.post(``, body)
    },

    upload: async function (body) {
        return await API.post(`/upload`, body)
    },

    delete: async function (body) {
        return await API.post(`/delete`, body)
    },

};
export default Services