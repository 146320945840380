import reactHTMLParser from 'react-html-parser'

function Message(props){

    const messages = props.messages
    const connection = props.connection

    let renderMessage = false
    if (typeof connection.messageUuid !== 'undefined' && connection.messageUuid !== '') renderMessage = true

    let messageHTML = ''
    let parsedMessageHTML = ''
    let messageString = ''

    if (renderMessage){
        messageHTML = messages.find(message => message.messageUuid === connection.messageUuid).messageHTML
        parsedMessageHTML = reactHTMLParser(messageHTML)
        messageString = messageHTML.replace( /(<([^>]+)>)/ig, '').slice(0,15)+'...'
    }


    return (
        <>
            {renderMessage &&
                <div className='f6 flex flex-row mt1'>
                    <div className='b ml1 workflow-config-step-title'>
                        Message:
                    </div>
                    <div className='ml1 tooltipwide pointer workflow-config-step-value'>
                        {messageString}
                        <span className='tooltiptextwide primary f6'>
                            {parsedMessageHTML}
                        </span>
                    </div>
                </div>}
        </>
    )
}

export default Message