import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import WorkflowInstance from './WorkflowInstance'
import IsNowLoading from '../../../Shared/IsNowLoading'
import AddNew from "../../../Shared/AddNew"
import { Toast } from 'primereact/toast'
import {
    addNewWorkflowService
} from '../../../Services/WorkflowAppServices'
import WorkflowService from '../../../Services/WorkflowService'

function WorkflowsList(props) {

    const { projectName } = useParams()

    const [isNowLoading, setIsNowLoading] = useState(true)
    const [isLoadingNewWorkflow, setIsLoadingNewWorkflow] = useState(false)
    const [projectPermissions, setProjectPermissions] = useState({})
    const toast = useRef(null);

    useEffect(_ => {
        fetchWorkflows()
    }, [])

    const [workflows, setWorkflows] = useState([])

    async function handleAddNewWorkflowClick() {
        setIsLoadingNewWorkflow(true)
        let serviceProps = { projectName }
        await addNewWorkflowService(serviceProps)
            .then(async () => {
                await fetchWorkflows()
            })
            .finally(() => setIsLoadingNewWorkflow(false))

    }

    const fetchWorkflows = async () => {
        try {
            setProjectPermissions(props.projectPermissions)
            await WorkflowService.find({ projectUuid: props.project.projectUuid })
                .then(response => {
                    setWorkflows(response.data)
                })
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: e.response.data ?? 'Something went wrong while fetching workflows.', life: 5000 });
        } finally {
            setIsNowLoading(false)
        }
    }

    return (
        <IsNowLoading isNowLoading={isNowLoading}>
            <Toast ref={toast} />
            <div>
                <div className='flex justify-center'>
                    <div className='flex flex-column mh4'>
                        <div className='f3 mt4'>
                            Choose a workflow
                        </div>
                        {projectPermissions?.canCreateWorkflows &&
                            <AddNew
                                isLoading={isLoadingNewWorkflow}
                                onClick={handleAddNewWorkflowClick}
                                width='190px'
                                label='Add a new workflow' />}
                        {workflows.length === 0 &&
                            <div className='mt3'>
                                There are no active workflows to view
                            </div>}
                        <div className='mt3'>
                            {workflows.map(workflow => {
                                return (
                                    <WorkflowInstance
                                        key={workflow.workflowUuid}
                                        workflow={workflow}
                                        fetchWorkflows={fetchWorkflows}
                                        toast={toast}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </IsNowLoading>
    )
}

export default WorkflowsList