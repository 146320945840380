function CheckedLabel(props){

    const label = props.label
    const isChecked = props.isChecked

    return (
        <>
        {isChecked &&
            <div className='flex flex-row'>
                <span className='dark-green f6'>&#10003;</span>
                <label className='f6'>{label}</label>
            </div>}
        </>
    )
}

export default CheckedLabel