import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { FilterMatchMode } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import UserService from '../../../Services/UserService'
import ProjectService from '../../../Services/ProjectService'
import GroupService from '../../../Services/GroupService'
import RoleService from '../../../Services/RoleService'
import LocaleService from '../../../Services/LocaleService'
import LoginHistoryService from '../../../Services/LoginHistoryService'

export default function ItemTable(props) {
    let emptyItem = {
        _id: null,
        firstName: null,
        lastName: null,
        email: null,
        assignedRoleUuids: [],
        assignedGroupUuids: [],
        assignedLocaleUuids: [],
        projectUuid: null,
        lastLoginDate: null,
    };
    const [loading, setLoading] = useState(true);
    const [userLoginHistoriesloading, setUserLoginHistoriesloading] = useState(false);
    const [items, setItems] = useState([]);
    const [item, setItem] = useState(emptyItem);
    const [error, setError] = useState(null);
    const [itemDialog, setItemDialog] = useState(false);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [roles, setRoles] = useState(null)
    const [groups, setGroups] = useState(null)
    const [locales, setLocales] = useState(null)
    const [userLoginHistories, setUserLoginHistories] = useState(null);
    const [userLoginHistoriesDialog, setUserLoginHistoriesDialog] = useState(false);
    const [projectPermissions, setProjectPermissions] = useState({})
    const [applicationPermissions, setApplicationPermissions] = useState(null)
    const [filters] = useState({
        firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        lastName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        groups: { value: null, matchMode: FilterMatchMode.CONTAINS },
        roles: { value: null, matchMode: FilterMatchMode.CONTAINS },
        locales: { value: null, matchMode: FilterMatchMode.CONTAINS },
        assigned: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    async function fetchItems() {
        setLoading(true);
        try {
            let usersResponse = await UserService.find()
            let assignedUsersResponse = await ProjectService.findUsers(props.project.projectUuid)
            let results = usersResponse.data.map(user => 
                {
                    let assignedUser = assignedUsersResponse.data.find(({ userUuid }) => userUuid === user.userUuid)
                    return ({
                        ...user,
                        assigned: typeof assignedUser !== 'undefined',
                        assignedRoleUuids: assignedUser?.assignedRoleUuids,
                        assignedGroupUuids: assignedUser?.assignedGroupUuids,
                        assignedLocaleUuids: assignedUser?.assignedLocaleUuids,
                        roles: assignedUser?.assignedRoleUuids?.map(assignedRoleUuid => roles.find(({roleUuid}) => roleUuid === assignedRoleUuid)?.roleTitle).join(', '),
                        groups: assignedUser?.assignedGroupUuids?.map(assignedGroupUuid => groups.find(({groupUuid}) => groupUuid === assignedGroupUuid)?.groupName).join(', '),
                        locales: assignedUser?.assignedLocaleUuids?.map(assignedLocaleUuid => locales.find(({localeUuid}) => localeUuid === assignedLocaleUuid)?.localeName).join(', ')
                    })
                }
            )

            setItems(results);
        } catch (err) {
            console.error(err)
            setError(err?.response?.data || 'Error')
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (roles && groups && locales) {
            fetchItems()
        }
    }, [roles, groups, locales]);

    useEffect(() => {
        setProjectPermissions(props.projectPermissions)
        setApplicationPermissions(props.applicationPermissions)
        try {
            RoleService.find({projectUuid: props.project.projectUuid}).then(response => setRoles(response.data))
            GroupService.find({projectUuid: props.project.projectUuid}).then(response => setGroups(response.data))
            LocaleService.find({projectUuid: props.project.projectUuid}).then(response => setLocales(response.data))
        } catch (err) {
            console.error(err)
            setError(err?.response?.data || 'Error')
        }
    }, [props.project, props.projectPermissions, props.applicationPermissions]);

    const editItem = (item) => {
        setItem({ ...item });
        setSubmitted(false);
        setItemDialog(true);
    };

    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    };

    const hideItemDialog = () => {
        setSubmitted(false);
        setItemDialog(false);
    };

    const saveItem = async () => {
        setSubmitted(true);
        if (item.assigned && (item.assignedRoleUuids === undefined || item.assignedRoleUuids?.length < 1)) {
            return
        }

        if (!projectPermissions?.canUpdateRoleAssignments) {
            delete item.assignedRoleUuids
        }
        
        if (!projectPermissions?.canUpdateLocaleAssignments) {
            delete item.assignedLocaleUuids
        }
        
        if (!projectPermissions?.canUpdateGroupAssignments) {
            delete item.assignedGroupUuids
        }


        try {
            if (item.userUuid) {
                await ProjectService.updateUser(props.project.projectUuid, item.userUuid, item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Updated', life: 3000 });
            } else {
                await ProjectService.updateUser(item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Created', life: 3000 });
            }
            setItemDialog(false);
            setItem(emptyItem);
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 });
        }
    };

    const deleteItem = async () => {
        try {
            await ProjectService.deleteUser(item.userUuid).then(data => fetchItems())
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 3000 });
            setDeleteItemDialog(false);
            setItem(emptyItem);
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 });
        }

    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const itemDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideItemDialog} />
            <Button label="Save" icon="pi pi-check" className="bg-primary" onClick={saveItem} />
        </React.Fragment>
    );

    const deleteItemDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteItem} />
        </React.Fragment>
    );

    const setSelectButtonChange = (e, name) => {
        const val = e.value;
        let _item = { ...item };

        _item[`${name}`] = val;

        setItem(_item);
    };
    
    const setSelectedMultiSelect = (e, name) => {
        const val = e.value;
        let _item = { ...item };

        _item[`${name}`] = val;

        setItem(_item);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _item = { ...item };

        _item[`${name}`] = val;

        setItem(_item);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <>
                    {projectPermissions?.canUpdateUserAssignments &&
                        <Button icon="pi pi-pencil" outlined className="mr-2 bg-primary right-0" onClick={() => editItem(rowData)} />
                    }
                     { rowData.assigned && 
                        <Button icon="pi pi-history" className="mr-2 bg-primary right-0" disabled={!rowData?.lastLoginDate} onClick={() => getUserLoginHistories(rowData.userUuid)} />
                    }                   
                </>
            </React.Fragment>
        );
    };

    const openNew = () => {
        setItem(emptyItem);
        setSubmitted(false);
        setItemDialog(true);
    };

    const importJSON = (e) => {
        const options = e.options
        let files = e.files;
        if (files.length !== 1) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please upload a single json file', life: 5000 });
            return
        }
        const file = files[0]
        if (file["name"].split('.').pop().toLowerCase() !== "json") {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please upload a json file', life: 5000 });
            return
        }
        const fileReader = new FileReader();
        fileReader.readAsText(file, "UTF-8");
        fileReader.onload = e => {
            UserService.import('PISA-Connect', JSON.parse(e.target.result))
                .then(response => {
                    fetchItems()
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Imported', life: 3000 });
                })
                .catch(err => {
                    console.error(err)
                    toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 });
                }).finally( () => {
                    options.clear()
                })
        };
    };

    const convertDateFormat = (date) => {
        const convertedDate = date.toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})
        return convertedDate
    };

    const getUserLoginHistories = async (userUuid) => {
        setUserLoginHistoriesloading(true)
        setUserLoginHistories(null)
        setUserLoginHistoriesDialog(true)

        try {
            await LoginHistoryService.find({userUuid: userUuid}).then(response => setUserLoginHistories(response.data))
        } catch (err) {
            console.error(err)
            setError(err?.response?.data || 'User Login Histories Error')
         
        } finally {
            setUserLoginHistoriesloading(false)
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                {projectPermissions?.canCreateUsers &&
                    <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
                }
                <Button label="Sync" icon="pi pi-plus" severity="success" onClick={  _ =>  UserService.syncCognito().finally(_=>fetchItems())} />
                {applicationPermissions?.canImportUsers &&
                    <FileUpload mode="basic" name="importUser[]" accept=".json" maxFileSize={1000000} chooseLabel="Import" customUpload uploadHandler={importJSON} auto />
                }
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <Button label="Export" icon="pi pi-upload" className="p-button-info" onClick={exportCSV} />;
    };

    const assignedBodyTemplate = (rowData) => {
        return rowData.assigned ? 'Yes' : 'No';
    };

    const rolesBodyTemplate = (rowData) => {
        return rowData?.roles
    };

    const groupsBodyTemplate = (rowData) => {
        return rowData?.groups
    };

    const localesBodyTemplate = (rowData) => {
        return rowData?.locales
    };

    const lastLoginDateBodyTemplate = (rowData) => {
        let lastLoginDate = rowData?.lastLoginDate
       
        if (lastLoginDate) { 
            const date = new Date(lastLoginDate)
            lastLoginDate = convertDateFormat(date)
        }
        return lastLoginDate
    };

    const userLoginCreatedAtBodyTemplate = (rowData) => {
        let loginHistoryDate = rowData?.createdAt
       
        if (loginHistoryDate) { 
            const date = new Date(loginHistoryDate)
            loginHistoryDate = convertDateFormat(date)
        }
        return loginHistoryDate
    };

    const groupsRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={groups} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="groupName" optionValue="groupName" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const rolesRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={roles} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="roleTitle" optionValue="roleTitle" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const localesRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={locales} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="localeName" optionValue="localeName" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const assignedRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={[{label: 'Yes', value: true}, {label: 'No', value: false}]} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="label" optionValue="value" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const exportFunction = ({data, field}) => {
        if (['assigned'].includes(field)) {
            return data ? 'Yes' : 'No';
        }
        
        if (['lastLoginDate'].includes(field)) {
            const date = new Date(data)
            return data ? convertDateFormat(date) : '';
        }
        return data
    };

    return (
        <>
            <div className="card" style={{height: "calc(100vh - 300px)"}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4" left={leftToolbarTemplate}  right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={items} tableStyle={{}} loading={loading}
                        scrollable scrollHeight="flex"
                        filters={filters} filterDisplay="row"
                        sortField="userUuid" sortOrder="-1"
                        exportFilename="users" exportFunction={exportFunction}>
                    <Column field="firstName" filter showFilterMenu={false} sortable header="First name" style={{ minWidth: '12rem' }}></Column>
                    <Column field="lastName" filter showFilterMenu={false} sortable header="Last name" style={{ minWidth: '12rem' }}></Column>
                    <Column field="email" filter showFilterMenu={false} sortable header="Email" style={{ minWidth: '12rem' }}></Column>
                    <Column field="lastLoginDate" sortable header="Last login date" body={lastLoginDateBodyTemplate}  style={{ minWidth: '12rem' }}></Column>
                    <Column hidden={!projectPermissions?.canReadUserAssignments} field="assigned" filter showFilterMatchModes={false} sortable header="Assigned" body={assignedBodyTemplate} showFilterMenu={false} filterElement={assignedRowFilterTemplate}></Column>
                    <Column hidden={!projectPermissions?.canReadGroupAssignments} field="groups" filter showFilterMatchModes={false} sortable header="Groups" body={groupsBodyTemplate} showFilterMenu={false} filterElement={groupsRowFilterTemplate} exportable={projectPermissions?.canReadGroupAssignments}></Column>
                    <Column hidden={!projectPermissions?.canReadRoleAssignments} field="roles" filter showFilterMatchModes={false} sortable header="Roles" body={rolesBodyTemplate} showFilterMenu={false} filterElement={rolesRowFilterTemplate} exportable={projectPermissions?.canReadRoleAssignments}></Column>
                    <Column hidden={!projectPermissions?.canReadLocaleAssignments} field="locales" filter showFilterMatchModes={false} sortable header="Locales" body={localesBodyTemplate} showFilterMenu={false} filterElement={localesRowFilterTemplate} exportable={projectPermissions?.canReadLocaleAssignments}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={deleteItemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {item && (
                        <span>
                            Are you sure you want to delete <b>{item.firstName} {item.lastName}</b>?
                        </span>
                    )}
                    {error && <small className="p-error">{error}</small>}
                </div>
            </Dialog>

            <Dialog visible={itemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Details" modal className="p-fluid" footer={itemDialogFooter} onHide={hideItemDialog}>
                <div className="field">
                    <label htmlFor="firstName" className="font-bold">
                        First name
                    </label>
                    <InputText id="firstName" disabled value={item.firstName} onChange={(e) => onInputChange(e, 'firstName')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.firstName })} />
                    {submitted && !item.firstName && <small className="p-error">Name is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="lastName" className="font-bold">
                        Last name
                    </label>
                    <InputText id="lastName" disabled value={item.lastName} onChange={(e) => onInputChange(e, 'lastName')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.lastName })} />
                    {submitted && !item.lastName && <small className="p-error">Name is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="email" className="font-bold">
                        Email
                    </label>
                    <InputText id="email" disabled value={item.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.email })} />
                    {submitted && !item.email && <small className="p-error">Email Address is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="assigned" className="font-bold">
                        Assigned
                    </label>
                    <SelectButton disabled={!projectPermissions?.canUpdateUserAssignments} value={item.assigned} onChange={(e) => setSelectButtonChange(e, 'assigned')} optionLabel="name" options={[{ name: 'Yes', value: true }, { name: 'No', value: false }]} />
                </div>
                <div className="field" hidden={!item.assigned || !projectPermissions?.canReadGroupAssignments}>
                    <label htmlFor="assignedGroupUuids" className="font-bold">
                        Groups
                    </label>
                    <MultiSelect disabled={!projectPermissions?.canUpdateGroupAssignments} value={item.assignedGroupUuids} onChange={(e) => setSelectedMultiSelect(e, 'assignedGroupUuids')} options={groups} optionLabel="groupName" optionValue="groupUuid"
                        filter placeholder="Select Groups" minSelectedLabels={1} className="w-full md:w-20rem" />
                </div>
                <div className="field" hidden={!item.assigned || !projectPermissions?.canReadRoleAssignments}>
                    <label htmlFor="assignedRoleUuids" className="font-bold">
                        Roles
                    </label>
                    <MultiSelect disabled={!projectPermissions?.canUpdateRoleAssignments} value={item.assignedRoleUuids} onChange={(e) => setSelectedMultiSelect(e, 'assignedRoleUuids')} options={roles} optionLabel="roleTitle" optionValue="roleUuid"
                        filter placeholder="Select Roles" minSelectedLabels={1}
                        className={classNames({ 'p-invalid': submitted && item.assigned && (item.assignedRoleUuids === undefined || item.assignedRoleUuids?.length < 1) }, 'w-full', 'md:w-20rem')} />
                    {submitted && item.assigned && (item.assignedRoleUuids === undefined || item.assignedRoleUuids?.length < 1) && <small className="p-error">Role is required.</small>}
                </div>
                
                <div className="field" hidden={!item.assigned || !projectPermissions?.canReadLocaleAssignments}>
                    <label htmlFor="assignedLocaleUuids" className="font-bold">
                        Locales
                    </label>
                    <MultiSelect disabled={!projectPermissions?.canUpdateLocaleAssignments} value={item.assignedLocaleUuids} onChange={(e) => setSelectedMultiSelect(e, 'assignedLocaleUuids')} options={locales} optionLabel="localeName" optionValue="localeUuid"
                        filter placeholder="Select Locales" minSelectedLabels={1} className="w-full md:w-20rem" />
                </div>
            </Dialog>

            <Dialog header="User login histories" visible={userLoginHistoriesDialog} style={{ width: '75vw' }} modal contentStyle={{ height: '350px' }} onHide={() => setUserLoginHistoriesDialog(false)}>
                <DataTable value={userLoginHistories} size='small' loading={userLoginHistoriesloading} scrollable scrollHeight="flex">
                    <Column field="createdAt" header="Time" body={userLoginCreatedAtBodyTemplate} sortable></Column>
                    <Column field="ipAddress" header="IP address"></Column>
                </DataTable>
        </Dialog>
        </>

    );
}