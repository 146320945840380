import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react'

import Roles from './Roles/Roles';
import Groups from './Groups/Groups';
import Categories from './Categories/Categories'
import Locales from './Locales/Locales'
import CustomTemplates from './CustomTemplates/CustomTemplates'
import EmailHistories from './EmailHistories/EmailHistories'
import UserAssignment from './UserAssignment/UserAssignment'
import WorkflowSteps from './WorkflowSteps/WorkflowSteps';
import WorkflowsList from './WorkflowsList/WorkflowsList';
import Workflow from './Workflow/Workflow'
import Messages from './Messages/Messages'
import Tasks from './Tasks/Tasks';
import TargetDates from './TargetDates/TargetDates'
import Settings from './Settings/Settings'
import ProjectIndex from './ProjectIndex/ProjectIndex';
import { getProjectPermissionsService } from '../../Services/WorkflowAppServices'
import { getApplicationPermissionsService } from '../../Services/AuthenticationServices'

function ProjectConfigs(props) {
    const navigateTo = useNavigate()
    const { projectName } = useParams()
    const [projectPermissions, setProjectPermissions] = useState(null)
    const [applicationPermissions, setApplicationPermissions] = useState(null)
    const [project, setProject] = useState(null)

    useEffect(_ => {
        async function fetchPermissions() {
            let applicationPermissionsResponse = await getApplicationPermissionsService()
            setApplicationPermissions(applicationPermissionsResponse.data.applicationPermissions)

            let response = await getProjectPermissionsService({ projectName })

            if (response.status === 200) {
                setProjectPermissions(response.data.projectPermissions)
                setProject(response.data.project)
                if (Object.values(response.data.projectPermissions).indexOf(true) === -1) {
                    navigateTo('/projects')
                }
            }
        }
        fetchPermissions()
    }, [projectName])

    return (
        <>
            {project && projectPermissions &&
                <Routes>
                    <Route path='/roles/*' element={<Roles applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/groups/*' element={<Groups applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/categories/*' element={<Categories applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/locales/*' element={<Locales applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/customTemplates/*' element={<CustomTemplates applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/emailHistories/*' element={<EmailHistories applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/userassignment/*' element={<UserAssignment applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/workflowsteps/*' element={<WorkflowSteps applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/messages/*' element={<Messages applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/workflows' element={<WorkflowsList applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/workflows/:workflowName/*' element={<Workflow applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/tasks/*' element={<Tasks applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/targetDates/*' element={<TargetDates applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route path='/settings/*' element={<Settings applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                    <Route index element={<ProjectIndex applicationPermissions={applicationPermissions} projectPermissions={projectPermissions} project={project} />} />
                </Routes>
            }
        </>
    )
}

export default ProjectConfigs