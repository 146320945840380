import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import RoleService from '../../../Services/RoleService'
import PermissionService from '../../../Services/PermissionService'

export default function ItemTable(props) {
    let emptyItem = {
        _id: null,
        roleTitle: null,
        projectUuid: props.project.projectUuid,
        permissions: {}
    };
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [item, setItem] = useState(emptyItem);
    const [columns, setColumns] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [error, setError] = useState(null);
    const [itemDialog, setItemDialog] = useState(false);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const [projectPermissions, setProjectPermissions] = useState({})
    const [filters, setFilters] = useState({
        roleTitle: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const fetchItems = useCallback(async () => {
        setLoading(true);
        try {
            let response = await RoleService.find({ projectUuid: props.project.projectUuid })
            let results = response.data
                .filter(item => !item.isProjectAdministrator)
                .map(item => {
                    let permissions = {},
                        itemPermissions = item?.permissions || {}
                    Object.keys(props.projectPermissions).forEach(projectPermission => {
                        if (props.projectPermissions[projectPermission]){
                            permissions[projectPermission] = itemPermissions[projectPermission] ?? false
                        }
                    });
                    return {
                        ...item,
                        permissions: permissions
                    }
                })
            setItems(results);
        } catch (err) {
            console.error(err)
            setError(err.response?.data)
        } finally {
            setLoading(false);
        }
    }, [props.project, props.projectPermissions]) 

    useEffect(() => {
        setProjectPermissions(props.projectPermissions)
        try {
            PermissionService.find({ projectUuid: props.project.projectUuid }).then(response => {
                let permissions = response.data.filter(permission => permission.name in props.projectPermissions && props.projectPermissions[permission.name])
                setColumns(permissions)
                setVisibleColumns(permissions)
                let permissionFilters = []
                for (const permission of permissions) {
                    permissionFilters[`permissions.${permission.name}`] = { value: null, matchMode: FilterMatchMode.EQUALS }
                }
                setFilters({...permissionFilters, ...filters})
            })
            
        } catch (err) {
            console.error(err)
            setError(err.response?.data)
        }
        fetchItems()
    }, [props.project, props.projectPermissions, fetchItems]);

    const confirmDeleteItem = (item) => {
        setItem(item);
        setDeleteItemDialog(true);
    };
    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    };

    const hideItemDialog = () => {
        setSubmitted(false);
        setItemDialog(false);
    };

    const onRowEditComplete = async (e) => {
        setItem(emptyItem);
        for (const field in e.newData) {
            if (field.startsWith('permissions.')) {
                e.newData.permissions[field.split('.')[1]] = e.newData[field]
                delete e.newData[field]
            }
        }
        await saveItem(e.newData)
    }
    const saveItem = async (item) => {
        setSubmitted(true);
        try {
            if (item.roleUuid) {
                await RoleService.update(item.roleUuid, item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Updated', life: 3000 });
            } else {
                await RoleService.create(item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Created', life: 3000 });
            }
            setItemDialog(false);
            setItem(emptyItem);
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response?.data, life: 5000 });
        }
    };

    const deleteItem = async () => {
        try {
            await RoleService.delete(item.roleUuid).then(data => fetchItems())
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 3000 });
            setDeleteItemDialog(false);
            setItem(emptyItem);
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 });
        }

    };
    const itemDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideItemDialog} />
            <Button label="Save" icon="pi pi-check" disabled={item.roleTitle?.length ? false : true} onClick={() => saveItem(item)} />
        </React.Fragment>
    );

    const deleteItemDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteItem} />
        </React.Fragment>
    );

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _item = { ...item };

        _item[`${name}`] = val;

        setItem(_item);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <>
                    {projectPermissions?.canDeleteRoles &&
                        <Button frozen icon="pi pi-trash" outlined severity="danger right-0" className='p-row-editor-init p-link' onClick={() => confirmDeleteItem(rowData)} />}
                </>
            </React.Fragment>
        );
    };

    const openNew = () => {
        setItem(emptyItem);
        setSubmitted(false);
        setItemDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                {projectPermissions?.canCreateRoles &&
                    <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
                }
            </div>
        );
    };
    const permissionTemplate = (rowData, column) => {
        return rowData?.permissions[column.field.split('.')[1]] ? <i className="pi pi-check"></i> : null;
    };

    const onSortFunction = (event) => {
        let data = [...event.data];
        const isPermissionColumn = !['roleTitle'].includes(event.field)
        data.sort((data1, data2) => {
            const value1 = isPermissionColumn ? data1.permissions[event.field.split('.')[1]] : data1[event.field];
            const value2 = isPermissionColumn ? data2.permissions[event.field.split('.')[1]] : data2[event.field];
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2, undefined, { numeric: true });
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return data;
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const permissionEditor = (options) => {
        return <Checkbox checked={options.value} onChange={e => options.editorCallback(e.checked)} style={{ position: 'static' }}></Checkbox>
    };

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol.name === col.name));
        setVisibleColumns(orderedSelectedColumns);
    };
    const header = <MultiSelect value={visibleColumns} filter options={columns} optionLabel="displayName"  onChange={onColumnToggle} className="w-full sm:w-20rem" display="chip" />;

    const permissionFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    return (
        <>
            <div className="card" style={{height: "calc(100vh - 300px)"}}>
                <Toast ref={toast} />
                <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                <DataTable value={items} tableStyle={{}} loading={loading}
                        scrollable scrollHeight="flex" 
                        filterDisplay="row" editMode="row" header={header} filters={filters}
                        onRowEditComplete={onRowEditComplete}
                        sortField="_id" sortOrder="1">
                    <Column field="roleTitle" header='Name' frozen filter filterMatchMode='contains' showFilterMenu={false} 
                            sortable style={{ minWidth: '25rem' }}
                            editor={(options) => textEditor(options)}/>
                    {visibleColumns.map((col, i) => 
                        <Column field={`permissions.${col.name}`}  header={col.displayName}
                                body={permissionTemplate}
                                style={{ textAlign: 'center' }} sortable sortFunction={onSortFunction}
                                filter filterElement={permissionFilterTemplate}
                                dataType="boolean"
                                editor={(options) => permissionEditor(options)} />)}
                    {projectPermissions?.canUpdateRoles &&
                        <Column rowEditor frozen alignFrozen="right" style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    }
                    <Column frozen alignFrozen="right"  body={actionBodyTemplate} exportable={false}></Column>
                </DataTable>
            </div>

            <Dialog visible={deleteItemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {item && (
                        <span>
                            Are you sure you want to delete <b>{item.roleTitle}</b>?
                        </span>
                    )}
                    {error && <small className="p-error">{error}</small>}
                </div>
            </Dialog>

            <Dialog visible={itemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Details" modal className="p-fluid" footer={itemDialogFooter} onHide={hideItemDialog}>
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Name
                    </label>
                    <InputText id="roleTitle" value={item.roleTitle} onChange={(e) => onInputChange(e, 'roleTitle')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.roleTitle })} />
                    {submitted && !item.roleTitle && <small className="p-error">Name is required.</small>}
                </div>
            </Dialog>
        </>

    );
}
