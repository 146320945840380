import breadCrumbArrow from './breadCrumbArrow.svg'

function ArrowWrapper(props){

    return (
        <div className='flex flex-row items-center' style={{height: '50px'}}>
            <div className='ml3' style={{width: '17px', marginTop: '5px'}}>
                <img src={breadCrumbArrow} alt="Bread crumb arrow"/>
            </div>
            <div className='ml3'>
                {props.children}
            </div>
        </div>
    )
}

export default ArrowWrapper