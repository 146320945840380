function FromBox(props){

    const index = props.index
    
    return (
        <div className='flex flex-column w2'>
            <span className='f6 tc'>From</span>
            <span className='pt1 tc ba br1'>{index}</span>
        </div>
    )
}

export default FromBox