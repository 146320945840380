import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import IsNowLoading from "../../../Shared/IsNowLoading";

import { getProjectPermissionsService } from '../../../Services/WorkflowAppServices'

import ProjectPageHeading from "../../../Shared/ProjectPageHeading"
import ProjectIndexTile from "./ProjectIndexTile"

import projectMenuOptions from "./Logos/projectMenuOptions"

function ProjectIndex(props) {

    const { projectName } = useParams()

    const [isNowLoading, setIsNowLoading] = useState(true)
    const [projectPermissions, setProjectPermissions] = useState({})

    useEffect(_ => {
        async function fetchPermissions() {
            let response = await getProjectPermissionsService({ projectName })

            if (response.status === 200) {
                setProjectPermissions(response.data.projectPermissions)
                setIsNowLoading(false)
            }

        }
        fetchPermissions()
    }, [projectName])


    const projectMenuOptionsList = projectMenuOptions.map((projectMenuOption, index) => {
        return (
            <div key={index}>
                {projectPermissions[projectMenuOption.permissionKey] &&
                    <Link
                        to={`/${projectName}/workflow-management/${projectMenuOption.navName}`}
                        className={projectMenuOption.className}
                        style={{ textDecoration: 'none' }}>
                        <ProjectIndexTile
                            name={projectMenuOption.friendlyName}
                            logo={projectMenuOption.logo}
                            description={projectMenuOption.description} />
                    </Link>}
            </div>
        )
    })


    return (
        <IsNowLoading isNowLoading={isNowLoading}>
            <div className='mb5'>
                <ProjectPageHeading pageTitle='Choose a project menu option...'>
                    <div className='flex flex-row flex-wrap'>
                        {projectMenuOptionsList}
                    </div>
                </ProjectPageHeading>
            </div>
        </IsNowLoading>
    )
}

export default ProjectIndex