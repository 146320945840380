import deleteButton from './deleteButton.svg'

function DeleteButton(props){

    const onClick = props.onClick
    const tooltipText = props.tooltipText
    const isNowLoading = props.isNowLoading
    const disableBorder = props.disableBorder
    
    let border
    disableBorder ? border = '' : border = 'ba'

    return (
        <div className={`tc pt1 b dark-red pointer tooltip b--moon-gray ${border}`} style={{width: '25px', height: '30px'}}>
            {!isNowLoading &&
            <div className='delete-button' style={{width: '23px', height: '25px'}} onClick={onClick}>
                <img width='12px' height='22px' src={deleteButton} alt='delete button'/>
                <span className='tooltiptext f6'>{tooltipText}</span>
            </div>}
            {isNowLoading &&
                <div className='flex justify-center'>
                    <div className="nowLoading">
                        <span className="primary f6">.</span>
                        <span className="primary f6">.</span>
                        <span className="primary f6">.</span>
                    </div>
                </div>}
        </div>
    )
}

export default DeleteButton