import { useState } from 'react'
import TransitionModal from './TransitionModal'

function Transition(props){

    const workflowSteps = props.workflowSteps
    const connection = props.connection
    const task = props.task
    const error = props.error
    const setError = props.setError
    const fetchTasks = props.fetchTasks
    const messageHTML = props.messageHTML

    const [renderTransitionModal, setRenderTransitionModal] = useState(false)

    let stepFrom = workflowSteps.find(workflowStep => workflowStep.stepUuid === connection.fromStep) 
    let stepTo = workflowSteps.find(workflowStep => workflowStep.stepUuid === connection.toStep) 
    return (
        <>
        {renderTransitionModal && 
            <TransitionModal 
                stepFrom={stepFrom}
                stepTo={stepTo}
                task={task}
                error={error}
                setError={setError}
                connection={connection}
                fetchTasks={fetchTasks}
                messageHTML={messageHTML}
                setRenderTransitionModal={setRenderTransitionModal}
            />}
            <div 
                className='flex justify-center b f6 bg-secondary primary pa1 br2 pointer apply-transition-button' 
                onClick={_ => { setRenderTransitionModal(true) }}>
                    <div className='tc' style={{width: '90px'}}>
                        Move to {stepTo.stepName}
                    </div>
            </div>
        </>
    )
}

export default Transition