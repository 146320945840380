import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import NowLoading from '../../Shared/NowLoading'
import ProjectInstance from './ProjectInstance'
import CreateANewProjectModal from './CreateANewProjectModal'
import AddNew from '../../Shared/AddNew'

import ProjectService from '../../Services/ProjectService'
import { getApplicationPermissionsService } from '../../Services/AuthenticationServices'

function ProjectList() {

    const navigateTo = useNavigate()
    const [projectsList, setProjectsList] = useState(null)
    const [allProjectNames, setAllProjectNames] = useState([])
    const [applicationPermissions, setApplicationPermissions] = useState(null)
    const [renderCreateANewProjectModal, setRenderCreateANewProjectModal] = useState(false)

    const fetchProjectsList = useCallback(async _ => {
        try {
            let projectResponse = await ProjectService.find()
            let projects = projectResponse.data
            setAllProjectNames(projects.map(project => project.projectName))
            projects = projects.map(project => <ProjectInstance key={project._id} project={project} />)
            setProjectsList(projects)
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(_ => {
        async function fetchApplicationPermissions() {
            let response = await getApplicationPermissionsService()

            if (response.status === 200) {
                setApplicationPermissions(response.data.applicationPermissions)
                if (!response.data.applicationPermissions?.canReadAssignedProjects) {
                    navigateTo('/')
                }
            }
        }
        fetchApplicationPermissions()
        fetchProjectsList()
    }, [fetchProjectsList])

    useEffect(() => {
        // If user cannot create project and only have one project assigned, directly navigate into the assigned project.
        if (!applicationPermissions?.canCreateNewProjects && allProjectNames?.length === 1) {
            navigateTo(`/${allProjectNames[0]}`)
        }
    }, [applicationPermissions, projectsList])

    return (
        <>
            {renderCreateANewProjectModal &&
                <CreateANewProjectModal
                    allProjectNames={allProjectNames}
                    fetchProjectsList={fetchProjectsList}
                    setRenderCreateANewProjectModal={setRenderCreateANewProjectModal} />}
            {!projectsList &&
                <div className='flex justify-center mt5'>
                    <NowLoading />
                </div>}
            {projectsList &&
                <>
                    <div className='flex justify-center'>
                        <div className='flex flex-column mh4'>
                            <div className='f3 mt4'>
                                Projects
                            </div>
                            {applicationPermissions?.canCreateNewProjects &&
                                <AddNew
                                    isLoading={false}
                                    onClick={_ => { setRenderCreateANewProjectModal(true) }}
                                    width='200px'
                                    label='Create a new project' />}
                            <div className='flex justify-center'>
                                <div className='flex flex-column'>
                                    {projectsList}
                                </div>
                                {projectsList.length === 0 && <div className='flex flex-column'>
                                    You are not assigned to any project.
                                </div>}
                            </div>
                        </div>
                    </div>
                </>}
        </>
    )
}

export default ProjectList