import AppService from './AppService'
const baseURL = '/api/workflow-app'
const API = AppService.initAPI(baseURL)

/* Connection services */
export async function addConnectionService(serviceProps){
    return await API.post('/createConnection', serviceProps)
}

export async function updateConnectionService(serviceProps){
    return await API.post('/updateConnection', serviceProps)
}

export async function deleteConnectionService(serviceProps){
    return await API.post('/deleteConnection', serviceProps) 
}
/* END Connection services */

/* Message services */
export async function addANewMessageService(serviceProps){
    return await API.post('/addANewMessage', serviceProps)
}

export async function updateMessageService(serviceProps){
    return await API.post('/updateMessage', serviceProps)
}

export async function deleteMessageService(serviceProps){
    return await API.post('/deleteMessage', serviceProps)
}
/* END Message services */

/* Project services */
export async function updateProjectService(serviceProps){
    return await API.post('/updateProject', serviceProps)
}

export async function deleteProjectService(serviceProps){
    return await API.post('/deleteProject', serviceProps)
}
/* END Project services */


/* Task services */
export async function createBulkTasksService(serviceProps, projectName){
    return await API.post(`/createBulkTasks/${projectName}`, serviceProps)
}

export async function getGroupsAndWorkflowsService(serviceProps){
    const projectName = serviceProps.projectName
    return await API.get(`/getGroupsAndWorkflows/${projectName}`)
}
/* END Task services */


/* User assignment services */
export async function assignGroupsToUserInProjectService(serviceProps){
    return await API.post('/assignGroupsToUserInProject', serviceProps)
}

export async function assignNewUserService(serviceProps){
    return await API.post('/assignNewUser', serviceProps)
}

export async function assignRolesToUserInProjectService(serviceProps){
    return await API.post('/assignRolesToUserInProject', serviceProps)
}

export async function assignUserToProjectService(serviceProps){
    return await API.post('/assignUserToProject', serviceProps)
}

export async function unassignUserFromProjectService(serviceProps){
    return await API.post('/unassignUserFromProject', serviceProps)
}
/* END user assignment services */


/* Workflow services */
export async function addNewWorkflowService(serviceProps){
    return await API.post('/addNewWorkflow', serviceProps)
}

export async function assignStepToWorkflowService(serviceProps){
    return await API.post('/assignStepToWorkflow', serviceProps)
}

export async function deleteWorkflowService(serviceProps){
    return await API.post('/deleteWorkflow', serviceProps)
}

export async function getWorkflowBuilderStateService(serviceProps){
    const workflowName = serviceProps.workflowName
    const projectName = serviceProps.projectName
    return await API.get(`/getWorkflowBuilderState/${projectName}/${workflowName}`)
}

export async function removeStepFromWorkflowService(serviceProps){
    return await API.post('/removeStepFromWorkflow', serviceProps)
}

export async function updateWorkflowNameService(serviceProps){
    return await API.post('/updateWorkflowName', serviceProps)
}

export async function moveStepService(serviceProps){
    return await API.post('/moveStep', serviceProps)
}
/* END workflow services */


/* Permission services */
export async function getProjectPermissionsService(serviceProps){
    const projectName = serviceProps.projectName
    return await API.get(`/getProjectPermissions/${projectName}`) 
}
/* END Permission services */