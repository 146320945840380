import PageSubHeading from "../../../Shared/PageSubHeading"

function Setting(props){

    const settingName = props.settingName
    
    return (
        <PageSubHeading subHeading={settingName}>
            {props.children}
        </PageSubHeading>
    )
}

export default Setting