function ToBox(props){

    const connection = props.connection
    const assignedSteps = props.assignedSteps
    let toStepIndex = assignedSteps.indexOf(connection.toStep)
    if (toStepIndex === -1) {
        toStepIndex = 0
    }
    const toIndex = toStepIndex + 1

    return (
        <div className='flex flex-column w2'>
            <span className='f6 tc'>To</span>
            <span className='pt1 tc ba br1'>{toIndex}</span>
        </div>
    )
}

export default ToBox