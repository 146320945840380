import React, { useState, useEffect, useRef, useCallback } from 'react'
import { DataTable } from 'primereact/datatable'
import { classNames } from 'primereact/utils'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Editor } from "primereact/editor"
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import { FilterMatchMode } from 'primereact/api'
import EmailTemplateService from '../../../Services/EmailTemplateService'
import { Tooltip } from 'primereact/tooltip';

export default function ItemTable(props) {
    let emptyItem = {
        _id: null,
        templateName: null,
        projectUuid: props.project.projectUuid
    };
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [item, setItem] = useState(emptyItem)
    const setError = props.setError
    const [itemDialog, setItemDialog] = useState(false)
    const [deleteItemDialog, setDeleteItemDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const toast = useRef(null)
    const [projectPermissions, setProjectPermissions] = useState({})
    const [filters] = useState({
        templateName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        creator: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const [templateHTML, setTemplateHTML] = useState()

    const fetchItems = useCallback(async () => {
        setLoading(true);
        try {
            let response = await EmailTemplateService.find({ projectUuid: props.project.projectUuid })
            setItems(response.data);
        } catch (err) {
            console.error(err)
            setError(err.response.data)
        } finally {
            setLoading(false);
        }
    }, [props.project, setError])

    useEffect(() => {
        setProjectPermissions(props.projectPermissions)
        fetchItems();
    }, [props.projectPermissions, fetchItems]);

    const hideItemDialog = () => {
        setSubmitted(false);
        setItemDialog(false);
    };

    const saveItem = async () => {
        setSubmitted(true)
        item.templateHTML = templateHTML || item.templateHTM
        try {
            if (item.templateUuid) {
                await EmailTemplateService.update(item.templateUuid, item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Updated', life: 3000 });
            } else {
                await EmailTemplateService.create(item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Created', life: 3000 });
            }
            setItemDialog(false);
            setItem(emptyItem);
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 });
        }
    };

    const editItem = (item) => {
        setItem({ ...item });
        setSubmitted(false);
        setItemDialog(true);
    };

    const confirmDeleteItem = (item) => {
        setItem(item);
        setDeleteItemDialog(true);
    };
    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    };

    const deleteItem = async () => {
        try {
            await EmailTemplateService.delete(item.templateUuid).then(data => fetchItems())
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 3000 });
            setDeleteItemDialog(false);
            setItem(emptyItem);
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 });
        }

    };

    const deleteItemDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteItem} />
        </React.Fragment>
    );

    const itemDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideItemDialog} />
            <Button label="Save" icon="pi pi-check" className="bg-primary" onClick={saveItem} />
        </React.Fragment>
    )

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || ''
        let _item = { ...item }

        _item[`${name}`] = val

        setItem(_item)
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <>
                    {projectPermissions?.canUpdateCustomTemplates &&
                        <Button icon="pi pi-pencil" outlined className="mr-2 bg-primary right-0" onClick={() => editItem(rowData)} />}
                    {projectPermissions?.canDeleteCustomTemplates &&
                        <Button icon="pi pi-trash" outlined severity="danger bg-primary right-0" onClick={() => confirmDeleteItem(rowData)} />}
                </>
            </React.Fragment>
        )
    }

    const openNew = () => {
        setItem(emptyItem)
        setSubmitted(false)
        setItemDialog(true)
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                {projectPermissions?.canCreateCustomTemplates &&
                    <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
                }
            </div>
        )
    }

    const convertDateToLocaleString = (date) => {
        const convertedDate = new Date(date).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
        return convertedDate
    }

    const dateCreatedBodyTemplate = (rowData) => {
        const formatedDate = convertDateToLocaleString(rowData?.createdAt || rowData.dateCreated)
        return formatedDate
    }

    const dateUpdatedBodyTemplate = (rowData) => {
        const formatedDate = convertDateToLocaleString(rowData?.updatedAt || rowData.dateUpdated)
        return formatedDate
    }

    return (
        <>
            <div className="card" style={{ height: "calc(100vh - 300px)" }}>
                <Toast ref={toast} />
                <Toolbar className="mb-3" left={leftToolbarTemplate}></Toolbar>
                <DataTable value={items} tableStyle={{}} loading={loading}
                    scrollable scrollHeight="flex"
                    filters={filters} filterDisplay="row"
                    sortField="dateUpdated" sortOrder="-1">
                    <Column field="templateName" filter showFilterMenu={false} sortable header="Name"></Column>
                    <Column field="creator" filter showFilterMenu={false} sortable header="Creator"></Column>
                    <Column field="dateCreated" body={dateCreatedBodyTemplate} sortable header="Date Created"></Column>
                    <Column field="dateUpdated" body={dateUpdatedBodyTemplate} sortable header="Date Updated"></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={deleteItemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {item && (
                        <span>
                            Are you sure you want to delete <b>{item.templateName}</b>?
                        </span>
                    )}
                </div>
            </Dialog>
            <Dialog visible={itemDialog} style={{ width: '50rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Details" modal className="p-fluid" footer={itemDialogFooter} onHide={hideItemDialog}>
                <div className="field">
                    <label htmlFor="templateName" className="font-bold">
                        Name
                    </label>
                    <InputText id="templateName" value={item.templateName} onChange={(e) => onInputChange(e, 'templateName')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.templateName })} />
                    {submitted && !item.templateName && <small className="p-error">Name is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="templateSubject" className="font-bold">
                        Subject
                    </label>
                    <InputText id="templateSubject" value={item.templateSubject} onChange={(e) => onInputChange(e, 'templateSubject')} required className={classNames({ 'p-invalid': submitted && !item.templateSubject })} />
                    {submitted && !item.templateSubject && <small className="p-error">Subject is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="templateHTML" className="font-bold">
                        Template
                        <i className="tooltips pi pi-info-circle" style={{ fontSize: '1rem', marginLeft: '5px' }}></i>
                    </label>
                    <Tooltip
                        target=".tooltips"
                        content='Available variables: ${taskName}, ${localeName}, ${groupName}, ${categoryName}, ${fromStepName}, ${toStepName}, ${projectName}, ${numberOfFiles}, ${taskUuid}, ${projectUuid}, ${toStepNameLowercase}, ${comment}'
                        mouseTrack
                        mouseTrackLeft={10}
                    />
                    <div className="card">
                        <Editor value={item.templateHTML} onTextChange={(e) => setTemplateHTML(e.htmlValue)} style={{ height: '200px' }} />
                    </div>
                </div>
            </Dialog>
        </>
    )
}