import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import ProjectName from '../../ProjectList/ProjectName'
import CreateCancelSubmitLoading from '../../../Shared/CreateCancelSubmitLoading'

import { updateProjectService } from '../../../Services/WorkflowAppServices'

function UpdateProjectDetails(props) {

    const navigateTo = useNavigate()

    const { projectName } = useParams()

    const [clickedSubmit, setClickedSubmit] = useState(false)
    const [projectNameDisplayedToUpdate, setProjectNameDisplayedToUpdate] = useState('')
    const [projectNameToUpdate, setProjectNameToUpdate] = useState('')
    const [validDataForLaunch, setValidDataForLaunch] = useState({ projectName: false })

    async function handleUpdateProjectClick() {
        if (Object.values(validDataForLaunch).includes(false))
            return

        setClickedSubmit(true)
        let serviceProps = { projectName, projectNameToUpdate, projectNameDisplayedToUpdate }
        let response = await updateProjectService(serviceProps)
        // TODO handle error case
        // if (response.status === 400) {
        //     setIsSomethingWentWrong(true)
        //     setProjectName('')
        // }

        if (response.status === 200) {
            setClickedSubmit(false)
            navigateTo(`/${projectNameToUpdate}/workflow-management/settings`)
        }
    }

    return (
        <div className='mr2' >
            <div className='ml2 pt3'>The current project code is: <strong>{projectName}</strong></div>
            <div className='mh2 dark-gray'>
                <div className='flex flex-column'>
                    <ProjectName
                        projectName={projectNameToUpdate}
                        setProjectName={setProjectNameToUpdate}
                        projectNameDisplayed={projectNameDisplayedToUpdate}
                        setProjectNameDisplayed={setProjectNameDisplayedToUpdate}
                        allProjectNames={[]}
                        validDataForLaunch={validDataForLaunch}
                        setValidDataForLaunch={setValidDataForLaunch}
                    />
                </div>
            </div>
            <div className='mr2'>
                <CreateCancelSubmitLoading
                    isSomethingWentWrong={false}
                    isNowLoading={clickedSubmit}
                    createButtonText='Update'
                    errorMessage='Something went wrong'
                    handleCreateResourceClick={handleUpdateProjectClick}
                    handleCloseErrorBoxClick={_ => { }}
                    buttonPosition='left'
                />
            </div>
        </div>
    )
}

export default UpdateProjectDetails;