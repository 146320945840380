import { useState } from 'react'
import { useParams } from 'react-router-dom'
import MiniCheckboxAndLabel from './MiniCheckboxAndLabel'
import CheckedLabel from './CheckedLabel'

import reactHTMLParser from 'react-html-parser'
import Select from 'react-select'
import SaveButton from "../../../Shared/SaveButton"
import CancelButton from "../../../Shared/CancelButton"
import UpdateButton from '../../../Shared/UpdateButton'
import DeleteButton from '../../../Shared/DeleteButton'

import FromBox from './FromBox'
import FromBoxCanEdit from './FromBoxCanEdit'
import ArrowRight from './ArrowRight'
import ToBox from './ToBox'
import ToBoxCanEdit from './ToBoxCanEdit'
import RolesList from './RolesList'
import RoleToEmailList from './RoleToEmailList'
import ActionsList from './ActionsList'
import Message from './Message'
import {
    updateConnectionService,
    deleteConnectionService
} from '../../../Services/WorkflowAppServices'
import { alphabeticalSortByKey } from '../../../Shared/sortHelper'

function Connection(props) {

    const { projectName } = useParams()

    const index = props.index
    const roles = props.roles
    const workflowUuid = props.workflowUuid
    const assignedSteps = props.assignedSteps
    const connection = props.connection
    const messages = props.messages
    const projectPermissions = props.projectPermissions
    const fetchWorkflowBuilderState = props.fetchWorkflowBuilderState
    const emailTemplateOptions = props.emailTemplateOptions
    const allSteps = props.allSteps
    const connectionUuid = connection.connectionUuid
    const [connectionToUpdate, setConnectionToUpdate] = useState(connection)
    const [deleteIsNowLoading, setDeleteIsNowLoading] = useState(false)
    const [saveIsNowLoading, setSaveIsNowLoading] = useState(false)
    const [isDiffSelected, setIsDiffSelected] = useState(false)
    const [renderUpdateFunctionality, setRenderUpdateFunctionality] = useState(false)
    const [error, setError] = useState(null)
    const [actionToUpdate, setActionToUpdate] = useState(null)
    const [queueOptionToUpdate, setQueueOptionToUpdate] = useState(null)
    const [templateToUpdate, setTemplateToUpdate] = useState(null)
    const [roleAccessToUpdate, setRoleAccessToUpdate] = useState(null)
    const [roleToEmailToUpdate, setRoleToEmailToUpdate] = useState(null)
    const [messageToUpdate, setMessageToUpdate] = useState(null)
    let initialComparedStep = null
    const currentStep = allSteps.find(step => step.stepUuid === connection.fromStep)
    if (currentStep) {
        initialComparedStep = {
            value: connection.fromStep,
            label: currentStep.stepName
        }
    }
    const [diffComparedStepToUpdate, setDiffComparedStepToUpdate] = useState(initialComparedStep)
    const [diffOriginalStepToUpdate, setDiffOriginalStepToUpdate] = useState(null)

    const roleOptions = roles.map(role => {
        return ({
            value: role.roleUuid,
            label: role.roleTitle
        })
    })
    //TODO Here is PISA action option list hardcoded, change to retrive data from DB for the other projects.
    const actionOptions = [
        { value: 'copy', label: 'Copy translation' },
        { value: 'copyAuto', label: 'Copy translation auto' },
        { value: 'selfCopy', label: 'Self-copy translation' },
        { value: 'selfCopyWithConfirmation', label: 'Self-copy translation with confirmation' },
        { value: 'copyFinal', label: 'Copy translation final' },
        { value: 'upload', label: 'File upload' },
        { value: 'uploadMandatory', label: 'File upload mandatory' },
        { value: 'checkCommit', label: 'Check commit' },
        { value: 'checkComplete', label: 'Check completion' },
        { value: 'removeSource', label: 'Remove source mapping' },
        { value: 'createPreviewTag', label: 'Add preview tag' },
        { value: 'addSourceAtNextStep', label: 'Add source mapping' },
        { value: 'borrower', label: 'Update borrower locales' },
        { value: 'diff', label: 'Generate diff report' },
        { value: 'copyBackwards', label: 'Copy translation backwards' },
    ]

    const queueOptions = [
        { value: 'Append', label: 'Append' },
        { value: 'Add', label: 'Add' },
        { value: 'Replace', label: 'Replace' }
    ]

    const messageOptions = messages.map(message => {
        return ({
            value: message.messageUuid,
            label: reactHTMLParser(message.messageHTML)
        })
    })

    const diffComparedStepOptions = assignedSteps.map(assignedStep => {
        const step = allSteps.find(s => s.stepUuid === assignedStep)
        return ({
            value: assignedStep,
            label: step.stepName
        })
    })

    let stepsWithoutCurrent = assignedSteps.filter(assignedStep => assignedStep !== connection.fromStep);

    const diffOriginalStepOptions = stepsWithoutCurrent.map(assignedStep => {
        const step = allSteps.find(s => s.stepUuid === assignedStep)
        return ({
            value: assignedStep,
            label: step.stepName
        })
    })

    async function handleSaveConnectionClick() {
        const actionValues = connectionToUpdate.actions.map(action => action.value)
        const isDiffSelected = actionValues.includes('diff')
        if (isDiffSelected && !connectionToUpdate.diffComparedStep) {
            connectionToUpdate.diffComparedStep = initialComparedStep
        }
        if (isDiffSelected && !connectionToUpdate.diffOriginalStep) {
            setError('You must select the original translation step for diff report.')
            return
        }
        if (isDiffSelected && connectionToUpdate.diffOriginalStep.value === connectionToUpdate.diffComparedStep.value) {
            setError('You must select two different steps to generate diff report.')
            return
        }
        setSaveIsNowLoading(true)
        // clean the diffComparedStep if diff action is un-selected
        if (!isDiffSelected && connectionToUpdate.diffComparedStep) {
            connectionToUpdate.diffComparedStep = null
        }
        let serviceProps = { connectionToUpdate, workflowUuid, projectName }
        let response = await updateConnectionService(serviceProps)

        if (response.status === 200) {
            setSaveIsNowLoading(false)
            setRenderUpdateFunctionality(false)
            fetchWorkflowBuilderState()
        }
    }

    async function handleDeleteConnectionClick() {
        setDeleteIsNowLoading(true)

        let serviceProps = { connectionUuid, workflowUuid, projectName }
        let response = await deleteConnectionService(serviceProps)

        if (response.status === 200) {
            fetchWorkflowBuilderState()
            setTimeout(_ => {
                setDeleteIsNowLoading(false)
            }, 500)
        }
    }

    // TODO pull out this functionality and create a hook, it's used in at least 6 other places.
    function handleRoleAccessChange(roleAccessChange) {
        setRoleAccessToUpdate(roleAccessChange)
        let newRoleAccess = roleAccessChange.map(roleAccess => roleAccess.value)
        let connectionToUpdateTemp = JSON.parse(JSON.stringify(connectionToUpdate))
        connectionToUpdateTemp.roleAccess = newRoleAccess
        setConnectionToUpdate(connectionToUpdateTemp)
    }

    function handleRoleToEmailChange(roleToEmailChange) {
        setRoleToEmailToUpdate(roleToEmailChange)
        let newRoleToEmail = roleToEmailChange.map(roleToEmail => roleToEmail.value)
        let connectionToUpdateTemp = JSON.parse(JSON.stringify(connectionToUpdate))
        connectionToUpdateTemp.roleToEmail = newRoleToEmail
        setConnectionToUpdate(connectionToUpdateTemp)
    }

    function handleActionChange(actionChange) {
        setActionToUpdate(actionChange)
        let connectionToUpdateTemp = JSON.parse(JSON.stringify(connectionToUpdate))
        if (actionChange.some(action => action.value === 'diff')) {
            setIsDiffSelected(true)
        } else {
            setIsDiffSelected(false)
            setDiffComparedStepToUpdate(initialComparedStep)
            setDiffOriginalStepToUpdate(null)
            connectionToUpdateTemp.diffOriginalStep = null
        }
        connectionToUpdateTemp.actions = actionChange
        setConnectionToUpdate(connectionToUpdateTemp)
    }

    function handleDiffComparedStepChange(diffComparedStepChange) {
        setError(null)
        setDiffComparedStepToUpdate(diffComparedStepChange)
        let connectionToUpdateTemp = JSON.parse(JSON.stringify(connectionToUpdate))
        connectionToUpdateTemp.diffComparedStep = diffComparedStepChange
        setConnectionToUpdate(connectionToUpdateTemp)
    }

    function handleDiffOriginalStepChange(diffOriginalStepChange) {
        setError(null)
        setDiffOriginalStepToUpdate(diffOriginalStepChange)
        let connectionToUpdateTemp = JSON.parse(JSON.stringify(connectionToUpdate))
        connectionToUpdateTemp.diffOriginalStep = diffOriginalStepChange
        setConnectionToUpdate(connectionToUpdateTemp)
    }

    function handleQueueOptionChange(queueOptionChange) {
        setQueueOptionToUpdate(queueOptionChange)
        let connectionToUpdateTemp = JSON.parse(JSON.stringify(connectionToUpdate))
        connectionToUpdateTemp.queueOption = queueOptionChange.value
        setConnectionToUpdate(connectionToUpdateTemp)
    }

    function handleTemplateChange(templateChange) {
        setTemplateToUpdate(templateChange)
        let connectionToUpdateTemp = JSON.parse(JSON.stringify(connectionToUpdate))
        connectionToUpdateTemp.emailTemplateUuid = templateChange.value
        connectionToUpdateTemp.emailTemplateName = templateChange.label
        setConnectionToUpdate(connectionToUpdateTemp)
    }

    function handleMessageChange(newMessage) {
        setMessageToUpdate(newMessage)
        let connectionToUpdateTemp = JSON.parse(JSON.stringify(connectionToUpdate))
        connectionToUpdateTemp.messageUuid = newMessage.value
        setConnectionToUpdate(connectionToUpdateTemp)
    }
    function handleRenderUpdateFunctionalityButtonClick() {
        let roleAccessUuids = connection.roleAccess
        let roleToEmailUuids = connection.roleToEmail
        let selectedRolesInit = roleAccessUuids.map(roleUuid => {
            return ({
                value: roleUuid,
                label: roles.find(role => role.roleUuid === roleUuid).roleTitle
            })
        })

        let selectedRolesToEmail = roleToEmailUuids.map(roleUuid => {
            return ({
                value: roleUuid,
                label: roles.find(role => role.roleUuid === roleUuid).roleTitle
            })
        })

        const initialTemplateName = { value: connection.emailTemplateUuid, label: connection.emailTemplateName }
        setTemplateToUpdate(initialTemplateName)
        const initialQueueOption = connection.queueOption ? { value: connection.queueOption, label: connection.queueOption } : { value: 'Append', label: 'Append' }
        setQueueOptionToUpdate(initialQueueOption)
        setActionToUpdate(connection.actions)
        setRoleToEmailToUpdate(selectedRolesToEmail)
        setRoleAccessToUpdate(selectedRolesInit)
        setConnectionToUpdate(connection)
        setRenderUpdateFunctionality(true)

        const actionValues = connection.actions.map(action => action.value)
        const isDiff = actionValues.includes('diff')
        setIsDiffSelected(isDiff)
        if (isDiff && connection.diffComparedStep) {
            setDiffComparedStepToUpdate(connection.diffComparedStep)
        }
        if (isDiff && connection.diffOriginalStep) {
            setDiffOriginalStepToUpdate(connection.diffOriginalStep)
        }
    }

    return (
        <>
            {renderUpdateFunctionality &&
                <div className='ba b--dashed ma1 bg-light-secondary'>
                    <div className='flex justify-around items-center pa1'>
                        <SaveButton
                            tooltipText='Save changes'
                            isNowLoading={saveIsNowLoading}
                            onClick={handleSaveConnectionClick} />
                        <div className='flex items-center ml1'>
                            <FromBoxCanEdit index={index} />
                            <ArrowRight />
                            <ToBoxCanEdit
                                assignedSteps={assignedSteps}
                                connectionToUpdate={connectionToUpdate}
                                setConnectionToUpdate={setConnectionToUpdate} />
                        </div>
                        <div className='ml1 mr1'>
                            <MiniCheckboxAndLabel
                                connectionToUpdate={connectionToUpdate}
                                setConnectionToUpdate={setConnectionToUpdate}
                                name='isEmail'
                                label='Email' />
                        </div>
                        <CancelButton
                            tooltipText='Cancel changes'
                            onClick={_ => {
                                setRenderUpdateFunctionality(false)
                                setError(null)
                            }} />
                    </div>
                    {connectionToUpdate.isEmail && <div className='f6'>
                        <div className='mt1 ml1'>Email configuration:</div>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={handleTemplateChange}
                            options={alphabeticalSortByKey(emailTemplateOptions, 'label')}
                            placeholder='Email template...'
                            width='400px'
                            value={templateToUpdate}
                            isMulti={false} />
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={handleRoleToEmailChange}
                            options={alphabeticalSortByKey(roleOptions, 'label')}
                            placeholder='Email to role...'
                            width='400px'
                            value={roleToEmailToUpdate}
                            isMulti={true} />
                    </div>}
                    <div className='mt1 mb1 f6'>
                        <div className='mt1 ml1'>Actions:</div>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={handleActionChange}
                            options={alphabeticalSortByKey(actionOptions, 'label')}
                            placeholder='Actions...'
                            width='400px'
                            value={actionToUpdate}
                            isMulti={true} />
                    </div>
                    {isDiffSelected && <div className='mt1 mb1 f6'>
                        <div className='mt1 ml1'>Generate diff report between:</div>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={handleDiffComparedStepChange}
                            options={alphabeticalSortByKey(diffComparedStepOptions, 'label')}
                            placeholder='Select the step of the edited translation'
                            width='400px'
                            value={diffComparedStepToUpdate}
                            isMulti={false} />
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={handleDiffOriginalStepChange}
                            options={alphabeticalSortByKey(diffOriginalStepOptions, 'label')}
                            placeholder='Select the step of the original translation'
                            width='400px'
                            value={diffOriginalStepToUpdate}
                            isMulti={false} />
                    </div>}
                    <div className='mt1 mb1 f6'>
                        <div className='mt1 ml1'>Queue option:</div>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={handleQueueOptionChange}
                            options={alphabeticalSortByKey(queueOptions, 'label')}
                            placeholder='Queue options...'
                            width='400px'
                            value={queueOptionToUpdate}
                            isMulti={false} />
                    </div>
                    <div className='mt1 mb1 f6'>
                        <div className='mt1 ml1'>Role access:</div>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={handleRoleAccessChange}
                            options={alphabeticalSortByKey(roleOptions, 'label')}
                            placeholder='User access...'
                            width='400px'
                            value={roleAccessToUpdate}
                            isMulti={true} />
                    </div>
                    <div className='mt1 mb1 f6'>
                        <div className='mt1 ml1'>Message:</div>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={handleMessageChange}
                            options={messageOptions}
                            placeholder='Message...'
                            width='400px'
                            value={messageToUpdate} />
                    </div>
                    {error && <div className='flex ma2 dark-red'>{error}</div>}
                </div>}
            {!renderUpdateFunctionality &&
                <div className='ba b--dashed ma1'>
                    <div className='flex justify-around items-center pa1'>
                        {projectPermissions?.canUpdateWorkflows &&
                            <div className='update-connection-button'>
                                <UpdateButton
                                    onClick={handleRenderUpdateFunctionalityButtonClick}
                                    tooltipText='Update connection'
                                />
                            </div>}
                        {!projectPermissions?.canUpdateWorkflows && <div></div>}
                        <div className='flex items-center ml1'>
                            <FromBox index={index} />
                            <ArrowRight />
                            <ToBox
                                connection={connection}
                                assignedSteps={assignedSteps} />
                        </div>
                        <div className='ml1 mr1'>
                            <CheckedLabel isChecked={connection.isEmail} label='Email' />
                        </div>
                        {projectPermissions?.canUpdateWorkflows &&
                            <div className='remove-connection-button'>
                                <DeleteButton
                                    onClick={handleDeleteConnectionClick}
                                    tooltipText='Delete connection'
                                    isNowLoading={deleteIsNowLoading} />
                            </div>}
                        {!projectPermissions?.canUpdateWorkflows && <div></div>}
                    </div>
                    {connectionToUpdate.isEmail && <>
                        <div className='f6 flex flex-row mt1'>
                            <span className='b ml1 workflow-config-step-title'>
                                Email template:
                            </span>
                            <span className='ml1 workflow-config-step-value'>
                                {connection.emailTemplateName ?? ''}
                            </span>
                        </div>
                        <RoleToEmailList roles={roles} connection={connection} />
                    </>}
                    <ActionsList actionsList={connection.actions} />
                    {connection.diffComparedStep && connection.diffOriginalStep && <div className='f6 flex flex-row mt1'>
                        <span className='b ml1 workflow-config-step-title'>
                            Diff report between:
                        </span>
                        <span className='ml1 workflow-config-step-value'>
                            {`Orig: ${connection.diffOriginalStep.label ?? ''} & Edit: ${connection.diffComparedStep.label ?? ''}`}
                        </span>
                    </div>}
                    <div className='f6 flex flex-row mt1'>
                        <span className='b ml1 workflow-config-step-title'>
                            Queue option:
                        </span>
                        <span className='ml1 workflow-config-step-value'>
                            {connection.queueOption ?? 'Append'}
                        </span>
                    </div>
                    <RolesList roles={roles} connection={connection} />
                    <Message messages={messages} connection={connection} />
                </div>}
        </>
    )
}

export default Connection