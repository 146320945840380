import { useState } from 'react'
import reactHtmlParser from 'react-html-parser'
import Step from '../../../TaskView/Step'
import Modal from '../../../Shared/Modal'
import ArrowDown from '../../../TaskView/ArrowDown'
import CreateCancelSubmitLoading from '../../../Shared/CreateCancelSubmitLoading'

// this is for just preview from workflow messages
function TransitionModalPreview(props) {
    const stepFrom = props.stepFrom
    const stepTo = props.stepTo
    const task = props.task
    const error = props.error
    const messageHTML = props.messageHTML
    const setRenderTransitionModal = props.setRenderTransitionModal
    const [isNowLoading] = useState(false)
    const [comment, setComment] = useState('')

    function handleCommentChange(e) {
        setComment(e.target.value)
    }

    return (
        <>
            <Modal modalTitle='Task transition' onClickClose={_ => { setRenderTransitionModal(false) }}>
                <div className='pl2 pr2 pb2'>
                    <div>
                        <p className='dark-gray mb4'>You are about to action the following transition on the task <strong>{task.taskName}</strong>.</p>
                        <Step step={stepFrom} bg='bg-light-secondary' />
                        <ArrowDown />
                        <Step step={stepTo} bg='bg-white' />
                        {messageHTML &&
                            <div className='mt4'>
                                {reactHtmlParser(messageHTML)}
                            </div>}
                        <div className='mt4'>
                            <p className='b mb1'>Comment:</p>
                            <textarea
                                className='transitionTextarea'
                                style={{ width: '100%', padding: '10px', border: '1px solid #00699F', borderRadius: '0.25rem' }}
                                cols='80'
                                rows='3'
                                maxLength='2000'
                                name='comment'
                                onChange={handleCommentChange}
                                value={comment}
                                placeholder='You can add a comment for this transition.' />
                        </div>
                        {!error &&
                            <div style={{ marginTop: '45px' }}>
                                <CreateCancelSubmitLoading
                                    isSomethingWentWrong={false}
                                    isNowLoading={isNowLoading}
                                    createButtonText={<div className='pl3 pr3 pt1 pb1 b'>Apply transition</div>}
                                    errorMessage=''
                                    handleCloseErrorBoxClick={_ => { }}
                                    buttonPosition='centered' />
                            </div>}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default TransitionModalPreview