import { useState } from 'react'

function ProjectName(props) {

    const projectName = props.projectName
    const setProjectName = props.setProjectName
    const projectNameDisplayed = props.projectNameDisplayed

    const allProjectNames = props.allProjectNames
    const validDataForLaunch = props.validDataForLaunch
    const setValidDataForLaunch = props.setValidDataForLaunch
    const setProjectNameDisplayed = props.setProjectNameDisplayed
    const validProjectNameTest = new RegExp("^[a-zA-Z0-9-_]+$")
    const validProjectNameDisplayedTest = new RegExp("^[a-zA-Z0-9-_ ]+$") 

    const [projectNameTaken, setProjectNameTaken] = useState(false)
    function handleProjectNameDisplayedChange(e) {
        let validDataForLaunchTemp = JSON.parse(JSON.stringify(validDataForLaunch))
        const proposedProjectNameDisplayed = e.target.value

        if (!validProjectNameDisplayedTest.exec(proposedProjectNameDisplayed) || proposedProjectNameDisplayed.length < 4){
            validDataForLaunchTemp.projectNameDisplayed = false
            setValidDataForLaunch(validDataForLaunchTemp)
        } else {
            validDataForLaunchTemp.projectNameDisplayed = true
            setValidDataForLaunch(validDataForLaunchTemp)
        }
    
        setProjectNameDisplayed(proposedProjectNameDisplayed)
    }

    function handleProjectNameChange(e) {
        let validDataForLaunchTemp = JSON.parse(JSON.stringify(validDataForLaunch))
    
        const proposedProjectName = e.target.value;
    
        if (!allProjectNames.includes(proposedProjectName) && 
            validProjectNameTest.exec(proposedProjectName) &&
            proposedProjectName.length >= 4){
                setProjectNameTaken(false) 
                validDataForLaunchTemp.projectName = true
                setValidDataForLaunch(validDataForLaunchTemp)
        }
    
        if (allProjectNames.includes(proposedProjectName)){
                setProjectNameTaken(true)
                validDataForLaunchTemp.projectName = false
                setValidDataForLaunch(validDataForLaunchTemp)
        }
    
        if (!validProjectNameTest.exec(proposedProjectName) || proposedProjectName.length < 4){
            setProjectNameTaken(false)
            validDataForLaunchTemp.projectName = false
            setValidDataForLaunch(validDataForLaunchTemp)
        }
    
        setProjectName(proposedProjectName)
    }


    return (
        <>
            <p>Choose a project name</p>
                <label className="b"> Project name:&nbsp;
                    <input 
                        className='tc project-name' 
                        style={{width: '320px'}} 
                        type='text' 
                        name='projectNameDisplayed' 
                        onChange={handleProjectNameDisplayedChange} 
                        value={projectNameDisplayed} 
                        placeholder='Example: PISA 2025 FT'/>
                </label>
            {!validProjectNameDisplayedTest.exec(projectNameDisplayed) && 
            projectNameDisplayed.length !== 0 && 
                <p className='dark-red'>Please use letters, numbers, underscore, and dashes only.</p>}

            <p>Choose a project code that is descriptive and unqiue with no spaces.</p>
                <label className="b"> Project code:&nbsp;
                    <input 
                        className='tc project-name' 
                        style={{width: '320px'}} 
                        type='text' 
                        name='projectName' 
                        onChange={handleProjectNameChange} 
                        value={projectName} 
                        placeholder='Example: PISA-2025-FT'/>
                </label>

            {projectName === '' && 
                <p className='white'>-</p>}

            {validProjectNameTest.exec(projectName) && 
                projectName.length < 4 && 
                    <p className='white'>-</p>}

            {validProjectNameTest.exec(projectName) && 
                projectName.length > 3 && 
                    !projectNameTaken && projectName !== '' && 
                        projectName.indexOf(' ') === -1 && 
                            <p className='dark-green'>{projectName} is available &#10003;</p>}

            {projectNameTaken && 
                <p className='dark-red'>{projectName} is already taken. Please choose a different project code.</p>}

            {!validProjectNameTest.exec(projectName) && 
                projectName.length !== 0 && 
                    <p className='dark-red'>Please use letters, numbers, underscore, and dashes only.</p>}
        </>
        )
}

export default ProjectName;