import { useState, useEffect } from 'react'

import workflowManagementLogo from './workflowManagementLogo.svg'
import taskViewLogo from './taskViewLogo.svg'
import IsNowLoading from '../../Shared/IsNowLoading'
import { useNavigate, Link, useParams } from 'react-router-dom'
import IndexTile from './IndexTile'

import { getProjectPermissionsService } from '../../Services/WorkflowAppServices'


function IndexPage() {
    const navigateTo = useNavigate()
    const { projectName } = useParams()
    const [isNowLoading, setIsNowLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    useEffect(_ => {
        async function fetchPermissions() {
            try {
                let response = await getProjectPermissionsService({ projectName })
                const {
                    canReadUserAssignments,
                    canReadRoles,
                    canReadGroups,
                    canReadCategories,
                    canReadLocales,
                    canReadCustomTemplates,
                    canReadEmailHistories,
                    canReadWorkflowSteps,
                    canReadWorkflowMessages,
                    canReadWorkflows,
                    canReadTasks,
                    canReadTargetDates,
                    canUpdateProject
                } = response.data.projectPermissions

                const canViewManagement = canReadUserAssignments ||
                    canReadRoles ||
                    canReadGroups ||
                    canReadCategories ||
                    canReadLocales ||
                    canReadCustomTemplates ||
                    canReadEmailHistories ||
                    canReadWorkflowSteps ||
                    canReadWorkflowMessages ||
                    canReadWorkflows ||
                    canReadTasks ||
                    canReadTargetDates ||
                    canUpdateProject

                if (!canViewManagement) {
                    navigateTo(`/${projectName}/tasks`)
                }
                setIsNowLoading(false)
            } catch (e) {
                console.error(e)
                setIsError(true)
                setIsNowLoading(false)
            }
        }
        fetchPermissions()
    }, [projectName])

    return (
        <IsNowLoading isNowLoading={isNowLoading}>
            {!isError && <div className='breadcrumb-home-alignment'>
                <div className='f3 mt4'>
                    Choose a tile to get started...
                </div>
                <div className='flex flex-row' style={{ flexWrap: 'wrap' }}>
                    <Link to={`/${projectName}/workflow-management`} className='workflow-management' style={{ textDecoration: 'none' }}>
                        <IndexTile
                            name='Workflow Management'
                            logo={workflowManagementLogo}
                            description="Create steps and build workflows. Assign workflows to tasks and users to track progress across the lifespan of a multipart project."
                        />
                    </Link>
                    <Link to={`/${projectName}/tasks`} className='task-view' style={{ textDecoration: 'none' }}>
                        <IndexTile
                            name='Task View'
                            logo={taskViewLogo}
                            logoWidth='320px'
                            description="View your tasks and move them through a workflow."
                        />
                    </Link>
                </div>
            </div>}
            {isError && (
                <div className="h-[100px] flex items-center justify-center mt-5 ml-3 text-2xl">
                    Something went wrong while fetching the project, please try again or
                    <a href="/" className="text-blue-500 hover:underline ml-1">go back</a>
                </div>
            )}

        </IsNowLoading>
    )
}

export default IndexPage