function NowLoading(){
    return (
        <p className="nowLoading mb0 mt0">
            <span className="primary f2">.</span>
            <span className="primary f2">.</span>
            <span className="primary f2">.</span>
        </p>
    )
}

export default NowLoading;