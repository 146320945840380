import cancelIcon from './cancelIcon.svg'

function CancelButton(props){

    const onClick = props.onClick
    const tooltipText = props.tooltipText
    const disableBorder = props.disableBorder

    let border
    disableBorder ? border = '' : border = 'ba'
    
    return (
        <div className={`tc pt1 b--moon-gray pointer tooltip ${border} cancel-button`} style={{width: '25px', height: '30px'}} onClick={onClick}>
            <img src={cancelIcon} width='15px' height='auto' alt='cancel icon'/>
            <span className='tooltiptext f6 b'>{tooltipText}</span>
        </div>
    )
}

export default CancelButton