import { useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import UpdateButton from "../../../Shared/UpdateButton"
import SaveButton from '../../../Shared/SaveButton'
import CancelButton from '../../../Shared/CancelButton'
import { Toast } from 'primereact/toast'

import { updateWorkflowNameService } from '../../../Services/WorkflowAppServices'

function WorkflowName(props) {

    const navigateTo = useNavigate()
    const toast = useRef(null)

    const { projectName } = useParams()

    const workflowName = props.workflowName
    const workflowUuid = props.workflowUuid
    const projectPermissions = props.projectPermissions

    const [renderUpdateFunctionality, setRenderUpdateFunctionality] = useState(false)
    const [workflowNameToUpdate, setWorkflowNameToUpdate] = useState(workflowName)
    const [isNowLoading, setIsNowLoading] = useState(false)

    async function handleUpdateWorkflowNameClick() {
        setIsNowLoading(true)
        let serviceProps = { workflowNameToUpdate: workflowNameToUpdate.trim(), workflowUuid, projectName }
        try {
            await updateWorkflowNameService(serviceProps)

            setRenderUpdateFunctionality(false)
            setIsNowLoading(false)
            setTimeout(_ => {
                navigateTo(`/${projectName}/workflow-management/workflows/${workflowNameToUpdate.trim()}`)
                window.location.reload()
            }, 0)
        } catch (error) {
            console.error(error)
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.response.data, life: 5000 })
            setIsNowLoading(false)
        }
    }

    function handleWorkflowNameChange(e) {
        let workflowNameToUpdateTemp = e.target.value
        setWorkflowNameToUpdate(workflowNameToUpdateTemp)
    }

    return (
        <div>
            <Toast ref={toast} />
            {!renderUpdateFunctionality &&
                <div className='flex flex-row items-center'>
                    {projectPermissions?.canUpdateWorkflows &&
                        <div className='mr1 update-workflow-name-button'>
                            <UpdateButton
                                onClick={_ => { setRenderUpdateFunctionality(true) }}
                                tooltipText='Update workflow name' />
                        </div>}
                    <div className='b f4'>
                        Name:
                    </div>
                    <div className='ml1 f4'>
                        {workflowName}
                    </div>
                </div>}
            {renderUpdateFunctionality &&
                <div className='flex justify-between items-center bg-light-secondary' style={{ width: '298x' }}>
                    <div className='mr1 save-new-workflow-name-button'>
                        <SaveButton
                            onClick={handleUpdateWorkflowNameClick}
                            isNowLoading={isNowLoading}
                            tooltipText='Save changes' />
                    </div>
                    <div className='b f4'>
                        Name:
                    </div>
                    <div className='ml1 f4'>
                        <input
                            value={workflowNameToUpdate}
                            style={{ width: '154px' }}
                            className='bg-light-secondary workflow-name-input'
                            placeholder='workflow-1'
                            onChange={handleWorkflowNameChange}
                        />
                    </div>
                    <CancelButton
                        tooltipText='Cancel changes'
                        onClick={_ => { setRenderUpdateFunctionality(false) }} />
                </div>}
        </div>
    )
}

export default WorkflowName