import viewIcon from './viewIcon.svg'

function PreviewButton(props){

    const onClick = props.onClick
    const tooltipText = props.tooltipText

    return (
        <div 
            className='tooltip pointer b--dotted ba b--dark-gray preview-button' 
            style={{width: '25px', height: '30px'}}
            onClick={onClick}>
                <div className='flex justify-center mt1'>
                    <img src={viewIcon} width='18px' height='20px' alt='view icon'/>
                </div>
                <span className='tooltiptext b f6'>{tooltipText}</span>
        </div>
    )
}

export default PreviewButton