function SummaryBlock(props){

    const title = props.title
    const accessor = props.accessor
    const rowSummaries = props.rowSummaries

    let keys = Object.keys(rowSummaries[accessor])
   
    let list = keys.map(key => {
        return (
            <div key={key} className='bt bl br b--moon-gray pv1 ph2 flex flex-row justify-between' style={{width: '100%'}}>
                <div className='mr4' >{key}</div>
                <div className='b mr5' >{rowSummaries[accessor][key]}</div>
            </div>
        )
    })

    return (
        <div>
            <div className='b mt3'>{title}</div>
            <div className='bb b--moon-gray flex flex-row flex-wrap mt1'>{list}</div>
        </div>
    )
}

export default SummaryBlock