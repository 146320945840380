import AppService from './AppService'
const baseURL = '/api/email-histories'
const API = AppService.initAPI(baseURL)

const Services = {
    find: async function (params) {
        return await API.get(``, {params})
    }
}

export default Services