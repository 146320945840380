import { useParams } from 'react-router-dom'
import { useState } from 'react'
import Connection from './Connection'
import Modal from '../../../Shared/Modal'
import { 
    addConnectionService, 
    removeStepFromWorkflowService,
    moveStepService
} from '../../../Services/WorkflowAppServices'
import { Button } from 'primereact/button';
function WorkflowBuilderStep(props){

    const { projectName, workflowName } = useParams()

    const projectUuid = props.projectUuid
    const step = props.step
    const stepUuid = step?.stepUuid
    const index = props.index
    const roles = props.roles
    const messages = props.messages
    const workflowUuid = props.workflowUuid
    const assignedSteps = props.assignedSteps
    const stepConnections = props.stepConnections
    const projectPermissions = props.projectPermissions
    const fetchWorkflowBuilderState = props.fetchWorkflowBuilderState
    const moveStepLoading = props.moveStepLoading
    const setMoveStepLoading = props.setMoveStepLoading
    const emailTemplateOptions = props.emailTemplateOptions
    const allSteps = props.allSteps
    const [isNowLoading, setIsNowloading] = useState(false)

    const [renderCannotDeleteModal, setRenderCannotDeleteModal] = useState(false)

    async function handleRemoveStepClick(){
        setIsNowloading(true)
        let serviceProps = { stepUuid, projectName, workflowName, workflowUuid }

        let response = await removeStepFromWorkflowService(serviceProps)

        if (response.status === 200){
            if (response.data.canDelete){
                fetchWorkflowBuilderState()
                setTimeout(_ => {
                    setIsNowloading(false)
                }, 500)
            } else {
                setRenderCannotDeleteModal(true)
                setIsNowloading(false)
            }
        }
    }


    async function handleAddConnectionClick(){
        let serviceProps = { workflowUuid, stepUuid, projectName }

        let response = await addConnectionService(serviceProps)

        if (response.status === 201){
            fetchWorkflowBuilderState()
        }
    }

    async function handleMoveStep(direction){
        setMoveStepLoading(true)
        let serviceProps = { workflowUuid, stepUuid, projectUuid, direction }
        try {
            await moveStepService(serviceProps)
            await fetchWorkflowBuilderState()
            setMoveStepLoading(false)
        } catch(e) {
            console.error(e)
            setMoveStepLoading(false)
        }
    }

    let connectionsList = stepConnections.map(connection => {
        return (
            <Connection 
                key={connection.connectionUuid}
                index={index} 
                roles={roles}
                messages={messages}
                connection={connection} 
                projectUuid={projectUuid}
                workflowUuid={workflowUuid}
                assignedSteps={assignedSteps}
                projectPermissions={projectPermissions}
                emailTemplateOptions={emailTemplateOptions}
                allSteps={allSteps} 
                fetchWorkflowBuilderState={fetchWorkflowBuilderState}/>
        )
    }) 

    return (
        <>
        {renderCannotDeleteModal && 
            <Modal 
                modalTitle='Cannot remove step'
                onClickClose={ _ => { setRenderCannotDeleteModal(false) }}>
                    <p>This workflow step <strong>{step.stepName}</strong> is the current step in at least one task.</p>
                    <p>Please ensure no tasks have this step as the current step and then try again.</p>
            </Modal>}
            <div className='ml2 mr2 mt2 mb3 ba b--primary br2 shadow-1' style={{width: '330px', minHeight: '120px'}}>
                {!isNowLoading &&
                    <div>
                        <div className='flex justify-between'>
                            {projectPermissions?.canUpdateWorkflows &&
                                <div 
                                    className='ml1 b dark-green mt1 pointer add-connection-button tooltip' 
                                    onClick={handleAddConnectionClick}>
                                        <span>+</span>
                                        <span className='tooltiptext f6'>Add connection</span>
                                </div>}
                            <div className='ma1 b f6 mt2 flex flex-row'>
                                <span>{index}.&nbsp;</span>
                                <span>{step?.stepName}</span>
                            </div>
                            {projectPermissions?.canUpdateWorkflows &&
                                <div 
                                    className='b dark-red mr2 mt1 pointer remove-workflow-step-button tooltip' 
                                    onClick={handleRemoveStepClick}>
                                        <span>&times;</span>
                                        <span className='tooltiptext f6'>Remove step</span>
                                </div>}
                        </div>
                        <div className='flex items-center justify-center'>
                            <div className='f7 flex flex-row'>
                                <span>Queue enabled: {step?.queueEnabled ? 'Yes' : 'No'}</span>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            {projectPermissions?.canUpdateWorkflows &&
                                <div className='ml1 b dark-green mt1 pointer tooltip'>
                                        {index !== 1 && 
                                        <>
                                            <Button icon="pi pi-angle-left" text severity="info" loading={moveStepLoading} onClick={() => handleMoveStep('backward')}/>
                                            <span className='tooltiptext f6'>Move step backward</span>
                                        </>}
                                </div>}
                     
                            {projectPermissions?.canUpdateWorkflows &&
                                <div className='b dark-red mr2 mt1 pointer tooltip'>
                                        {index !== assignedSteps.length && 
                                        <>
                                            <Button icon="pi pi-angle-right" text severity="info" loading={moveStepLoading} onClick={() => handleMoveStep('forward')}/>
                                            <span className='tooltiptext f6'>Move step forward</span>
                                        </>}
                                </div>}
                        </div>
                        {connectionsList}
                    </div>}
                {isNowLoading && 
                    <div className='flex justify-center items-center' style={{width: '230px', minHeight: '110px'}}>
                        <div className='nowLoading'>
                            <span className='f3 primary'>.</span>
                            <span className='f3 primary'>.</span>
                            <span className='f3 primary'>.</span>
                        </div>
                    </div>}
            </div>
        </>
    )
}

export default WorkflowBuilderStep