import React, { useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import { Calendar } from 'primereact/calendar';
import SummaryModal from './SummaryModal'
import { InputText } from 'primereact/inputtext'
import Papa from 'papaparse';
import { validateFieldsStartChar } from '../Shared/validators';
import { getTasksService } from '../Services/TaskViewServices'
import { MultiSelect } from 'primereact/multiselect'
export default function ItemTable(props) {
    const {
        data,
        groups,
        loading,
        locales,
        workflows,
        workflowSteps,
        tableParams,
        setTableParams,
        total,
        selectedProject,
        assignedRoleUuids
    } = props

    const [filteredItems, setFilteredItems] = useState(data.filter((task) => task.actionRequired))
    const [renderSummaryModal, setRenderSummaryModal] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const toast = useRef(null)
    const dt = useRef(null)

    const exportCSV = async (event) => {
        setExportLoading(true)
        try {
            const response = await getTasksService({ ...tableParams, projectUuid: selectedProject.projectUuid, isExport: true })
            event.preventDefault();
            event.stopPropagation();
            const link = document.createElement('a');
            let exportData = response?.data?.tasks.map((task) => {
                const lastStepHistoryAt = task.stepHistory.slice(-1)[0]?.updatedBy?.updatedAt;
                const workflow = workflows.find(workflow => workflow.workflowUuid === task.workflowUuid)
                let assignedSteps = workflow.assignedSteps
                let statusDenominator = assignedSteps.length
                let statusNumerator = assignedSteps.indexOf(task.onStepUuid) + 1
                const workflowStep = workflowSteps.find(workflowStep => workflowStep.stepUuid === task.onStepUuid)
                let queued = false
                if (workflowStep.stepQueues) {
                    let queueObject = workflowStep.stepQueues.find(stepQueue => stepQueue.localeUuid === task.localeUuid && stepQueue.groupUuid === task.groupUuid)
                    if (queueObject) {
                        queued = queueObject.taskQueue.includes(task.taskUuid)
                    }
                }
                let connections = workflow.connections.filter(connection => connection.fromStep === task.onStepUuid)
                let connectionsToActOnThisStep = connections.filter(connection => {
                    return connection.fromStep === task.onStepUuid &&
                        connection.roleAccess.some(roleUuid => assignedRoleUuids.includes(roleUuid));
                });
                const actionRequired = connectionsToActOnThisStep.length > 0
                let daysRemaining = ''
                if (task.taskDueDate && task.estCompletionDate) {
                    daysRemaining = Math.round((new Date(task.taskDueDate) - new Date(task.estCompletionDate)) / (1000 * 60 * 60 * 24));
                }
                const estDaysBehind = daysRemaining < 0 ? daysRemaining : ''
                return {
                    'Task': task.taskName,
                    'Group': groups.find(group => group.groupUuid === task.groupUuid)?.groupName,
                    'Locale': locales.find(locale => locale.localeUuid === task.localeUuid)?.localeName,
                    'Workflow': workflow?.workflowName,
                    'Step name': workflowStep?.stepName,
                    'Step': `${statusNumerator} of ${statusDenominator}`,
                    'Step due': task.onStepTargetDate ? new Date(task.onStepTargetDate).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }) : '',
                    'Task due': task.taskDueDate ? new Date(task.taskDueDate).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }) : '',
                    'Task Est. Completion': task.estCompletionDate ? new Date(task.estCompletionDate).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }) : '',
                    'Task Est. days behind': estDaysBehind,
                    'Last transition': lastStepHistoryAt ? new Date(lastStepHistoryAt).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }) : '',
                    'Queued': queued ? 'Yes' : 'No',
                    'Action': actionRequired ? 'Yes' : 'No',
                    'Complete': statusNumerator === statusDenominator ? 'Yes' : 'No'
                }
            });
            exportData = exportData.filter((item) => {
                // Filter out items which fields value starts with =, +, - or @ before exporting to csv file to avoid malicious injection
                const result = validateFieldsStartChar(item)
                return result.isValid;
            })
            const csvData = Papa.unparse(exportData);
            link.href = URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
            link.download = 'tasks.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setExportLoading(false)
        } catch (error) {
            console.error(error)
            setExportLoading(false)
            toast.current.show({ severity: 'error', summary: 'Export tasks failed', detail: error.response?.message ?? 'Something went wrong while exporting tasks.', life: 5000 })
        }
    }

    const sortByField = (array, field) => {
        return array.slice().sort((a, b) => {
            if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
            if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
            return 0;
        });
    }

    const rightToolbarTemplate = () => {
        return (<div className="flex items-center gap-2">
            {/* <Button label="Summary" icon="pi pi-info-circle" className="p-button-success" onClick={_ => setRenderSummaryModal(true)} /> */}
            <Button label="Export" icon="pi pi-upload" loading={exportLoading} className="p-button-info" onClick={exportCSV} />
        </div>)
    }

    const booleanOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
    ]

    const booleanRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={booleanOptions} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="label" optionValue="value" placeholder="Select" className="p-column-filter" filter style={{ maxWidth: '7rem' }} />
        )
    }

    const booleanBodyTemplate = (rowData, options) => {
        return rowData[options.field] ? 'Yes' : 'No'
    }

    const taskNameBodyTemplate = (rowData) => {
        return <a href={`/${rowData.projectName}/tasks/${rowData.taskUuid}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            {rowData.taskName}
        </a>
    }

    const onStepBodyTemplate = (rowData) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{rowData.onStepNumber} / {rowData.totalStepNumber}</div>
    }

    const stepDueBodyTemplate = (rowData) => {
        if (rowData.onStepTargetDate) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const localDate = new Date(rowData.onStepTargetDate).toLocaleString(undefined, options);
            const targetDate = new Date(rowData.onStepTargetDate);
            const today = new Date();

            today.setHours(0, 0, 0, 0);
            targetDate.setHours(0, 0, 0, 0);

            const isOverdued = targetDate < today;
            return isOverdued ? <div className='text-red-500 font-bold'>{localDate}</div> : <div>{localDate}</div>
        } else {
            return <></>
        }
    }

    const taskDueBodyTemplate = (rowData) => {
        let localDate = '';
        if (rowData.taskDueDate) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            localDate = new Date(rowData.taskDueDate).toLocaleString(undefined, options);
        }
        return <span>{localDate}</span>;
    }

    const estCompletionDateTemplate = (rowData) => {
        if (rowData.estCompletionDate) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const localDate = new Date(rowData.estCompletionDate).toLocaleString(undefined, options);
            let daysRemaining
            if (rowData.taskDueDate && rowData.estCompletionDate) {
                daysRemaining = Math.round((new Date(rowData.taskDueDate) - new Date(rowData.estCompletionDate)) / (1000 * 60 * 60 * 24));
            }
            return daysRemaining < 0 ? <div className='text-red-500 font-bold'>{localDate}</div> : <div>{localDate}</div>
        } else {
            return <></>
        }
    }

    const daysRemainingTemplate = (rowData) => {
        let daysRemaining
        if (rowData.taskDueDate && rowData.estCompletionDate) {
            daysRemaining = Math.round((new Date(rowData.taskDueDate) - new Date(rowData.estCompletionDate)) / (1000 * 60 * 60 * 24));
        }
        return daysRemaining < 0 ? <div className='w-full h-full text-red-500 font-bold'>{daysRemaining}</div> : <></>
    }

    const exportFunction = ({ data, field, rowData, column }) => {
        if (['queued', 'actionRequired'].includes(field)) {
            return data ? 'Yes' : 'No'
        }
        return data
    }

    const lastStepHistoryAtBodyTemplate = (rowData) => {
        let result = ''
        if (rowData.lastStepHistoryAt) {
            result = new Date(rowData.lastStepHistoryAt).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })
        }
        return result
    }

    const onFilter = (event) => {
        event['first'] = 0;
        event['page'] = 0
        setTableParams(prevState => ({
            ...prevState,
            ...event,
        }))
    }
    const onPage = (event) => {
        setTableParams(prevState => ({
            ...prevState,
            ...event,
        }))
    }

    const onSort = (event) => {
        setTableParams(prevState => ({
            ...prevState,
            ...event,
        }))
    }

    const taskNameRowFilterTemplate = (options) => {
        return (
            <InputText value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value)} />
        );
    };

    const taskDueDateFilterTemplate = (options) => {
        return (
            <Calendar value={options.value} selectionMode="range" onChange={(e) => options.filterApplyCallback(e.target.value)} showIcon />
        );
    }

    const groupsRowFilterTemplate = (options) => {
        return (
            <MultiSelect value={options.value} filter onChange={(e) => options.filterApplyCallback(e.value)} options={sortByField(groups, 'groupName')} optionLabel="groupName"
                showSelectAll={false} selectionLimit={15} optionValue="groupUuid" placeholder="Select groups" />
        );
    };

    const localesRowFilterTemplate = (options) => {
        return (
            <MultiSelect value={options.value} filter onChange={(e) => options.filterApplyCallback(e.value)} options={sortByField(locales, 'localeName')} optionLabel="localeName"
                showSelectAll={false} selectionLimit={15} optionValue="localeUuid" placeholder="Select locales" />
        );
    };

    const workflowsRowFilterTemplate = (options) => {
        return (
            <MultiSelect value={options.value} filter onChange={(e) => options.filterApplyCallback(e.value)} options={sortByField(workflows, 'workflowName')} optionLabel="workflowName"
                showSelectAll={false} selectionLimit={15} optionValue="workflowUuid" placeholder="Select workflows" />
        );
    };

    const stepsRowFilterTemplate = (options) => {
        return (
            <MultiSelect value={options.value} filter onChange={(e) => options.filterApplyCallback(e.value)} options={sortByField(workflowSteps, 'stepName')} optionLabel="stepName"
                showSelectAll={false} selectionLimit={15} optionValue="stepUuid" placeholder="Select steps" />
        );
    };

    const hideFilterApplyTemplate = () => {
        return (<></>);
    };

    return (
        <>
            {data && renderSummaryModal &&
                <SummaryModal rows={filteredItems} onClickClose={_ => setRenderSummaryModal(false)} />
            }
            <div className="card" style={{ height: "calc(100vh - 165px" }}>
                <Toast ref={toast} />
                <Toolbar className="mb-1" style={{ padding: '0.3rem 1rem' }} end={rightToolbarTemplate}></Toolbar>
                <DataTable
                    ref={dt}
                    value={data}
                    size={'small'}
                    tableStyle={{}}
                    loading={loading}
                    filters={tableParams.filters}
                    scrollable
                    scrollHeight="flex"
                    onValueChange={filteredData => setFilteredItems(filteredData)}
                    sortOrder={tableParams.sortOrder}
                    exportFunction={exportFunction}
                    emptyMessage="No tasks found."
                    lazy
                    onFilter={onFilter}
                    onPage={onPage}
                    onSort={onSort}
                    first={tableParams.first}
                    paginator
                    rows={tableParams.rows}
                    sortField={tableParams.sortField}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    totalRecords={total}
                    removableSort
                >
                    <Column field="taskName" body={taskNameBodyTemplate} filter sortable header="Task" showFilterMatchModes={false} style={{ minWidth: '10rem' }} filterApply={hideFilterApplyTemplate} filterElement={taskNameRowFilterTemplate}></Column>
                    <Column field="groupName" filter filterField='groupUuid' showFilterMatchModes={false} header="Group" filterApply={hideFilterApplyTemplate} filterElement={groupsRowFilterTemplate}></Column>
                    <Column field="localeName" filter filterField='localeUuid' showFilterMatchModes={false} header="Locale" filterApply={hideFilterApplyTemplate} filterElement={localesRowFilterTemplate}></Column>
                    <Column field="workflowName" filter filterField='workflowUuid' showFilterMatchModes={false} header="Workflow" filterApply={hideFilterApplyTemplate} filterElement={workflowsRowFilterTemplate}></Column>
                    <Column field="stepName" filter filterField='onStepUuid' showFilterMatchModes={false} header="Step name" filterApply={hideFilterApplyTemplate} filterElement={stepsRowFilterTemplate}></Column>
                    <Column body={onStepBodyTemplate} header="Step" showFilterMenu={false} ></Column>
                    <Column field="onStepTargetDate" header="Step Due" showFilterMenu={false} body={stepDueBodyTemplate}></Column>
                    <Column field="taskDueDate" filter header="Task Due" sortable showFilterMatchModes={false} filterApply={hideFilterApplyTemplate} body={taskDueBodyTemplate} filterElement={taskDueDateFilterTemplate}></Column>
                    <Column field="estCompletionDate" header="Task Est. Completion" showFilterMenu={false} body={estCompletionDateTemplate}></Column>
                    <Column header="Task Est. days behind" showFilterMenu={false} body={daysRemainingTemplate}></Column>
                    <Column field="lastStepHistoryAt" showFilterMenu={false} showFilterMatchModes={false} header="Last Transition" body={lastStepHistoryAtBodyTemplate} dataType="date"></Column>
                    <Column field="queued" filter header="Queued" style={{ minWidth: '6rem' }} showFilterMatchModes={false} body={booleanBodyTemplate} filterApply={hideFilterApplyTemplate} filterElement={booleanRowFilterTemplate} />
                    <Column field="actionRequired" filter header="Action" style={{ minWidth: '6rem' }} showFilterMatchModes={false} body={booleanBodyTemplate} filterApply={hideFilterApplyTemplate} filterElement={booleanRowFilterTemplate} />
                    <Column field="isComplete" filter header="Complete" style={{ minWidth: '6rem' }} showFilterMatchModes={false} body={booleanBodyTemplate} filterApply={hideFilterApplyTemplate} filterElement={booleanRowFilterTemplate} />
                    <Column field="transition" header="Transition" exportable={false} style={{ minWidth: '9rem' }}></Column>
                    <Column field="showDetails" header="Details" exportable={false} style={{ minWidth: '5rem' }}></Column>
                </DataTable>
            </div>
        </>

    )
}