const columns = [
    {
    Header: " ",
    columns: [
        {
            Header: 'Task',
            pluralHeader: 'Tasks',
            accessor: 'taskName',
        },
        {
            Header: 'Group',
            pluralHeader: 'Groups',
            accessor: 'groupName',
        },        
        {
            Header: 'Workflow',
            pluralHeader: 'Workflows',
            accessor: 'workflowName',
        },
        {
            Header: 'Category',
            pluralHeader: 'Categories',
            accessor: 'categoryName',
        },
        {
            Header: 'Locale',
            pluralHeader: 'Locales',
            accessor: 'localeName',
        },
        {
            Header: 'Step name',
            accessor: 'stepName',
        },
        {
            Header: 'Step',
            pluralHeader: 'onStep',
            accessor: 'onStep',
        },
        {
            id: 'isCurrentStepComplete',
            Header: 'Status',
            accessor: d => d.isCurrentStepComplete? "Queued" : "0%",
        },
        {
            id:'actionRequired',
            Header: 'Action required',
            accessor: 'actionRequired',
        },
        {
            Header: 'Transition',
            accessor: 'transition',
        },
        {
            Header: 'Details',
            accessor: 'showDetails',
        }
    ],
    }
]

export default columns