function AccountMenu(props){

    const signOut = props.signOut
    const user = props.user
    const initials = props.initials

    return (
        <div className='ba flex flex-column shadow-4 bg-white' style={{position: 'fixed', right: '0px', top: '50px', zIndex: '100', height: '132px'}}>
            <div className='flex justify-between'>
                <div className='ba flex justify-center items-center f3 ma3' style={{width: '100px', height: '100px', borderRadius: '50%'}}>
                    {initials}
                </div>
                <div className='flex flex-column ml1 mt3 mr3'>
                    <div className='f5 mt1'>
                        {`${user.firstName} ${user.lastName}`}
                    </div>
                    <div className='f6 mt1'>
                        {user.emailAddress}
                    </div>
                    <div className='f6 mt2 white'>
                        -
                    </div>
                    <div className='f6 mt2 dim pointer sign-out' onClick={signOut}>
                        Sign out
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountMenu