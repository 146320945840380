import { useQuery } from '@tanstack/react-query'
import Services from '../Services/WorkflowStepService'

export const useWorkflowSteps = (projectUuid) => {
    const getWorkflowStepsQuery = useQuery({
        queryKey: ['workflowSteps', projectUuid],
        queryFn: async () => {
            const response = await Services.find({ projectUuid })
            return response.data
        },
        staleTime: 1000,
        refetchOnWindowFocus: false,
    })
    return {
        workflowSteps: getWorkflowStepsQuery.data,
    }
}