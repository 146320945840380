import { useState, useEffect, useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom'
import IsNowLoading from "../../Shared/IsNowLoading"
import Step from "./Step"
import PageSubHeading from '../../Shared/PageSubHeading'
import NameValuePair from './NameValuePair'
import FileInstance from './FileInstance'
import { getTaskService } from '../../Services/TaskViewServices'
import { getProjectPermissionsService } from '../../Services/WorkflowAppServices'
import { getAssignedRoles } from '../../Services/AuthenticationServices'
import MessageService from '../../Services/MessageService'
import RoleService from '../../Services/RoleService'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'

function TaskInstance() {

    const { taskUuid } = useParams()

    const [isNowLoading, setIsNowLoading] = useState(true)
    const [stepsList, setStepsList] = useState([])
    const [stepHistoryTableData, setStepHistoryTableData] = useState([])
    const [group, setGroup] = useState('')
    const [task, setTask] = useState('')
    const [statusNumerator, setStatusNumerator] = useState('')
    const [statusDenominator, setStatusDenominator] = useState('')
    const [currentStepName, setCurrentStepName] = useState('')
    const [fileInstances, setFileInstances] = useState([])
    const [fileLatestInstances, setFileLatestInstances] = useState([])
    const [error, setError] = useState(false)
    const [tabDisplayed, setTabDisplayed] = useState('step')
    const [showArchivedTable, setShowArchivedTable] = useState(false)
    const [permissions, setPermissions] = useState([])
    const [workflowSteps, setWorkflowsSteps] = useState([])
    const dt = useRef(null)

    const [filters] = useState({
        comment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        updatedAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
        updatedBy: { value: null, matchMode: FilterMatchMode.CONTAINS },
    })

    async function handleResponse(response, projectPermissions) {
        let taskTemp = response.data
        setGroup(taskTemp.group)
        let workflow = taskTemp.workflow
        let locale = taskTemp.locale
        let project = taskTemp.project
        taskTemp.localeName = locale?.localeName
        taskTemp.projectName = project.projectName
        let taskPermissions = taskTemp.taskPermissions
        let workflowSteps = taskTemp.workflow.workflowSteps
        let messages = taskTemp.messages
        let roles = taskTemp.roles
        let stepHistory = taskTemp.stepHistory
        let assignedSteps = workflow.assignedSteps

        let isTaskQueuedInCurrentStep = false
        const taskOnStep = workflowSteps.find(workflowStep => workflowStep.stepUuid === taskTemp.onStepUuid)
        if (taskOnStep.stepQueues) {
            let queueObject = taskOnStep.stepQueues.find(stepQueue => stepQueue.localeUuid === taskTemp.localeUuid && stepQueue.groupUuid === taskTemp.groupUuid)
            if (queueObject) {
                isTaskQueuedInCurrentStep = queueObject.taskQueue.includes(taskTemp.taskUuid)
            }
        }

        setStatusNumerator(assignedSteps.indexOf(taskTemp.onStepUuid) + 1)
        setStatusDenominator(assignedSteps.length)

        let allConnections = workflow.connections

        let fileInstancesTemp = []
        let fileLatestInstancesTemp = []

        // get the latest stepHistory which has file(s) uploaded
        const indexOflastestStepHistoryWithFiles = stepHistory
            .map(stepHistoryItem => stepHistoryItem?.files && stepHistoryItem?.files?.length > 0)
            .lastIndexOf(true);
        const lastestStepHistory = stepHistory[indexOflastestStepHistoryWithFiles]
        const latestFiles = lastestStepHistory?.files || []

        latestFiles.forEach((file) => {
            fileLatestInstancesTemp.push(
                <FileInstance
                    file={file}
                    fetchTask={fetchTask}
                    step={lastestStepHistory}
                    setError={setError}
                    taskUuid={taskUuid}
                    workflowSteps={workflowSteps}
                    taskPermissions={taskPermissions}
                    key={file.originalname}
                    projectPermissions={projectPermissions}
                    projectName={project.projectName}
                />)
        })

        setFileLatestInstances(fileLatestInstancesTemp)

        // get the stepHistories until the latest stepHistory which has file(s) uploaded
        const archivedStepHistories = stepHistory.slice(0, indexOflastestStepHistoryWithFiles)
        archivedStepHistories.forEach(step => {
            let files = step.files
            files.forEach((file) => {
                fileInstancesTemp.push(
                    <FileInstance
                        file={file}
                        fetchTask={fetchTask}
                        step={step}
                        setError={setError}
                        taskUuid={taskUuid}
                        workflowSteps={workflowSteps}
                        taskPermissions={taskPermissions}
                        projectPermissions={projectPermissions}
                        key={file.originalname}
                    />)
            })
        })

        setFileInstances(fileInstancesTemp)

        setTask(taskTemp)
        setIsNowLoading(false)

        setStepsList([])
        let steps = []

        assignedSteps.forEach((stepUuid, stepIndex) => {
            const step = workflowSteps.find(workflowStep => workflowStep.stepUuid === stepUuid)

            const currentStepIndex = assignedSteps.indexOf(taskTemp.onStepUuid)
            const thisStepIndex = assignedSteps.indexOf(stepUuid)

            const connections = allConnections.filter(connection => connection.fromStep === stepUuid)

            let isOnThisStep = currentStepIndex === thisStepIndex
            let isFutureStep = currentStepIndex < thisStepIndex
            if (isOnThisStep) setCurrentStepName(step.stepName)

            steps.push(
                <Step
                    step={step}
                    task={taskTemp}
                    taskUuid={taskUuid}
                    messages={messages}
                    fetchTask={fetchTask}
                    error={error}
                    setError={setError}
                    connections={connections}
                    stepIndex={stepIndex + 1}
                    isOnThisStep={isOnThisStep}
                    isFutureStep={isFutureStep}
                    assignedSteps={assignedSteps}
                    workflowSteps={workflowSteps}
                    taskPermissions={taskPermissions}
                    key={stepIndex}
                    isTaskQueuedInCurrentStep={isTaskQueuedInCurrentStep}
                    projectPermissions={projectPermissions}
                    roles={roles}
                />)
            setStepsList(steps)
        })
        const _stepHistoryTableData = stepHistory.map(history => ({
            fromStepUuid: history.fromStepUuid,
            toStepUuid: history.toStepUuid,
            updatedBy: history.updatedBy ? `${history.updatedBy.givenName} ${history.updatedBy.familyName}` : '',
            updatedAt: new Date(history.updatedBy?.updatedAt),
            comment: history.updatedBy?.comment
        }));
        setStepHistoryTableData(_stepHistoryTableData)
        setWorkflowsSteps(workflowSteps)
    }
    const fetchTask = useCallback(async _ => {
        setIsNowLoading(true)
        try {
            setError(false)
            const [response, assignedRolesResponse] = await Promise.all([
                getTaskService({ taskUuid: taskUuid }),
                getAssignedRoles(),
            ]);

            response.data.taskPermissions = { assignedRoleUuids: assignedRolesResponse.data.map(assignedRole => assignedRole.roleUuid) }
            const [messageResponse, roleResponse, permissionResponse] = await Promise.all([
                MessageService.find({ projectUuid: response.data.projectUuid }),
                RoleService.find({ projectUuid: response.data.projectUuid }),
                getProjectPermissionsService({ projectName: response.data.project.projectName })
            ]);

            response.data.messages = messageResponse.data;
            response.data.roles = roleResponse.data;
            setPermissions(permissionResponse.data.projectPermissions)
            await handleResponse(response, permissionResponse.data.projectPermissions)
        } catch (err) {
            console.error(err)
            if (err.response) {
                setError(err.response.data)
            } else {
                setError({
                    modalTitle: 'Something went wrong',
                    messageHTML: `<p>Something went wrong</p>`
                })
            }

            setIsNowLoading('blank')
        }
    }, [taskUuid])

    useEffect(_ => {
        fetchTask()
    }, [fetchTask])

    const transitionBodyTemplate = (rowData) => {
        const fromStep = workflowSteps.find(workflowStep => workflowStep.stepUuid === rowData.fromStepUuid)
        const toStep = workflowSteps.find(workflowStep => workflowStep.stepUuid === rowData.toStepUuid)
        return (
            <>
                {`${fromStep?.stepName} \u279c ${toStep?.stepName}`}
            </>
        );
    };

    const updatedAtTemplate = (rowData) => {
        return rowData.updatedAt.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })
    }
    return (
        <>
            {/* TODO pull out component for each summary line */}
            {/* TODO fix pending when only one pending left */}
            <IsNowLoading isNowLoading={isNowLoading} error={error} setError={setError}>
                <div className='mt4'></div>
                <PageSubHeading subHeading={`${task.taskName}`}>
                    <NameValuePair name='Group' value={group.groupName} />
                    <NameValuePair name='Step' value={
                        <div className='flex flex-row'>
                            <div className='mr2 b'>{currentStepName}</div>
                            <div className='dark-gray'>({statusNumerator} of {statusDenominator})</div>
                        </div>} />
                    {task.localeName && <NameValuePair name='Locale' value={task.localeName} />}
                </PageSubHeading>
                <div className='breadcrumb-home-alignment'>
                    <div className='flex flex-row'>
                        <button className={`f4 ba bb-0 br2  br--top bg-transparent ma1 ${tabDisplayed === 'step' ? 'bg-secondary' : ''
                            }`}
                            onClick={() => { setTabDisplayed('step') }}
                        >Steps</button>
                        {(fileInstances.length !== 0 || fileLatestInstances.length !== 0) &&
                            <button className={`f4 ba bb-0 br2  br--top bg-transparent ma1 ${tabDisplayed === 'files' ? 'bg-secondary' : ''
                                }`}
                                onClick={() => { setTabDisplayed('files') }}
                            >Files</button>
                        }
                        <button className={`f4 ba bb-0 br2 br--top bg-transparent ma1 ${tabDisplayed === 'stepHistory' ? 'bg-secondary' : ''
                            }`}
                            onClick={() => { setTabDisplayed('stepHistory') }}
                        >History</button>
                    </div>
                    <div className='bg-secondary br2' style={{ width: '950px', height: '4px', marginTop: '-4px', marginLeft: '3px' }}></div>
                    {tabDisplayed === 'step' &&
                        <div className='mb4' style={{ width: '950px' }}>
                            {stepsList}
                        </div>
                    }
                    {tabDisplayed === 'files' &&
                        <div className='mt3'>
                            {fileLatestInstances.length > 0 &&
                                <div className='viewtask-tab-table mb4 ml1' style={{ width: '950px' }}>
                                    <div className='mb1'>Latest files:</div>
                                    <table>
                                        <thead>
                                            <tr style={{ textAlign: 'left' }}>
                                                <th style={{ width: '300px' }}>File name</th>
                                                <th style={{ width: '350px' }}>Uploaded on connection</th>
                                                <th style={{ width: '150px' }}>Date uploaded</th>
                                                <th style={{ width: '150px' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fileLatestInstances}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {fileInstances.length > 0 &&
                                <div>
                                    <div className='mb1 ml1'>
                                        Archived files: <button onClick={() => setShowArchivedTable(!showArchivedTable)}>{showArchivedTable ? 'hide' : 'show'}</button>
                                    </div>
                                    {showArchivedTable &&
                                        <div className='viewtask-tab-table mb4 ml1' style={{ width: '950px' }}>
                                            <table>
                                                <thead>
                                                    <tr style={{ textAlign: 'left' }}>
                                                        <th style={{ width: '300px' }}>File name</th>
                                                        <th style={{ width: '350px' }}>Uploaded on connection</th>
                                                        <th style={{ width: '150px' }}>Date uploaded</th>
                                                        <th style={{ width: '150px' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fileInstances}
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                    {tabDisplayed === 'stepHistory' &&
                        <div className='viewtask-tab-table mb4 mt3 ml1' style={{ width: '950px' }}>
                            <DataTable ref={dt} value={stepHistoryTableData} size={'small'}
                                filters={filters} filterDisplay="row" scrollable scrollHeight="flex"
                                sortField="updatedAt" sortOrder="1"
                                emptyMessage="No step history found.">
                                <Column body={transitionBodyTemplate} showFilterMenu={false} header="Transition" style={{ minWidth: '15rem' }}></Column>
                                {permissions?.canViewVerifiersName && <Column field="updatedBy" filter header="Updated by" showFilterMenu={false} ></Column>}
                                <Column field="updatedAt" body={updatedAtTemplate} filter sortable showFilterMenu={false} header="Updated at" style={{ minWidth: '10rem' }}></Column>
                                <Column field="comment" filter showFilterMenu={false} header="Comment"></Column>
                            </DataTable>
                        </div>
                    }
                </div>
            </IsNowLoading>
        </>
    )
}

export default TaskInstance