import { useParams } from 'react-router-dom'
import { useState } from 'react'
import striptags from 'striptags'

import { assignStepToWorkflowService } from '../../../Services/WorkflowAppServices'

function StepLibraryStep(props){
    const { projectName, workflowName } = useParams()

    const step = props.step
    const projectPermissions = props.projectPermissions
    const fetchWorkflowBuilderState = props.fetchWorkflowBuilderState

    const [isAssignedStepLoading, setIsAssignedStepLoading] = useState(false)

    async function handleAssignStepClick(){
        setIsAssignedStepLoading(true)
        let serviceProps = { stepUuid: step.stepUuid, projectName, workflowName }

        let response = await assignStepToWorkflowService(serviceProps)

        if (response.status === 201){
            fetchWorkflowBuilderState()
            setTimeout(_ => {
                setIsAssignedStepLoading(false)
            }, 500)
        }
    }


    return (
        <div className='ba b--secondary br2 mt2 mb2 ml2 shadow-3' style={{width: '220px', minHeight: '52px'}}>
            {!isAssignedStepLoading &&
                <div>
                    <div className='flex justify-between'>
                        <div className='ma1 b f6'>
                            {step.stepName}
                        </div>
                        {projectPermissions?.canUpdateWorkflows &&
                            <div className='mr2 f4 b pointer primary tooltip add-step-button' onClick={handleAssignStepClick}>
                                +<span className='tooltiptext f6'>Add step to workflow</span>
                            </div>}
                    </div>
                    {step?.stepDescription &&
                        <div className='mt2 ml1 f6'>
                            {striptags(step?.stepDescription)?.slice(0,20)}<span>...</span>
                        </div>
                    }
                </div>}
            {isAssignedStepLoading &&
                <div className='flex justify-center align-center' style={{width: '220px', height: '52px'}}>
                    <div className='nowLoading mt2'>
                        <span className='primary f4'>.</span>
                        <span className='primary f4'>.</span>
                        <span className='primary f4'>.</span>
                    </div>
                </div>}
        </div>
    )
}

export default StepLibraryStep