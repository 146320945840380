function MiniCheckboxAndLabel(props){

    const name = props.name
    const label = props.label
    const connectionToUpdate = props.connectionToUpdate
    const setConnectionToUpdate = props.setConnectionToUpdate

    function handleCheckboxClick(){
        let connectionToUpdateTemp = JSON.parse(JSON.stringify(connectionToUpdate))
        connectionToUpdateTemp[name] = !connectionToUpdateTemp[name]
        if (connectionToUpdateTemp[name] === false) {
            connectionToUpdateTemp.emailTemplateUuid = 'default'
            connectionToUpdateTemp.emailTemplateName = 'default'
        }
        setConnectionToUpdate(connectionToUpdateTemp)
    }

    return (
        <div className='flex flex-row'>
            <input 
                type='checkbox' 
                className='mr1 pointer mini-check-box'
                name={name}
                onClick={handleCheckboxClick}
                checked={connectionToUpdate[name]}/>
            <label className='f6'>{label}</label>
        </div>
    )
}

export default MiniCheckboxAndLabel