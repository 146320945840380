function ProjectPageHeading(props){

    const pageTitle = props.pageTitle
    const margin = props.margin

    let marginLeft
    let marginRight
    let marginTop = '32px'

    if (margin){
        marginLeft = margin
        marginRight = margin
    } else {
        marginLeft = '171px'
        marginRight = '171px'
    }

    if (typeof props.marginTop !== 'undefined') marginTop = props.marginTop

    return (
        <div className="project-page-heading" style={{marginLeft, marginRight}}>
            { pageTitle &&
                <div className='f3 mr5' style={{width: '400px', marginTop}}>
                    {pageTitle}
                </div>
            }
            {props.children}
        </div>
    )
}

export default ProjectPageHeading