import { useNavigate, useLocation } from 'react-router-dom'
import ArrowWrapper from "./ArrowWrapper";
import CrumbLink from "./CrumbLink";

function Crumb(props){

    const location = useLocation()
    const navigateTo = useNavigate()

    const crumb = props.crumb
    const crumbIndex = props.crumbIndex
    
    let breadCrumbArray = [location.pathname.split('/')][0]
    breadCrumbArray[0] = 'Home'

    function handleCrumbClick(){
        let locationPathnameSplit = location.pathname.split('/')
        let newLocation = locationPathnameSplit.slice(0, crumbIndex + 1).join('/')
        navigateTo(newLocation, { state: location.state })
    }

    return (
        <>
            {crumbIndex !== 0 &&
                <ArrowWrapper>
                    <CrumbLink onClick={handleCrumbClick} crumb={crumb} />
                </ArrowWrapper>}
            {crumbIndex === 0 &&
                <CrumbLink onClick={handleCrumbClick} crumb={crumb} />}
        </>
    )
}

export default Crumb