import { useState } from 'react'

function ToBox(props){

    const assignedSteps = props.assignedSteps
    const connectionToUpdate = props.connectionToUpdate
    const setConnectionToUpdate = props.setConnectionToUpdate
    
    const numberOfWorkflowSteps = assignedSteps.length
    let toStepIndex = assignedSteps.indexOf(connectionToUpdate.toStep)
    if (toStepIndex === -1) {
        toStepIndex = 0
    }
    const toIndexInit = toStepIndex + 1

    const [toIndexToUpdate, setToIndexToUpdate] = useState(toIndexInit)
    
    function handleChangeInToIndex(e){
        let toIndex = Number(e.target.value)
        // check if input is not number
        if (isNaN(toIndex)) {
            setToIndexToUpdate('')
            return
        }
        // check if input number is not greater than the number of steps
        if (toIndex > numberOfWorkflowSteps)
            return
        
        setToIndexToUpdate(toIndex === 0 ? '' : toIndex)
        let toStepUuid = assignedSteps[toIndex - 1]

        let connectionToUpdateTemp = JSON.parse(JSON.stringify(connectionToUpdate))
        connectionToUpdateTemp.toStep = toStepUuid
        setConnectionToUpdate(connectionToUpdateTemp)
    }

    return (
        <div className='flex flex-column'>
            <span className='f6 tc'>To</span>
            <input 
                className='w1 tc to-box-input'
                value={toIndexToUpdate}
                onChange={handleChangeInToIndex}/>
        </div>
    )
}

export default ToBox