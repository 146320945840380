import logo from './logo.svg'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import InitialsInCircle from './InitialsInCircle'
import MenuBar from './MenuBar'
import AccountMenu from './AccountMenu'
import BreadCrumbBar from './BreadCrumbBar'
import {
    getAccountMenuUserDetailsService,
    signOutService,
} from '../Services/AuthenticationServices'

function MainMenuBar(props) {

    const navigateTo = useNavigate()

    const [showAccountMenu, setShowAccountMenu] = useState(false)

    const [user, setUser] = useState({})
    const [initials, setInitials] = useState('')

    async function signOut() {
        await signOutService()
        navigateTo('/signin')
    }

    useEffect(_ => {
        async function fetchUserDetails() {
            try {
                let response = await getAccountMenuUserDetailsService()
                let userTemp = response.data.user
                setUser(userTemp)
                setInitials(`${userTemp.firstName[0]}${userTemp.lastName[0]}`)
            } catch (err) {
                console.log(err)
            }

        }
        fetchUserDetails()
    }, [])

    return (
        // TODO 
        // <div style={{minWidth: location.pathname.includes('workflows') ? '1320px' : '1280px'}}>
        <div>
            <MenuBar>
                <div className='flex flex-row'>
                    <div className='ml5' style={{ height: '50px', marginTop: '8px' }}>
                        <img src={logo} width='auto' height='35px' alt='logo'/>
                    </div>
                    <div className='ml2 f4 white' style={{ marginTop: '19px' }}>
                        Workflow Manager
                    </div>
                </div>
                <InitialsInCircle
                    initials={initials}
                    onClick={_ => { setShowAccountMenu(prevValue => !prevValue) }} />
            </MenuBar>
            <BreadCrumbBar />
            {showAccountMenu &&
                <AccountMenu
                    user={user}
                    initials={initials}
                    signOut={signOut} />}
            <div onClick={_ => { setShowAccountMenu(false) }}>
                <Outlet />
            </div>
        </div>
    )
}

export default MainMenuBar;