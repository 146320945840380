import { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import CreateCancelSubmitLoading from '../../../Shared/CreateCancelSubmitLoading'
import Papa from 'papaparse';
import TaskService from '../../../Services/TaskService';
import uploadTargetDatesExample from './uploadTargetDatesExample.png'
import moment from 'moment';
import { validateFieldsStartChar } from '../../../Shared/validators';

export default function UploadFileDialog(props) {

    const fileInputTaskUpload = useRef(null)
    const renderUploadDialog = props.renderUploadDialog
    const setRenderUploadDialog = props.setRenderUploadDialog
    const [csvData, setCsvData] = useState(null)
    const [clickedSubmit, setClickedSubmit] = useState(false)
    const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const fetchItems = props.fetchItems
    const projectUuid = props.projectUuid

    function handleCSVUpload() {
        cleanUpErrorMessages()
        if (fileInputTaskUpload.current) {
            try {
                Papa.parse(fileInputTaskUpload.current.files[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results) => {
                        let uploadedTargetDates = []
                        for (let i = 0; i < results.data.length; i++) {
                            if (!("taskUuid" in results.data[i] && "stepUuid" in results.data[i])) {
                                setIsSomethingWentWrong(true)
                                setErrorMessage('Invalid fields in the CSV file.')
                                fileInputTaskUpload.current.value = null;
                                return
                            }
                            const parsedMoment = moment(results.data[i].targetDate);
                            if (results.data[i].targetDate !== '' && !parsedMoment.isValid()) {
                                setIsSomethingWentWrong(true)
                                setErrorMessage(`${results.data[i].targetDate} is not support, please provide targetDate field in the csv file as ISO format (YYYY-MM-DD).`)
                                fileInputTaskUpload.current.value = null;
                                return
                            }
                            if (!Number.isInteger(Number(results.data[i].duration)) || Number(results.data[i].duration) < 0) {
                                setIsSomethingWentWrong(true)
                                setErrorMessage(`${results.data[i].duration} is not valid, duration field should be a positive integer`)
                                fileInputTaskUpload.current.value = null;
                                return
                            }
                            const result = validateFieldsStartChar(results.data[i])
                            if (!result.isValid) {
                                setIsSomethingWentWrong(true)
                                setErrorMessage(`The value of ${result.key} cannot starts with =, +, - or @: ${result.value}`)
                                fileInputTaskUpload.current.value = null;
                                return
                            }
                        }
                        uploadedTargetDates = Object.values(
                            results.data.reduce((acc, { taskUuid, stepUuid, targetDate, duration }) => {
                                if (!acc[taskUuid]) {
                                    acc[taskUuid] = { taskUuid, targetDates: [] };
                                }
                                let parsedDate = ''
                                if (targetDate !== '') {
                                    parsedDate = moment(targetDate).format('YYYY-MM-DD') + 'T00:00:00Z'
                                }
                                acc[taskUuid].targetDates.push({ stepUuid, targetDate: parsedDate, duration });
                                return acc;
                            }, {})
                        );
                        setCsvData(uploadedTargetDates)
                    }
                });
            } catch (e) {
                console.error(e)
            }
        }
    }
    async function handleFileSubmit() {
        if (csvData) {
            setClickedSubmit(true)
            try {
                await TaskService.bulkUpdateTargetDates({ csvData, projectUuid })
                setClickedSubmit(false)
                setRenderUploadDialog(false)
                fetchItems()
            }
            catch (error) {
                setIsSomethingWentWrong(true)
                setErrorMessage(error.response.data)
                setClickedSubmit(false)
            }
        }
    }

    function cleanUpErrorMessages() {
        setIsSomethingWentWrong(false)
        setErrorMessage('')
    }

    function closeDialog() {
        cleanUpErrorMessages()
        setRenderUploadDialog(false)
    }

    return (
        <Dialog visible={renderUploadDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Details" modal className="p-fluid" onHide={closeDialog}>
            <div>
                <p>You can retrieve <strong>template</strong> with existing data by exporting.</p>
                <p>Or create a spreadsheet at least contains following fields.</p>
                <ul>
                    <li><strong>taskUuid</strong></li>
                    <li><strong>stepUuid</strong></li>
                    <li><strong>targetDate</strong> or <strong>duration</strong></li>
                </ul>
                <img src={uploadTargetDatesExample} alt='upload target dates csv example' />
                <p>Save the spreadsheet as a <strong>.csv</strong> file and upload.</p>
                <input className='' type='file' name='TaskUploadCSV' ref={fileInputTaskUpload} onChange={handleCSVUpload} accept='.csv' />
                <div className='mt3 mb2 white'>This is a feedback message about the upload</div>
                <CreateCancelSubmitLoading
                    isSomethingWentWrong={isSomethingWentWrong}
                    isNowLoading={clickedSubmit}
                    createButtonText='Submit'
                    errorMessage={errorMessage}
                    handleCreateResourceClick={handleFileSubmit}
                    handleCloseErrorBoxClick={cleanUpErrorMessages}
                    buttonPosition='right'
                />
            </div>
        </Dialog>

    )
}


