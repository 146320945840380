import LoginLogoutContainer from './LoginLogoutContainer'
import PrimaryButton from '../Shared/PrimaryButton'
import ErrorMessage from '../Shared/ErrorMessage'
import NowLoading from '../Shared/NowLoading'
import { getSsoLinkService } from './../Services/AuthenticationServices'
import { useState, useEffect } from 'react'

function Login(props) {
    const clickLogin = props.clickLogin;
    const Width = '350px';
    const usernameValue = props.usernameValue;
    const passwordValue = props.passwordValue;
    const setUsernameValue = props.setUsernameValue;
    const setPasswordValue = props.setPasswordValue;
    const blockLoginButton = props.blockLoginButton;
    const errorMessage = props.errorMessage;

    const [SSOPISAConnectUrl, setSSOPISAConnectUrl] = useState('')
    const [SSOPISAConnectEnabled, setSSOPISAConnectEnabled] = useState('false')
    function updateUsername(e) {
        setUsernameValue(e.target.value)
    }

    function updatePassword(e) {
        setPasswordValue(e.target.value)
    }

    function handleNavigateToSSO(url){
        window.location.href = url;
    }

    useEffect(_ => {
        async function fetchSsoLink() {
            try {
                let response = await getSsoLinkService()
                setSSOPISAConnectEnabled(response.data.SSOPISAConnectEnabled)
                setSSOPISAConnectUrl(response.data.SSOPISAConnectUrl)
            } catch (err) {
                console.error(err)
            }
        }
        fetchSsoLink()
    }, [])

    return (
        <LoginLogoutContainer Width={Width}>
            <div className="flex flex-column w5 mt5">
                <label className='primary'>Username</label>
                <input
                    className='f5 username'
                    value={usernameValue}
                    onChange={updateUsername}
                />
            </div>
            <div className="flex flex-column mt4 w5 mb3">
                <label className='primary'>Password</label>
                <input
                    className='f5 password'
                    value={passwordValue}
                    onChange={updatePassword}
                    type="password"
                />
            </div>
            {errorMessage !== '' &&
                <div className='mb4 w5'>
                    <ErrorMessage>
                        {errorMessage}
                    </ErrorMessage>
                </div>}
            {errorMessage === '' &&
                <div className='mb4 white'>-</div>}
            <div style={{height: '80px'}}>
                {!blockLoginButton &&
                    <div className='flex flex-row-reverse justify-between items-center mt4'>
                        <div className="login" onClick={clickLogin}>
                            <PrimaryButton buttonText='Sign in'></PrimaryButton>
                        </div>
                    </div>}
                {blockLoginButton &&
                    <div className="flex justify-center mt5">
                        <NowLoading />
                    </div>}
            </div>
            {SSOPISAConnectEnabled === 'True' &&
                <div className='flex flex-column '>
                    <button className='mb4 flex justify-center bg-secondary b--secondary ba primary pointer grow' onClick={() => handleNavigateToSSO(SSOPISAConnectUrl)}>Sign in with PISA Connect</button>
                </div>}
        </LoginLogoutContainer>
    );

}

export default Login;