import {
    removeSourceService,
    addSourceAtNextStepService,
    checkCommit,
    checkCompletion,
    copyTranslationFile,
    copyTranslationFileSelf,
    copyTranslationFileFinal,
    copyBackwardsService,
    arrangeTmxFilesService
} from '../Services/CodeCommitServices'

import { updateOnStepForTaskService } from '../Services/TaskViewServices'

export async function applyTransitionAndActionsHelper({
    transitionData,
    isBorrowerLocale,
    setAllTargetFilesRecentlySavedStatus,
    setAllTranslationsCompleteStatus,
    setRemoveSourceStatus,
    setCopyFileSelfStatus,
    setCopyFileStatus,
    setCopyFileFinalStatus,
    setAddSourceAtNextStepStatus,
    setCopyBackwardsStatus,
    setArrangeTmxFilesStatus,
    checkedCopyWithConfirmation
}) {
    let commitId = ''
    for (const data of transitionData) {
        const { connection, stepFrom, stepTo, task } = data
        const connectionUuid = connection.connectionUuid
        const queueOption = connection.queueOption ?? 'Append'
        const isEmail = connection.isEmail ?? false
        const localeUuid = task.localeUuid
        const groupUuid = task.groupUuid
        const projectUuid = task.projectUuid
        const projectName = task?.project?.projectName?.toLowerCase()
        const emailTemplateUuid = connection.emailTemplateUuid ?? 'default'
        const actionValues = connection.actions.map(action => action.value)
        const isCheckCommit = actionValues.includes('checkCommit')
        const isCheckComplete = actionValues.includes('checkComplete')
        const isRemoveSource = actionValues.includes('removeSource')
        const isCopy = actionValues.includes('copy')
        const isCopyAuto = actionValues.includes('copyAuto')
        const isSelfCopy = actionValues.includes('selfCopy')
        const isSelfCopyWithConfirmation = actionValues.includes('selfCopyWithConfirmation')
        const isCopyFinal = actionValues.includes('copyFinal')
        const isAddSourceAtNextStep = actionValues.includes('addSourceAtNextStep')
        const isCreatePreviewTag = actionValues.includes('createPreviewTag')
        const isCopyBackwards = actionValues.includes('copyBackwards')
        const fromStepName = stepFrom.stepName.toLowerCase().replace(/ /g, '-')

        let formData = new FormData();
        formData.append('stepTo', stepTo.stepName)
        formData.append('fromStepUuid', stepFrom.stepUuid)
        formData.append('toStepUuid', stepTo.stepUuid)
        formData.append('taskUuid', task.taskUuid)
        formData.append('taskName', task.taskName)
        formData.append('connectionUuid', connectionUuid)
        formData.append('comment', '')
        formData.append('emailTemplateUuid', emailTemplateUuid)
        formData.append('isEmail', isEmail)

        // 1
        if (isCheckCommit) {
            await checkCommit({
                projectName,
                localeUuid,
                fromStepName,
                taskUuid: task.taskUuid,
            })
            if (!isBorrowerLocale) {
                setAllTargetFilesRecentlySavedStatus('done')
            }
        }
        // 2
        if (isCheckComplete && !checkedCopyWithConfirmation) {
            await checkCompletion({
                projectName,
                localeUuid,
                fromStepName
            })
            if (!isBorrowerLocale) {
                setAllTranslationsCompleteStatus('done')
            }
        }

        if (isSelfCopy) {
            await copyTranslationFileSelf({ toStepUuid: stepTo.stepUuid, fromStepUuid: stepFrom.stepUuid, projectName, localeUuid, taskUuid: task.taskUuid, isSelfCopyConirmation: false })
            if (!isBorrowerLocale) {
                setCopyFileSelfStatus('done')
            }
        }

        if (isSelfCopyWithConfirmation) {
            await copyTranslationFileSelf({ toStepUuid: stepTo.stepUuid, fromStepUuid: stepFrom.stepUuid, projectName, localeUuid, taskUuid: task.taskUuid, isSelfCopyConirmation: true, checkedCopyWithConfirmation: checkedCopyWithConfirmation })
            if (!isBorrowerLocale) {
                setCopyFileSelfStatus('done')
            }
        }

        if (isCopy) {
            await copyTranslationFile({ toStepUuid: stepTo.stepUuid, fromStepUuid: stepFrom.stepUuid, projectName, localeUuid, taskUuid: task.taskUuid, isAuto: false })
            if (!isBorrowerLocale) {
                setCopyFileStatus('done')
            }
        }
        if (isCopyAuto) {
            await copyTranslationFile({ toStepUuid: stepTo.stepUuid, fromStepUuid: stepFrom.stepUuid, projectName, localeUuid, taskUuid: task.taskUuid, isAuto: true })
            if (!isBorrowerLocale) {
                setCopyFileStatus('done')
            }
        }
        if (isCopyFinal) {
            await copyTranslationFileFinal({ fromStepUuid: stepFrom.stepUuid, projectName, localeUuid, taskUuid: task.taskUuid })
            if (!isBorrowerLocale) {
                setCopyFileFinalStatus('done')
            }
        }
        if (isCopyBackwards) {
            await copyBackwardsService({
                toStepUuid: stepTo.stepUuid,
                fromStepUuid: stepFrom.stepUuid,
                projectName,
                localeUuid,
                taskUuid: task.taskUuid,
            })
            if (!isBorrowerLocale) {
                setCopyBackwardsStatus('done')
            }
        }

        if (isRemoveSource) {
            const response = await removeSourceService({
                localeUuid,
                projectName,
                isCreatePreviewTag,
                taskUuid: task.taskUuid,
                fromStepUuid: stepFrom.stepUuid,
                toStepUuid: stepTo.stepUuid,
                groupUuid,
                toStepName: stepTo.stepName
            })
            commitId = response.data.commitId
            if (!isBorrowerLocale) {
                setRemoveSourceStatus('done')
            }
        }
        if (isAddSourceAtNextStep) {
            await addSourceAtNextStepService({
                isCreatePreviewTag,
                taskUuid: task.taskUuid,
                projectName,
                projectUuid,
                localeUuid,
                queueOption,
                toStepUuid: stepTo.stepUuid,
                groupUuid,
                fromStepName
            })

            if (!isBorrowerLocale) {
                setAddSourceAtNextStepStatus('done')
            }
        }

        // if there is at least one step is repo-step, arrange the tmx files for the step.

        if (stepFrom.queueEnabled || stepTo.queueEnabled) {
            await arrangeTmxFilesService({
                projectName,
                taskUuid: task.taskUuid,
                fromStepUuid: stepFrom.queueEnabled ? stepFrom.stepUuid : null,
                toStepUuid: stepTo.queueEnabled ? stepTo.stepUuid : null,
            })
            if (!isBorrowerLocale) {
                setArrangeTmxFilesStatus('done')
            }
        }

        if (isBorrowerLocale) {
            // only run update step here for borrower locales, main locale will update step in TransitionModal.js
            await updateOnStepForTaskService(task.taskUuid, formData)
        }
    }
    return commitId
}