import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import NowLoading from "../../../Shared/NowLoading"
import MessageRow from './MessageRow'
import AddNew from "../../../Shared/AddNew"

import { 
    addANewMessageService 
} from '../../../Services/WorkflowAppServices'
import MessageService from '../../../Services/MessageService'

function Messages(props){

    let { projectName } = useParams();

    const [isNowLoading, setIsNowLoading] = useState(true)
    const [messagesList, setMessagesList] = useState([])
    const [isLoadingNewMessage, setIsLoadingNewMessage] = useState(false)
    const [projectPermissions, setProjectPermissions] = useState({})

    async function handleAddANewMessageClick(){
        setIsLoadingNewMessage(true)

        let serviceProps = { projectName }
        await addANewMessageService(serviceProps)
            .then(response => {
                fetchMessages()
            })
            .finally(() => setIsLoadingNewMessage(false))
    }

    const fetchMessages = useCallback(async _ => {
        setProjectPermissions(props.projectPermissions)
        await MessageService.find({ projectUuid: props.project.projectUuid })
            .then(response => {
                setMessagesList(response.data.map(message => {
                    return <MessageRow
                        message={message}
                        fetchMessages={fetchMessages}
                        projectPermissions={props.projectPermissions}
                    />
                }))
            })
            .finally(() => setIsNowLoading(false))
    }, [props.project, props.projectPermissions])
    
    useEffect( _ => {
        fetchMessages()
    }, [fetchMessages])

    return (
        <>
        {isNowLoading &&
            <div className='flex justify-center mt5'>
                <NowLoading />
            </div>}
        {!isNowLoading &&
            <div className='breadcrumb-home-alignment' >
                <div className='pt4' >
                    <div className='mt4 f3'>
                        Workflow messages
                    </div>
                    {projectPermissions.canCreateWorkflowMessages &&
                    <div style={{marginTop: '-10px'}}>
                        <AddNew
                            isLoading={isLoadingNewMessage}
                            onClick={handleAddANewMessageClick}
                            width='180px'
                            label='Add a new message'/>
                    </div>}
                </div>
                <div style={{marginTop: '50px', marginRight: '16px'}}>
                    {messagesList}
                </div>
            </div>
            }
    </>
    )
}

export default Messages