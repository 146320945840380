import Modal from "../Shared/Modal"
import SummaryBlock from "./SummaryBlock"
import columns from "./columns"

function SummaryModal(props){

    const rows = props.rows
    const onClickClose = props.onClickClose

    let rowArrays = {
        taskName: [],
        groupName: [],
        workflowName: [],
        categoryName: [],
        localeName: [],
        status: [],
        onStep: [],
        actionRequired: [],
        pending: []
    }

    let rowSummaries = JSON.parse(JSON.stringify(rowArrays))

    rows.forEach(row => {
        let keys = Object.keys(rowArrays)
        keys.forEach(key => {
            rowArrays[key].push(row[key])
        })
    })
    
    let keys = Object.keys(rowArrays)

    keys.forEach(key => {
        rowSummaries[key] = rowArrays[key].reduce((acc, curr) => (acc[curr] = (acc[curr] || 0) + 1, acc), {})
    })

    let summaryBlocks = columns[0].columns.filter(column => typeof column.pluralHeader !== 'undefined')

    let summaryBlocksList = summaryBlocks.map(
        summaryBlock => 
            <SummaryBlock 
                accessor={summaryBlock.accessor} 
                title={summaryBlock.pluralHeader} 
                rowSummaries={rowSummaries}
                key={summaryBlock.accessor} 
            />
        )

    let onStepArray = rowArrays.onStep

    let numeratorTotal = 0
    let denominatorTotal = 0
    let percentage
    onStepArray.forEach(onStep => {
        let [numerator, denominator] = onStep.split(' of ')
        numeratorTotal += Number(numerator) - 1
        denominatorTotal += Number(denominator) - 1
        percentage = 100*(numeratorTotal/denominatorTotal)
    })

    return (
        <Modal modalTitle='Tasks summary' onClickClose={onClickClose}>
            {rows.length !== 0 &&
            <>
                <div className='mt2'>
                    <div className='primary'>For the {rows.length} tasks displayed:</div>
                    <ul className=''>
                        <li>Action is required for <strong>{rowSummaries.actionRequired.Yes}</strong> tasks.</li>
                        <li className='mt2'>Total task progress is <strong>{numeratorTotal} out of {denominatorTotal}</strong> or <strong>{Math.round(percentage)}%</strong></li>
                    </ul>
                    <div className='mt4 primary'>Frequency of each entry per column across displayed tasks:</div>
                    {summaryBlocksList}
                </div>
            </>}
            {rows.length === 0 &&
                <p>There are no tasks currently displayed. Please reduce the specificity of your search queries.</p>}
        </Modal>
    )
}

export default SummaryModal