import AppService from './AppService'
const baseURL = '/api'
const API = AppService.initAPI(baseURL)

export async function assignUserService(){
    return await API.post('/assignUser')
}

export async function signInService(serviceProps){
    return await API.post('/login', serviceProps)
}

export async function getSsoLinkService(){
    return await API.get('/getSsoLink')
}

export async function signOutService(){
    return await API.get('/logout')
}

export async function getApplicationPermissionsService(){
    return await API.get('/getApplicationPermissions') 
}

export async function getAccountMenuUserDetailsService(){
    return await API.get('/getAccountMenuUserDetails')
}

export async function getAssignedRoles(){
    return await API.get('/assignedRoles') 
}