function RoleToEmailList(props){

    const roles = props.roles
    const connection = props.connection

    let roleTitles = connection.roleToEmail
        .map(roleUuid => {
            return roles.find(role => role.roleUuid === roleUuid).roleTitle
        })
        .sort()
        .map(label => {
            return <>{label} <br /></>
        })


    return (
        <div className='f6 flex flex-row mt1'>
            <span className='b ml1 workflow-config-step-title'>
                Role to email:
            </span>
            <span className='ml1 workflow-config-step-value'>
                {roleTitles}
            </span>
        </div>
    )
}

export default RoleToEmailList