import { useQuery } from '@tanstack/react-query'
import { getTasksService } from '../Services/TaskViewServices'

export const useTasksView = ({ tableParams, projectUuid }) => {
    const getTasksViewQuery = useQuery({
        queryKey: ['tasksView', tableParams, projectUuid],
        queryFn: async () => {
            const response = await getTasksService({ ...tableParams, projectUuid, isExport: false })
            return response.data
        },
        staleTime: 1000,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
    })

    return {
        tasks: getTasksViewQuery.data?.tasks,
        total: getTasksViewQuery.data?.total ?? 0,
        getTasksViewError: getTasksViewQuery.error,
        refetchTasksView: getTasksViewQuery.refetch,
        isFetchedTasksView: getTasksViewQuery.isFetched
    }
}
