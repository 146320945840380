import { useQuery } from '@tanstack/react-query'
import Services from '../Services/WorkflowService'

export const useWorkflows = (projectUuid) => {
    const getWorkflowsQuery = useQuery({
        queryKey: ['workflows', projectUuid],
        queryFn: async () => {
            const response = await Services.find({ projectUuid })
            return response.data
        },
        staleTime: 1000,
        refetchOnWindowFocus: false,
    })
    return {
        workflows: getWorkflowsQuery.data,
    }
}