const timezones = [
    { label: '(UTC-12:00) International Date Line West', value: -12 },
    { label: '(UTC-11:00) Coordinated Universal Time-11', value: -11 },
    { label: '(UTC-10:00) Hawaii, Aleutian Islands', value: -10 },
    { label: '(UTC-09:30) Marquesas Islands', value: -9.5 },
    { label: '(UTC-09:00) Alaska, Coordinated Universal Time-09', value: -9 },
    { label: '(UTC-08:00) Pacific Time (US & Canada), Baja California', value: -8 },
    { label: '(UTC-07:00) Mountain Time (US & Canada), Arizona', value: -7 },
    { label: '(UTC-06:00) Central Time (US & Canada), Mexico City', value: -6 },
    { label: '(UTC-05:00) Eastern Time (US & Canada), Bogota', value: -5 },
    { label: '(UTC-04:00) Atlantic Time (Canada), Caracas', value: -4 },
    { label: '(UTC-03:30) Newfoundland', value: -3.5 },
    { label: '(UTC-03:00) Buenos Aires, Brasilia', value: -3 },
    { label: '(UTC-02:00) Coordinated Universal Time-02', value: -2 },
    { label: '(UTC-01:00) Azores, Cabo Verde Is.', value: -1 },
    { label: '(UTC+00:00) Coordinated Universal Time', value: 0 },
    { label: '(UTC+01:00) Berlin, Paris', value: 1 },
    { label: '(UTC+02:00) Athens, Cairo', value: 2 },
    { label: '(UTC+03:00) Moscow, Nairobi', value: 3 },
    { label: '(UTC+03:30) Tehran', value: 3.5 },
    { label: '(UTC+04:00) Abu Dhabi, Baku', value: 4 },
    { label: '(UTC+04:30) Kabul', value: 4.5 },
    { label: '(UTC+05:00) Karachi, Tashkent', value: 5 },
    { label: '(UTC+05:30) Kolkata, Sri Jayawardenepura', value: 5.5 },
    { label: '(UTC+05:45) Kathmandu', value: 5.75 },
    { label: '(UTC+06:00) Dhaka, Astana', value: 6 },
    { label: '(UTC+06:30) Yangon', value: 6.5 },
    { label: '(UTC+07:00) Bangkok, Jakarta', value: 7 },
    { label: '(UTC+08:00) Beijing, Singapore', value: 8 },
    { label: '(UTC+08:45) Eucla', value: 8.75 },
    { label: '(UTC+09:00) Tokyo, Seoul', value: 9 },
    { label: '(UTC+09:30) Adelaide, Darwin', value: 9.5 },
    { label: '(UTC+10:00) Sydney, Melbourne', value: 10 },
    { label: '(UTC+10:30) Lord Howe Island', value: 10.5 },
    { label: '(UTC+11:00) Solomon Is., New Caledonia', value: 11 },
    { label: '(UTC+12:00) Auckland, Fiji', value: 12 },
    { label: '(UTC+13:00) Nuku\'alofa, Samoa', value: 13 },
    { label: '(UTC+14:00) Kiritimati Island', value: 14 }
];

export default timezones