const AddTokenInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.request.use((request) => {
        request.headers["Authorization"] = localStorage.getItem('token');

        return request;
    }, (error) => {

    });
};

export default AddTokenInterceptor;