import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { FilterMatchMode } from 'primereact/api';
import TaskService from '../../../Services/TaskService';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import UploadFileDialog from './UploadFileDialog'
import Papa from 'papaparse';
import moment from 'moment';
import GroupService from '../../../Services/GroupService'
import LocaleService from '../../../Services/LocaleService'
import CategoryService from '../../../Services/CategoryService'
import WorkflowService from '../../../Services/WorkflowService'
import WorkflowStepService from '../../../Services/WorkflowStepService'
import { validateFieldsStartChar } from '../../../Shared/validators';

export default function ItemTable(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const emptyItem = {
        targetDate: null,
        duration: 0,
    }
    const projectUuid = props.project.projectUuid
    const [item, setItem] = useState(emptyItem);
    const [error, setError] = useState(null);
    const [itemDialog, setItemDialog] = useState(false);
    const [renderUploadDialog, setRenderUploadDialog] = useState(false)
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const toast = useRef(null);
    const [projectPermissions, setProjectPermissions] = useState({})
    const [groups, setGroups] = useState(null)
    const [locales, setLocales] = useState(null)
    const [tasks, setTasks] = useState(null)
    const [categories, setCategories] = useState(null)
    const [workflows, setWorkflows] = useState(null)
    const [steps, setSteps] = useState(null)
    const [lazyFilters, setLazyFilters] = useState({
        projectUuid: props.project.projectUuid,
        localeUuid: undefined
    })
    const [filters] = useState({
        taskName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        groupName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        workflowName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        stepName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const fetchTasks = useCallback(async () => {
        if (lazyFilters.projectUuid && lazyFilters.localeUuid) {
            setLoading(true);
            try {
                const response = await TaskService.find({ ...lazyFilters })
                setTasks(response.data)
            } catch (err) {
                console.error(err)
                setError(err.response?.data ?? 'Something went wrong.')
            }
        }
    }, [lazyFilters])

    useEffect(() => {
        try {
            GroupService.find({ projectUuid: props.project.projectUuid }).then(response => setGroups(response.data))
            CategoryService.find({ projectUuid: props.project.projectUuid }).then(response => setCategories(response.data))
            WorkflowService.find({ projectUuid: props.project.projectUuid }).then(response => setWorkflows(response.data))
            WorkflowStepService.find({ projectUuid: props.project.projectUuid }).then(response => setSteps(response.data))
            LocaleService.find({ projectUuid: props.project.projectUuid }).then(response => {
                if (response && response.data && response.data[0]) {
                    setLazyFilters((prevFilter) => ({
                        ...prevFilter,
                        localeUuid: response.data[0].localeUuid,
                    }));
                }
                setLocales(response.data);
            })
        } catch (err) {
            console.error(err)
            setError(err?.response?.data || 'Error')
        }
    }, [props.project]);

    useEffect(() => {
        fetchTasks();
    }, [lazyFilters]);

    useEffect(() => {
        if (groups && categories && workflows && steps && locales && tasks) {
            const _items = [];
            const workflowsInUse = workflows.filter(workflow => tasks.map(task => task.workflowUuid).includes(workflow.workflowUuid));
            const stepUuidsInUse = [...new Set(workflowsInUse.flatMap(workflow => workflow.assignedSteps))];
            const stepsInUse = steps.filter(step => stepUuidsInUse.includes(step.stepUuid))
            tasks.forEach(task => {
                stepsInUse.forEach(workflowStep => {
                    const stepTargetDate = task.targetDates.find(targetDateObject => targetDateObject.stepUuid === workflowStep.stepUuid)?.targetDate ?? null;
                    const stepDuration = task.targetDates.find(targetDateObject => targetDateObject.stepUuid === workflowStep.stepUuid)?.duration ?? null;
                    const categoryName = categories.find(categoryObject => categoryObject.categoryUuid === task.categoryUuid) ? categories.find(categoryObject => categoryObject.categoryUuid === task.categoryUuid).categoryName : null;
                    const localeName = locales.find(localeObject => localeObject.localeUuid === task.localeUuid) ? locales.find(localeObject => localeObject.localeUuid === task.localeUuid).localeName : null;
                    const groupName = groups.find(groupObject => groupObject.groupUuid === task.groupUuid) ? groups.find(groupObject => groupObject.groupUuid === task.groupUuid).groupName : null;
                    const workflowName = workflowsInUse.find(workflowObject => workflowObject.workflowUuid === task.workflowUuid) ? workflowsInUse.find(workflowObject => workflowObject.workflowUuid === task.workflowUuid).workflowName : null;
                    _items.push({
                        taskUuid: task.taskUuid,
                        taskName: task.taskName,
                        workflowName: workflowName,
                        stepUuid: workflowStep.stepUuid,
                        stepName: workflowStep.stepName,
                        categoryName: categoryName,
                        localeName: localeName,
                        groupName: groupName,
                        targetDate: stepTargetDate,
                        duration: stepDuration
                    });
                });
            });
            setItems(_items);
        }
        setLoading(false);
    }, [groups, categories, workflows, steps, locales, tasks]);

    useEffect(() => {
        setProjectPermissions(props.projectPermissions)
    }, [props.projectPermissions]);

    const editItem = (item) => {
        setItem({ ...item });
        setItemDialog(true);
    };

    const confirmDeleteItem = (item) => {
        setItem(item);
        setDeleteItemDialog(true);
    };
    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
        setError(null)
    };

    const hideItemDialog = () => {
        setItem(emptyItem)
        setItemDialog(false);
    };

    const saveItem = async () => {
        if (!item.targetDate && !item.duration) {
            toast.current.show({ severity: 'error', summary: 'Duration or target date required', detail: `Please provide at least a duration or a target date.`, life: 5000 });
            return
        }
        try {
            const parsedMoment = moment(item.targetDate)

            await TaskService.updateTargetDate(item.taskUuid, item.stepUuid, { targetDate: parsedMoment.isValid() ? parsedMoment.format('YYYY-MM-DD') + 'T00:00:00Z' : undefined, duration: item.duration }).then(data => fetchTasks())
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Updated', life: 3000 });

            setItemDialog(false);
            setItem(emptyItem);
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 });
        }
    };

    const deleteItem = async () => {
        try {
            await TaskService.deleteTargetDate(item.taskUuid, item.stepUuid).then(data => fetchTasks())
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 3000 });
            setDeleteItemDialog(false);
            setItem(emptyItem);
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 });
        }

    };

    const handleExportTargetDates = (event) => {
        // Primereact DataTable build-in export function doesn't support exporting selected columns, here is the custom implmentation.
        event.preventDefault();
        event.stopPropagation();
        const link = document.createElement('a');
        let exportData = items.map((item) => ({
            taskUuid: item.taskUuid,
            taskName: item.taskName,
            stepUuid: item.stepUuid,
            stepName: item.stepName,
            localeName: item.localeName,
            groupName: item.groupName,
            targetDate: item.targetDate ? `${moment(item.targetDate).format('YYYY-MM-DD')}` : '',
            duration: item.duration,
        }));
        exportData = exportData.filter((item) => {
            // Filter out items which fields value starts with =, +, - or @ before exporting to csv file to avoid malicious injection
            const result = validateFieldsStartChar(item)
            return result.isValid;
        })
        const csvData = Papa.unparse(exportData);
        link.href = URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
        link.download = 'target-dates.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                {projectPermissions?.canReadTargetDates &&
                    <Button label="Export" icon="pi pi-upload" className="p-button-info" onClick={handleExportTargetDates} />
                }
            </div>
        )
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                {projectPermissions?.canUpdateTargetDates &&
                    <Button label="Upload csv" icon="pi pi-plus" severity="success" onClick={() => setRenderUploadDialog(true)} />
                }
            </div>
        );
    };

    const onLocaleChange = (e) => {
        if (e.value) {
            setLoading(true);
            setLazyFilters((prevFilter) => ({
                ...prevFilter,
                localeUuid: e.value,
            }));
        }
    }

    const itemDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideItemDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveItem} />
        </React.Fragment>
    );

    const deleteItemDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteItem} />
        </React.Fragment>
    );

    const localesRowFilterTemplate = (options) => {
        return (
            <Dropdown name='localeUuid' value={lazyFilters.localeUuid} options={locales} onChange={(e) => onLocaleChange(e)} optionLabel="localeName" optionValue="localeUuid" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const tasksRowFilterTemplate = (options) => {
        return (
            <Dropdown name='taskUuid' value={options.value} options={tasks} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="taskName" optionValue="taskName" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const groupsRowFilterTemplate = (options) => {
        return (
            <Dropdown name='groupUuid' value={options.value} options={groups} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="groupName" optionValue="groupName" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const categoriesRowFilterTemplate = (options) => {
        return (
            <Dropdown name='categoryUuid' value={options.value} options={categories} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="categoryName" optionValue="categoryName" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const workflowsRowFilterTemplate = (options) => {
        return (
            <Dropdown name='workflowUuid' value={options.value} options={workflows} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="workflowName" optionValue="workflowName" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const stepsRowFilterTemplate = (options) => {
        return (
            <Dropdown name='stepUuid' value={options.value} options={steps} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="stepName" optionValue="stepName" placeholder="Select One" className="p-column-filter" filter style={{ minWidth: '12rem' }} />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <>
                    {projectPermissions?.canUpdateTargetDates &&
                        <Button icon="pi pi-pencil" outlined className="mr-2 bg-primary right-0" onClick={() => editItem(rowData)} />}
                    {projectPermissions?.canDeleteTargetDates &&
                        <Button icon="pi pi-trash" outlined severity="danger bg-primary right-0" onClick={() => confirmDeleteItem(rowData)} />}
                </>
            </React.Fragment>
        )
    }

    return (
        <>
            <div className="card">
                <Toast ref={toast} />
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable value={items} tableStyle={{}} loading={loading}
                    filters={filters} filterDisplay="row"
                    sortField="createdAt" sortOrder="-1">
                    <Column field="taskName" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Task" filterElement={tasksRowFilterTemplate}></Column>
                    <Column field="groupName" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Group" filterElement={groupsRowFilterTemplate}></Column>
                    <Column field="localeName" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Locale" filterElement={localesRowFilterTemplate}></Column>
                    <Column field="categoryName" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Category" filterElement={categoriesRowFilterTemplate}></Column>
                    <Column field="workflowName" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Workflow" filterElement={workflowsRowFilterTemplate}></Column>
                    <Column field="stepName" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Step" filterElement={stepsRowFilterTemplate}></Column>
                    <Column field="targetDate" sortable header="Target date" body={(rowData) => {
                        let localDate = ''
                        if (rowData.targetDate) {
                            const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' };
                            localDate = new Date(rowData.targetDate).toLocaleString(undefined, options);
                        }
                        return <span>{localDate}</span>;
                    }}>
                    </Column>
                    <Column field="duration" filter showFilterMenu={false} showFilterMatchModes={false} sortable header="Duration" ></Column>
                    <Column body={actionBodyTemplate} header='Action' exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={deleteItemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {item && (
                        <span>
                            Are you sure you want to delete the <b>target date</b> of <b>{item.taskName}</b> in <b>{item.stepName}</b>?
                        </span>
                    )}
                    {error && <small className="p-error">{error}</small>}
                </div>
            </Dialog>

            <Dialog visible={itemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Details" modal className="p-fluid" footer={itemDialogFooter} onHide={hideItemDialog}>
                <div className="field">
                    <label htmlFor="name" className="font-bold">
                        Target date
                    </label>
                    <Calendar value={item?.targetDate ? new Date(item.targetDate) : null} onChange={(e) => setItem({ ...item, targetDate: e.value })} showIcon />
                </div>
                <div className="field">
                    <label htmlFor="duration" className="font-bold">
                        Duration
                    </label>
                    <InputNumber
                        id="duration"
                        value={item.duration}
                        onChange={(e) => setItem(prevItem => ({
                            ...prevItem,
                            duration: e.value
                        }))}
                    />
                </div>
            </Dialog>

            <UploadFileDialog
                renderUploadDialog={renderUploadDialog}
                fetchItems={fetchTasks}
                projectUuid={projectUuid}
                setRenderUploadDialog={setRenderUploadDialog} />
        </>

    );
}