import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import IsNowLoading from '../../../Shared/IsNowLoading'

import Setting from './Setting'
import UpdateProjectDetails from './UpdateProjectDetails'
import DeleteProjectDetails from './DeleteProjectDetails'

import { getProjectPermissionsService } from '../../../Services/WorkflowAppServices'

function Settings(props){

    const { projectName } = useParams()

    const [projectPermissions, setProjectPermissions] = useState({})
    const [isNowLoading, setIsNowLoading] = useState(true)

    useEffect(_ => { 
        async function fetchPermissions(){
            let response = await getProjectPermissionsService({ projectName })

            if (response.status === 200){
                setProjectPermissions(response.data.projectPermissions)
                setIsNowLoading(false)
            }

        }
        fetchPermissions()
    }, [projectName])

    return (
        <IsNowLoading isNowLoading={isNowLoading}>
            <div className='mt5'>
                {projectPermissions?.canUpdateProject &&
                    <Setting settingName='Rename project'>
                        <UpdateProjectDetails />
                    </Setting>}
                {projectPermissions?.canDeleteProject &&
                    <Setting settingName='Delete project'>
                        <DeleteProjectDetails />
                    </Setting>}
            </div>
        </IsNowLoading>
    )
}

export default Settings