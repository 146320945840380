import AppService from './AppService'
const baseURL = '/api/messages'
const API = AppService.initAPI(baseURL)

const Services = {

    find: async function (params) {
        return await API.get(``, {params})
    },

};
export default Services