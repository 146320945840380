import { useState } from 'react'
import DataTable from './DataTable'
import IsNowLoading from '../../../Shared/IsNowLoading';
import ProjectPageHeading from '../../../Shared/ProjectPageHeading';

function UserAssignment(props) {
    const [isNowLoading] = useState(false)

    return (
        <IsNowLoading isNowLoading={isNowLoading}>
            <ProjectPageHeading pageTitle='User assignment'>
                <div className='assigned-user-table-section mt3 flex flex-column'>
                    <DataTable applicationPermissions={props.applicationPermissions} projectPermissions={props.projectPermissions} project={props.project}/>
                </div>
            </ProjectPageHeading>
        </IsNowLoading>
    )
}

export default UserAssignment