import greenTick from './greenTick.svg'

function GreenTick(props){
    return (
        <div style={{height: '22px'}}>
            <img src={greenTick} width='15px' alt='green tick'/>
        </div>
    )
}

export default GreenTick