import NowLoading from './NowLoading'
import Modal from './Modal'
import reactHtmlParser from 'react-html-parser'

function IsNowLoading(props){

    const isNowLoading = props.isNowLoading
    const error = props.error
    const setError = props.setError

    return (
        <div>
            {isNowLoading !== 'blank' && isNowLoading &&
                <div className='flex justify-center mt5'>
                    <NowLoading />
                </div>}
            {!isNowLoading && 
                <>
                    {props.children}
                </>}
            {error && 
                <Modal modalTitle={error.modalTitle ?? 'Error'} onClickClose={ _ => setError(false) }>
                    {reactHtmlParser(error.messageHTML ?? error)}
                </Modal>}
        </div>
    )
}

export default IsNowLoading