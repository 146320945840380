import AppService from './AppService'
const baseURL = '/api/categories'
const API = AppService.initAPI(baseURL)

const Services = {
    view: async function (id) {
        return await API.get(`/${id}`)
    },

    find: async function (params) {
        return await API.get(``, {params})
    },

    delete: async function (id) {
        return await API.delete(`/${id}`)
    },
    
    create: async function (body) {
        return await API.post(``, body)
    },

    update: async function (id, body) {
        return await API.put(`/${id}`, body)
    },
};
export default Services