import { useState } from 'react'

import CreateCancelSubmitLoading from '../../Shared/CreateCancelSubmitLoading'
import ProjectName from './ProjectName'
import NowLoading from '../../Shared/NowLoading'
import Modal from '../../Shared/Modal'

import ProjectService from '../../Services/ProjectService'

function CreateANewProjectModal(props){
    
    const allProjectNames = props.allProjectNames
    const fetchProjectsList = props.fetchProjectsList
    const setRenderCreateANewProjectModal = props.setRenderCreateANewProjectModal

    const [projectName, setProjectName] = useState('')
    const [projectNameDisplayed, setProjectNameDisplayed] = useState('')
    const [validDataForLaunch, setValidDataForLaunch] = useState({projectName: false, projectNameDisplayed: false})
    const [isNowLoading, setIsNowLoading] = useState(false)
    const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false)

    async function handleCreateProjectClick(){
        if (Object.values(validDataForLaunch).indexOf(false) !== -1)
            return
        setIsNowLoading(true)
        let serviceProps = { projectName, projectNameDisplayed }
        let response = await ProjectService.create(serviceProps)
        
        if (response.status === 400) {
            setIsSomethingWentWrong(true)
            setProjectName('')
        }

        if (response.status === 201) {
            setIsNowLoading(false)
            fetchProjectsList()
            setRenderCreateANewProjectModal(false)
        }
    }

    function handleCloseErrorBoxClick(){
        setIsSomethingWentWrong(false)
        setIsNowLoading(false)
    }

    return (
        <Modal modalTitle='Create a new project' onClickClose={_ => setRenderCreateANewProjectModal(false)}>
            <div style={{width: '520px'}}>
                {!allProjectNames &&
                    <div className='flex justify-center mt5'>
                        <NowLoading />
                    </div>}
                {allProjectNames &&
                    <div className='mb1'>
                        {/*TODO, Rename this ProjectName into ProjectCode, projectNameDisplayed into ProjectName*/}
                        <ProjectName 
                            projectName={projectName}
                            setProjectName={setProjectName}
                            projectNameDisplayed={projectNameDisplayed}
                            setProjectNameDisplayed={setProjectNameDisplayed}
                            allProjectNames={allProjectNames}
                            validDataForLaunch={validDataForLaunch}
                            setValidDataForLaunch={setValidDataForLaunch}
                        />
                        <CreateCancelSubmitLoading 
                            isSomethingWentWrong={isSomethingWentWrong}
                            isNowLoading={isNowLoading}
                            buttonPosition='right'
                            createButtonText='Create Project'
                            errorMessage='Something went wrong when trying to create this project. Please try again later.'
                            handleCloseErrorBoxClick={handleCloseErrorBoxClick}
                            handleCreateResourceClick={handleCreateProjectClick}
                        />
                    </div>}
            </div>
        </Modal>
    )
}

export default CreateANewProjectModal