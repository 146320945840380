import arrow from './arrow.svg'

function ArrowDown(props) {

    return (
        <div className='flex justify-center'>
            <img src={arrow} style={{ transform: 'rotate(90deg)' }} width='40px' alt='arrow'/>
        </div>
    )
}

export default ArrowDown