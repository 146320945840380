import React, { useState, useEffect } from 'react'
import '../tachyons.css'
import '../index.css'
import Login from './Login'

import { signInService, assignUserService } from '../Services/AuthenticationServices';

import { useNavigate, useSearchParams } from 'react-router-dom'

function AuthenticationApp(props){

    const navigateTo = useNavigate()
  
    const [searchParams] = useSearchParams();
    const [usernameValue, setUsernameValue] = useState('')
    const [passwordValue, setPasswordValue] = useState('')
    const [blockLoginButton, setBlockLoginButton] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const code = searchParams.get("code")
    useEffect(_ => {
        async function singleSignOn() {
            try {
                if (code) {
                    setBlockLoginButton(true)
                    setErrorMessage('')
                    await signInService({code})
                    await assignUserService()
                    navigateTo('/')
                }
            } catch (err) {
                setErrorMessage('Please try again.')
                setBlockLoginButton(false)
            }
            
        }
        singleSignOn()
    }, [navigateTo, code])

    async function login(){
      try {
        setBlockLoginButton(true)
        setErrorMessage('')
        let serviceProps = {
          username: usernameValue,
          password: passwordValue
        }
        let response = await signInService(serviceProps)

        if (response.data === 'authentication succeeded')
          navigateTo('/')
        
      } catch (err) {
        setErrorMessage('Please try again.')
        setBlockLoginButton(false)
      }

    }

    return (
        <Login 
            clickLogin={login}
            errorMessage={errorMessage}
            usernameValue={usernameValue}
            passwordValue={passwordValue}
            setUsernameValue={setUsernameValue}
            setPasswordValue={setPasswordValue}
            blockLoginButton={blockLoginButton}
        />
    )
}

export default AuthenticationApp