import AppService from './AppService'
const baseURL = '/api/users'
const API = AppService.initAPI(baseURL)

const Services = {

    find: async function (params) {
        return await API.get(``, {params})
    },

    import: async function (SSOName, body) {
        return await API.put(`/import/${SSOName}`, body)
    },

    syncCognito: async function () {
        return await API.post(`/syncCognito`)
    },

};
export default Services
