import { useEffect, useState } from 'react'

import { getTaskService } from '../Services/TaskViewServices'

function CrumbLink(props){

    // TODO this component is a mess, clean this up
    const crumb = props.crumb
    const onClick = props.onClick

    const [newCrumbName, setNewCrumbName] = useState('')

    useEffect( _ => {
        let crumbLookUp = {
            customtemplates : 'Custom templates',
            userassignment : 'User assignment',
            roleassignment : 'Role assignment',
            groupassignment : 'Group assignment',
            workflowsteps : 'Workflow steps',
            targetDates: 'Target dates',
            emailhistories: 'Email histories'
        }
        let newCrumbNameTemp = typeof crumbLookUp[crumb] === 'undefined' ? crumb : crumbLookUp[crumb] 
        setNewCrumbName(newCrumbNameTemp)
        
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        let isCrumbUuid = regexExp.test(newCrumbNameTemp) 
        if (isCrumbUuid)
            setNewCrumbName('')
        
        if (!isCrumbUuid)
            return

        async function fetchTask(){
            let serviceProps = { taskUuid: crumb }
            let response = await getTaskService(serviceProps)

            if (response.status === 200 ){
                setNewCrumbName(response.data?.taskName)
            }
        }
        fetchTask()
    }, [crumb])


    function capitaliseFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div className={`pointer underline-hover ${capitaliseFirstLetter(newCrumbName)}`} onClick={onClick}>
            {capitaliseFirstLetter(newCrumbName)}
        </div>
    )
}

export default CrumbLink