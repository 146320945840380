function PageSubHeading(props){

    const subHeading = props.subHeading
    let width = props.width

    if (typeof width === 'undefined') width = '950px'

    return (
        <div className='breadcrumb-home-alignment'>
            <div className='f3'>{subHeading}</div>
            <div className='bg-secondary br2 mt2' style={{width, height: '4px'}}></div>
            <div className='mb4' style={{width}}>
                {props.children}
            </div>
        </div>
    )
}

export default PageSubHeading