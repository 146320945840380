import { useState } from 'react'
import DataTable from './DataTable'
import IsNowLoading from '../../../Shared/IsNowLoading'
import ProjectPageHeading from '../../../Shared/ProjectPageHeading'
import { TabView, TabPanel } from 'primereact/tabview';
import DefaultTargetDatesTable from './DefaultTargetDatesTable'
function TargetDates(props) {
    const [isNowLoading] = useState(false)
    const [error, setError] = useState(false)

    return (
        <IsNowLoading isNowLoading={isNowLoading} error={error} setError={setError}>
            <ProjectPageHeading pageTitle='Target dates'>
                <TabView>
                    <TabPanel header="Default">
                        <DefaultTargetDatesTable projectPermissions={props.projectPermissions} project={props.project} />
                    </TabPanel>
                    <TabPanel header="Tasks">
                        <DataTable projectPermissions={props.projectPermissions} project={props.project} />
                    </TabPanel>
                </TabView>
            </ProjectPageHeading>
        </IsNowLoading>
    )
}

export default TargetDates