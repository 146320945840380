import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import {
    deleteWorkflowService
} from '../../../Services/WorkflowAppServices'
function WorkflowInstance(props) {

    const { workflow, toast, fetchWorkflows } = props
    const dateCreated = new Date(workflow.dateCreated)
    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
    const { projectName } = useParams()
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const handleDelete = async () => {
        setDeleteLoading(true)
        try {
            let serviceProps = { projectUuid: workflow.projectUuid, workflowUuid: workflow.workflowUuid }
            await deleteWorkflowService(serviceProps)
        } catch (err) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data ?? 'Something went wrong while deleting workflow.', life: 5000 });
        } finally {
            await fetchWorkflows()
            setDeleteLoading(false)
        }
    }
    const deleteItemDialogFooter = (
        <React.Fragment>
            <Button disabled={deleteLoading} label="No" icon="pi pi-times" outlined onClick={() => setDeleteItemDialog(false)} />
            <Button loading={deleteLoading} label="Yes" icon="pi pi-check" severity="danger" onClick={handleDelete} />
        </React.Fragment>
    );
    return (
        <div className='bg-light-secondary b--primary flex mt3 ba br2'>
            <div className='flex items-center ph3 gap-6 flex-direction-column'>
                <div className='primary' style={{ width: '275px', wordBreak: 'break-all' }}><b>Workflow name:</b> {workflow.workflowName}</div>
                <div className='primary'><b>Created on:</b> {dateCreated.toLocaleDateString(undefined, dateOptions)}</div>
                <div className='primary'><b>Workflow complete rate:</b> TBD</div>
                <div className='flex items-center ph3 gap-2 flex-direction-column'>
                    <Link to={`/${projectName}/workflow-management/workflows/${workflow.workflowName}`} style={{ textDecoration: 'none' }}>
                        <p className='primary b--primary pointer ba pa2 show-details b bw1 br2 choose-workflow-button'>Choose workflow</p>
                    </Link>
                    <Button severity="danger" icon="pi pi-trash" aria-label="Filter" outlined onClick={() => setDeleteItemDialog(true)} />
                </div>
            </div>
            <Dialog visible={deleteItemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={() => setDeleteItemDialog(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        Are you sure you want to delete <b>{workflow.workflowName}</b>?
                    </span>
                </div>
            </Dialog>
        </div>
    )
}

export default WorkflowInstance