import groups from './groups.svg'
import roles from './roles.svg'
import settings from './settings.svg'
import workflows from './workflows.svg'
import workflowSteps from './workflowSteps.svg'
import userAssignment from './userAssignment.svg'
import tasks from './tasks.svg'
import categories from './categories.svg'
import locales from './locales.svg'
import workflowMessages from './workflowMessages.svg'
import customTemplates from './customTemplates.svg'

const PROJECT_MENU_OPTIONS = [
    
    {
        navName: 'userassignment',
        friendlyName: 'User assignment',
        className: 'user-assignment-option',
        permissionKey: 'canReadUserAssignments',
        description: 'Assign users to this project.',
        logo: userAssignment
    },
    {
        navName: 'roles',
        friendlyName: 'Roles',
        className: 'roles-option',
        permissionKey: 'canReadRoles',
        description: 'Create a view a list of roles and permissions.',
        logo: roles   
    },
    {
        navName: 'groups',
        friendlyName: 'Groups',
        className: 'groups-option',
        permissionKey: 'canReadGroups',
        description: 'Create and view a list of user groups.',
        logo: groups
    },
    {
        navName: 'categories',
        friendlyName: 'Categories',
        className: 'categories-option',
        permissionKey: 'canReadCategories',
        description: 'Create and view a list of categories.',
        logo: categories
    },
    {
        navName: 'locales',
        friendlyName: 'Locales',
        className: 'locales-option',
        permissionKey: 'canReadLocales',
        description: 'Create and view a list of locales.',
        logo: locales
    },
    {
        navName: 'customtemplates',
        friendlyName: 'Custom Templates',
        className: 'custom-templates-option',
        permissionKey: 'canReadCustomTemplates',
        description: 'Create and view a list of custom templates.',
        logo: customTemplates
    },
    {
        navName: 'emailhistories',
        friendlyName: 'Email Histories',
        className: 'email-historieses-option',
        permissionKey: 'canReadEmailHistories',
        description: 'View a list of email histories.',
        logo: customTemplates
    },
    // {
    //     navName: 'roleassignment',
    //     friendlyName: 'Role assignment',
    //     className: 'role-assignment-option',
    //     permissionKey: 'canReadRoleAssignments',
    //     description: 'Assign roles to users in this project.',
    //     logo: roleAssignment
    // },
    // {
    //     navName: 'groupassignment',
    //     friendlyName: 'Group assignment',
    //     className: 'group-assignment-option',
    //     permissionKey: 'canReadGroupAssignments',
    //     description: 'Assign users in this project into groups.',
    //     logo: groupAssignment
    // },
    {
        navName: 'workflowsteps',
        friendlyName: 'Workflow steps',
        className: 'workflow-steps-option',
        permissionKey: 'canReadWorkflowSteps',
        description: 'Create a library of steps to build workflows.',
        logo: workflowSteps
    },
    {
        navName: 'messages',
        friendlyName: 'Workflow messages',
        className: 'workflow-messages-option',
        permissionKey: 'canReadWorkflowMessages',
        description: 'Create custom messages for workflow connections.',
        logo: workflowMessages
    },
    {
        navName: 'workflows',
        friendlyName: 'Workflows',
        className: 'workflows-option',
        permissionKey: 'canReadWorkflows',
        description: 'Build a workflow with steps and connections.',
        logo: workflows
    },
    {
        navName: 'tasks',
        friendlyName: 'Tasks',
        className: 'tasks-option',
        permissionKey: 'canReadTasks',
        description: 'Assign a task to a group and workflow.',
        logo: tasks
    },
    {
        navName: 'targetDates',
        friendlyName: 'Target dates',
        className: 'tasks-option',
        permissionKey: 'canReadTargetDates',
        description: 'Edit the target date for the task',
        logo: tasks
    },
    {
        navName: 'settings',
        friendlyName: 'Settings',
        className: 'settings-option',
        permissionKey: 'canUpdateProject',
        description: 'Rename or delete this project.',
        logo: settings
    }
]

export default PROJECT_MENU_OPTIONS