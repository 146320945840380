import { useState, useEffect } from 'react'
import DataTable from './DataTable'
import Transition from './Transition'
import ProjectPageHeading from '../Shared/ProjectPageHeading'
import PrimaryButton from '../Shared/PrimaryButton'
import { getTasksFilterFieldsService, getProjectAssignedRoles } from '../Services/TaskViewServices'
import WorkflowService from '../Services/WorkflowService'
import WorkflowStepService from '../Services/WorkflowStepService'
import MessageService from '../Services/MessageService'
import IsNowLoading from "../Shared/IsNowLoading"
import ProjectService from '../Services/ProjectService'
import { useTasksView } from '../hooks/useTasksView'
import { useLocation, useParams } from 'react-router-dom'

function TasksList() {
    const [tasksRowsToRender, setTasksRowsToRender] = useState([])
    const [isFetchTasksLoading, setIsFetchTasksLoading] = useState(false)
    const [error, setError] = useState(false)
    const [workflows, setWorkflows] = useState(null)
    const [workflowSteps, setWorkflowSteps] = useState(null)
    const [locales, setLocales] = useState(null)
    const [groups, setGroups] = useState(null)
    const [categories, setCategories] = useState(null)
    const [messages, setMessages] = useState(null)
    const [assignedRoleUuids, setAssignedRoleUuids] = useState(null)
    const location = useLocation()
    const { projectName } = useParams()
    const state = location.state
    // clean up state when refresh the page after consuming it
    window.history.replaceState(null, '')
    const initialTableParams = {
        first: 0,
        rows: 10,
        sortField: "taskName",
        page: 0,
        sortOrder: 1,
        multiSortMeta: [],
        filters: {
            taskName: {
                value: "",
                matchMode: "contains"
            },
            groupUuid: {
                value: null,
                matchMode: "in"
            },
            localeUuid: {
                value: null,
                matchMode: "in"
            },
            workflowUuid: {
                value: null,
                matchMode: "in"
            },
            onStepUuid: {
                value: null,
                matchMode: "in"
            },
            taskDueDate: {
                value: null,
                matchMode: "equals"
            },
            targetDate: {
                value: null,
                matchMode: "equals"
            },
            lastStepHistoryAt: {
                value: null,
                matchMode: "equals"
            },
            onStep: {
                value: null,
                matchMode: "equals"
            },
            queued: {
                value: null,
                matchMode: "equals"
            },
            actionRequired: {
                value: null,
                matchMode: "equals"
            },
            isComplete: {
                value: false,
                matchMode: "equals"
            },
            transition: {
                value: null,
                matchMode: "equals"
            },
            showDetails: {
                value: null,
                matchMode: "equals"
            }
        }
    }
    const [selectedProject, setSelectedProject] = useState(null)
    const [tableParams, setTableParams] = useState(state?.tableParams ?? initialTableParams)
    const {
        tasks,
        total,
        refetchTasksView,
        getTasksViewError,
        isFetchedTasksView
    } = useTasksView({ tableParams, projectUuid: selectedProject?.projectUuid })
    useEffect(() => {
        setIsFetchTasksLoading(true)
        const fetchInitialData = async () => {
            try {
                ProjectService.find({ projectName }).then(response => {
                    setSelectedProject(state?.selectedProject ?? response.data[0]);
                })

            } catch (err) {
                console.error(err);
                setError(err?.response?.data || 'Error');
            }
        };
        fetchInitialData();
    }, []);

    useEffect(() => {
        if (selectedProject) {
            fetchTasks()
            getProjectAssignedRoles(selectedProject.projectUuid).then(response => {
                setAssignedRoleUuids(response.data.map(assignedRole => assignedRole.roleUuid))
            })
            getTasksFilterFieldsService({ projectUuid: selectedProject.projectUuid }).then(response => {
                setLocales(response.data.locales)
                setGroups(response.data.groups)
                setCategories(response.data.categories)
            })
            WorkflowService.find({ projectUuid: selectedProject.projectUuid }).then(response => setWorkflows(response.data))
            MessageService.find({ projectUuid: selectedProject.projectUuid }).then(response => setMessages(response.data))
            WorkflowStepService.find({ projectUuid: selectedProject.projectUuid }).then(response => setWorkflowSteps(response.data))
        }
    }, [selectedProject, tableParams])

    const fetchTasks = async () => {
        setIsFetchTasksLoading(true)
        setError(false)
        refetchTasksView().then(() => {
            setIsFetchTasksLoading(false);
        })
    }
    useEffect(() => {
        if (getTasksViewError) {
            setError(getTasksViewError.response?.data ? getTasksViewError.response?.data : 'Something went wrong while fetching tasks.')
        }
    }, [getTasksViewError])
    useEffect(_ => {
        if (tasks && workflows && workflowSteps && messages && assignedRoleUuids && locales && groups && categories && isFetchedTasksView) {
            handleResponse()
        }
    }, [tasks, workflows, workflowSteps, messages, assignedRoleUuids, locales, groups, categories, isFetchedTasksView])


    function handleResponse() {
        setError(false)
        let tasksTemp = JSON.parse(JSON.stringify(tasks))
        tasks.forEach((task, index) => {
            tasksTemp[index].projectName = task?.project?.projectName
            tasksTemp[index].groupName = groups.find(group => group.groupUuid === task.groupUuid)?.groupName
            tasksTemp[index].categoryName = categories.find(category => category.categoryUuid === task.categoryUuid)?.categoryName
            tasksTemp[index].localeName = locales.find(locale => locale.localeUuid === task.localeUuid)?.localeName
            tasksTemp[index].dateCreated = new Date(task?.createdAt).toLocaleDateString()
            tasksTemp[index].stepName = workflowSteps.find(workflowStep => workflowStep.stepUuid === task.onStepUuid)?.stepName
            let workflow = workflows.find(workflow => workflow.workflowUuid === task.workflowUuid)
            tasksTemp[index].workflowName = workflow.workflowName

            let assignedSteps = workflow.assignedSteps
            let statusDenominator = assignedSteps.length
            let statusNumerator = assignedSteps.indexOf(task.onStepUuid) + 1
            tasksTemp[index].onStepNumber = statusNumerator
            tasksTemp[index].totalStepNumber = statusDenominator
            tasksTemp[index].isComplete = (statusNumerator === statusDenominator)
            tasksTemp[index].showDetails =
                <a href={`/${projectName}/tasks/${task.taskUuid}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <div className='flex justify-center'>
                        <PrimaryButton buttonText='View' onClick={() => { }} />
                    </div>
                </a>

            let allConnections = workflow.connections
            let connections = allConnections.filter(connection => connection.fromStep === task.onStepUuid)
            //status numerator is actually one ahead of index, looking one ahead
            let connectionsToNextStep = connections.filter(connection => assignedSteps.indexOf(connection.toStep) === statusNumerator)

            let connectionsToActOnThisStep = connections.filter(connection => {
                return connection.fromStep === task.onStepUuid &&
                    connection.roleAccess.some(roleUuid => assignedRoleUuids.includes(roleUuid));
            });
            tasksTemp[index].actionRequired = connectionsToActOnThisStep.length > 0

            const workflowStep = workflowSteps.find(workflowStep => workflowStep.stepUuid === task.onStepUuid)
            tasksTemp[index].queued = false
            if (workflowStep.stepQueues) {
                let queueObject = workflowStep.stepQueues.find(stepQueue => stepQueue.localeUuid === tasksTemp[index].localeUuid && stepQueue.groupUuid === tasksTemp[index].groupUuid)
                if (queueObject) {
                    tasksTemp[index].queued = queueObject.taskQueue.includes(tasksTemp[index].taskUuid)
                }
            }
            tasksTemp[index].lastStepHistoryAt = task.stepHistory[task.stepHistory.length - 1]?.updatedBy?.updatedAt;

            let connection
            if (connectionsToNextStep.length > 0) {
                connection = connectionsToNextStep[0]
                let roleAccess = connection.roleAccess
                let isRenderMoveStepButton = assignedRoleUuids.some(roleUuid => roleAccess.includes(roleUuid)) && !tasksTemp[index].queued

                let messageHTML = ''
                if (connection.messageUuid)
                    messageHTML = messages.find(message => message.messageUuid === connection.messageUuid).messageHTML

                if (isRenderMoveStepButton) {
                    tasksTemp[index].transition =
                        <Transition
                            task={task}
                            error={error}
                            setError={setError}
                            workflow={workflow}
                            messageHTML={messageHTML}
                            connection={connection}
                            fetchTasks={fetchTasks}
                            workflowSteps={workflowSteps} />
                }
            }
        })
        setTasksRowsToRender(tasksTemp)
    }

    useEffect(() => {
        // ensure set isFetchTasksLoading right after tasksRowsToRender changes
        if (isFetchTasksLoading) {
            setIsFetchTasksLoading(false)
        }
    }, [tasksRowsToRender])

    return (
        <IsNowLoading isNowLoading={false} margin='64px' error={error} setError={setError}>
            <ProjectPageHeading margin='64px' pageTitle=''>
                <div className='task-list mt1'>
                    <DataTable
                        data={tasksRowsToRender}
                        total={total}
                        loading={isFetchTasksLoading}
                        selectedProject={selectedProject}
                        setSelectedProject={setSelectedProject}
                        groups={groups && selectedProject ? groups.filter(group => group.projectUuid === selectedProject.projectUuid) : []}
                        locales={locales && selectedProject ? locales.filter(locale => locale.projectUuid === selectedProject.projectUuid) : []}
                        workflows={workflows && selectedProject ? workflows.filter(workflow => workflow.projectUuid === selectedProject.projectUuid) : []}
                        workflowSteps={workflowSteps && selectedProject ? workflowSteps.filter(step => step.projectUuid === selectedProject.projectUuid) : []}
                        tableParams={tableParams}
                        initialTableParams={initialTableParams}
                        setTableParams={setTableParams}
                        assignedRoleUuids={assignedRoleUuids}
                    />
                </div>
            </ProjectPageHeading>
        </IsNowLoading>
    )
}

export default TasksList
