import { useState } from 'react'
import PrimaryButton from '../../Shared/PrimaryButton'

import TransitionModal from '../TransitionModal'

function Connection(props){

    const step = props.step
    const error = props.error
    const setError = props.setError
    const messages = props.messages
    const workflowSteps = props.workflowSteps
    const isOnThisStep = props.isOnThisStep
    const fetchTask = props.fetchTask
    const task = props.task
    const taskPermissions = props.taskPermissions
    const connection = props.connection
    const isTaskQueuedInCurrentStep = props.isTaskQueuedInCurrentStep

    const toStepUuid = connection.toStep
    const roleAccess = connection.roleAccess
    const messageUuid = connection?.messageUuid

    let messageHTML = ''
    if (messageUuid) messageHTML = messages.find(message => message.messageUuid === messageUuid).messageHTML

    const assignedRoleUuids = taskPermissions.assignedRoleUuids

    const [renderTransitionModal, setRenderTransitionModal] = useState(false)


    let stepTo = workflowSteps.find(workflowStep => workflowStep.stepUuid === toStepUuid)
    let buttonText = `Move to ${stepTo?.stepName ?? 'next step'}`
    let isRenderMoveStepButton = isOnThisStep && assignedRoleUuids.some(roleUuid => roleAccess.includes(roleUuid)) && !isTaskQueuedInCurrentStep

    return (
        <>
        {renderTransitionModal && 
            <TransitionModal 
                stepFrom={step}
                stepTo={stepTo}
                task={task}
                error={error}
                setError={setError}
                connection={connection}
                fetchTasks={fetchTask}
                messageHTML={messageHTML}
                setRenderTransitionModal={setRenderTransitionModal}
            />}
        {isRenderMoveStepButton &&
            <div className='mt3 mr3 pointer'>
                    <PrimaryButton 
                        buttonText={buttonText}
                        onClick={_ => setRenderTransitionModal(true) } />
            </div>}
        </>
    )
}

export default Connection