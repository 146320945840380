function MenuBar(props){

    return (
        <div className='bg-primary' style={{height: '50px', position: 'sticky', top: '0', zIndex: '40'}}>
          <div className='flex justify-between'>
            {props.children}
           </div>
        </div>
    )
}

export default MenuBar