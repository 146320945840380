import React, { useEffect } from 'react'
import PrimaryButton from "./PrimaryButton"
import NowLoading from "./NowLoading"
import reactHtmlParser from 'react-html-parser'

function CreateCancelSubmitLoading(props) {

    const {
        isSomethingWentWrong,
        isNowLoading,
        createButtonText,
        errorMessage,
        handleCreateResourceClick,
        handleCloseErrorBoxClick,
        buttonPosition,
        buttonRef
    } = props

    let flexDirection = 'flex-row'
    if (buttonPosition === 'left') flexDirection = 'flex-start'
    if (buttonPosition === 'right') flexDirection = 'flex-row-reverse'
    if (buttonPosition === 'centered') flexDirection = 'justify-center'

    useEffect(() => {
        if (!isSomethingWentWrong && !isNowLoading && buttonRef?.current) {
            buttonRef.current.focus()
        }
    }, [isSomethingWentWrong, isNowLoading, buttonRef])

    return (
        <div style={{ paddingTop: '10px', height: '40px' }}>
            {!isSomethingWentWrong && !isNowLoading &&
                <div className={`flex ${flexDirection}`}>
                    <div style={{ height: 'auto' }} className='create-button'>
                        <PrimaryButton ref={buttonRef} onClick={handleCreateResourceClick} buttonText={createButtonText} />
                    </div>
                </div>}
            {!isSomethingWentWrong && isNowLoading &&
                <div className='flex justify-center'>
                    <NowLoading />
                </div>}
            {isSomethingWentWrong &&
                <div className='ba b--dark-red pa1 bw1 dark-red flex justify-between items-center'>
                    {reactHtmlParser(errorMessage)}
                    <div className='b pointer f4 mr1' onClick={handleCloseErrorBoxClick}>&times;</div>
                </div>}
        </div>
    )
}

export default CreateCancelSubmitLoading