import { useLocation, useNavigate } from 'react-router-dom'
import Crumb from './Crumb'
import backArrow from '../Shared/backArrow.svg'

function BreadCrumbBar(){

    const location = useLocation()
    const navigateTo = useNavigate()
    
    let breadCrumbArray = [location.pathname.split('/')][0]
    breadCrumbArray[0] = 'Home'

    function handleBackToClick(){
        let locationPathnameSplit = location.pathname.split('/')
        locationPathnameSplit.pop()
        let newLocation = locationPathnameSplit.join('/')
        navigateTo(newLocation, { state: location.state })
    }

    let breadCrumbs = breadCrumbArray.map((crumb, crumbIndex) => { 
        return (
            <Crumb key={crumbIndex} crumb={crumb} crumbIndex={crumbIndex}/>
    )})

    let position = 'sticky'
    let top = '0px'

    if (location.pathname.includes('messages')){
        position = 'fixed'
        top = '50px'
    }

    return (<>
        {location.pathname !== '/' &&
            <div className='bg-moon-gray flex' style={{height: '50px', position, top, zIndex: '40', width: '100%'}}>
                <div className='flex flex-row pointer f5 back-button breadcrumb-back-width' style={{height: '50px'}} onClick={handleBackToClick}>
                    <div className='ml5 ml3-m flex items-center' style={{width: '17px', height: '50px'}}>
                        <img src={backArrow} alt="back arrow"/>
                    </div>
                    <div className='flex items-center' style={{height: '50px'}}>
                        <div className='ml2 back-button'>Back</div>
                    </div>
                </div>
                <div className='flex flex-row items-center mr1' style={{height: '50px'}}>
                    {breadCrumbs}
                </div>
            </div>}
        {location.pathname === '/' && 
            <div className='bg-moon-gray flex' style={{height: '50px', position, top, zIndex: '40', width: '100%'}}>
                <div className='flex items-center pointer underline-hover home-button breadcrumb-home-margin-left' onClick={_ => window.location.reload()}>
                    Home
                </div>
            </div>}
        </>
    )
}

export default BreadCrumbBar