import { signOutService } from '../Services/AuthenticationServices'

const SaveTokenInterceptor = (axiosInstance) => {
    // const navigateTo = useNavigate()
    axiosInstance.interceptors.response.use((response) => {
        const token = response.headers["authorization"]
        if (token) {
            localStorage.setItem('token', token)
        }
        return response
    }, async (error) => {
        if (error?.response?.status === 401) {
            await signOutService()
            localStorage.clear();
            window.location.replace("/signin");
        } else {
            //dispatch error in a more user friendly manner
        }
        return Promise.reject(error)
    });
};

export default SaveTokenInterceptor;