function NameValuePair(props){

    const name = props.name
    const value = props.value

    return (
        <div className='flex flex-row mt3 f4'>
            <div className='primary name-value-pair-name' style={{width: '100px'}}>{name}</div>
            <div className='flex flex-row flex-wrap name-value-pair-value' style={{width: '850px'}}>{value}</div>
        </div>
    )
}

export default NameValuePair