import { useState, useRef, useEffect } from 'react'
import reactHtmlParser from 'react-html-parser'
import Step from './Step'
import Modal from '../Shared/Modal'
import ArrowDown from './ArrowDown'
import CreateCancelSubmitLoading from '../Shared/CreateCancelSubmitLoading'
import PrimaryButton from "../Shared/PrimaryButton"

import ImportantAction from './ImportantAction'
import { applyTransitionAndActionsHelper } from './applyTransitionAndActionsHelper'
import { checkBorrowerTransitionDataService, generateDiffreportService, checkTargetFilesService } from '../Services/CodeCommitServices'
import { updateOnStepForTaskService } from '../Services/TaskViewServices'

function TransitionModal(props) {
    const stepFrom = props.stepFrom
    const stepTo = props.stepTo
    const task = props.task
    const taskUuid = task.taskUuid
    const error = props.error
    const setError = props.setError
    const messageHTML = props.messageHTML
    const fetchTasks = props.fetchTasks
    const setRenderTransitionModal = props.setRenderTransitionModal
    const connection = props.connection
    const localeUuid = task.localeUuid
    const projectName = task?.project?.projectName?.toLowerCase()
    const connectionUuid = connection.connectionUuid
    const actionValues = connection.actions.map(action => action.value)
    const isEmail = connection.isEmail ?? false
    const emailTemplateUuid = connection.emailTemplateUuid ?? 'default'
    const isCheckCommit = actionValues.includes('checkCommit')
    const isCheckComplete = actionValues.includes('checkComplete')
    const isRemoveSource = actionValues.includes('removeSource')
    const isCopy = actionValues.includes('copy')
    const isCopyAuto = actionValues.includes('copyAuto')
    const isSelfCopy = actionValues.includes('selfCopy')
    const isSelfCopyWithConfirmation = actionValues.includes('selfCopyWithConfirmation')
    const isCopyFinal = actionValues.includes('copyFinal')
    const isUpload = actionValues.includes('upload')
    const isUploadMandatory = actionValues.includes('uploadMandatory')
    const isAddSourceAtNextStep = actionValues.includes('addSourceAtNextStep')
    const isCreatePreviewTag = actionValues.includes('createPreviewTag')
    const isBorrower = actionValues.includes('borrower')
    const isDiff = actionValues.includes('diff')
    const isCopyBackwards = actionValues.includes('copyBackwards')
    const applyTransitionButtonRef = useRef(null);
    const okButtonRef = useRef(null)
    const [isNowLoading, setIsNowLoading] = useState(false)
    const [isCheckingActions, setIsCheckingActions] = useState(false)
    const [isRenderOkButton, setIsRenderOkButton] = useState(false)
    const [isTransitionError, setIsTransitionError] = useState(false)
    const [allTargetFilesRecentlySavedStatus, setAllTargetFilesRecentlySavedStatus] = useState('loading')
    const [allTranslationsCompleteStatus, setAllTranslationsCompleteStatus] = useState('loading')
    const [copyFileSelfStatus, setCopyFileSelfStatus] = useState('loading')
    const [copyFileStatus, setCopyFileStatus] = useState('loading')
    const [copyBackwardsStatus, setCopyBackwardsStatus] = useState('loading')
    const [copyFileFinalStatus, setCopyFileFinalStatus] = useState('loading')
    const [removeSourceStatus, setRemoveSourceStatus] = useState('loading')
    const [addSourceAtNextStepStatus, setAddSourceAtNextStepStatus] = useState('loading')
    const [createPreviewTagStatus, setCreatePreviewTagStatus] = useState('loading')
    const [validateDiffReportStatus, setValidateDiffReportStatus] = useState('loading')
    const [updateBorrowerLocaleStatus, setUpdateBorrowerLocaleStatus] = useState('loading')
    const [arrangeTmxFilesStatus, setArrangeTmxFilesStatus] = useState('loading')
    const [comment, setComment] = useState('')
    const [checkedCopyWithConfirmation, setCheckedCopyWithConfirmation] = useState(false)
    const fileInput = useRef(null)
    const [isTargetFilesExist, setIsTargetFilesExist] = useState(false)
    const [checktargetFilesLoading, setCheckTargetFilesLoading] = useState(false)

    useEffect(() => {
        if (isRenderOkButton && okButtonRef.current) {
            okButtonRef.current.focus();
        }
    }, [isRenderOkButton, okButtonRef]);
    useEffect(() => {
        if (isSelfCopyWithConfirmation) {
            const checkTargetFiles = async () => {
                setCheckTargetFilesLoading(true)
                try {
                    const response = await checkTargetFilesService({
                        projectName,
                        stepUuid: stepFrom.stepUuid,
                        localeUuid,
                        taskUuid,
                    });
                    setIsTargetFilesExist(response.data.checkResult);
                    if (response.data.checkResult === false) {
                        setCheckedCopyWithConfirmation(true);
                    }
                } catch (error) {
                    console.error('Check target files failed:', error);
                    setIsTargetFilesExist(false);
                    setCheckedCopyWithConfirmation(true);
                }
                setCheckTargetFilesLoading(false)
            }
            checkTargetFiles();
        };
    }, [isSelfCopyWithConfirmation]);

    async function handleMoveStepClick() {
        if (!fetchTasks) return
        setIsNowLoading(true)
        let needRenderOkButton = false

        let formData = new FormData();
        formData.append('stepTo', stepTo.stepName)
        formData.append('fromStepUuid', stepFrom.stepUuid)
        formData.append('toStepUuid', stepTo.stepUuid)
        formData.append('taskUuid', taskUuid)
        formData.append('taskName', task.taskName)
        formData.append('connectionUuid', connectionUuid)
        formData.append('comment', comment)
        formData.append('emailTemplateUuid', emailTemplateUuid)
        formData.append('isEmail', isEmail)
        formData.append('isFromStepQueueEnabled', stepFrom.queueEnabled ?? 'false')
        if (fileInput.current) {
            for (let i = 0; i < fileInput.current.files.length; i++) {
                formData.append("multiFiles", fileInput.current.files[i])
            }
        }

        if (isUploadMandatory) {
            if (fileInput.current.files.length === 0) {
                // setError('You must upload a file to apply this transition')

                let ele = document.getElementById('fileError');
                ele.innerHTML = '(Error: You must upload a file)';

                setIsNowLoading(false)
                return
            }
        }

        const fromStepName = stepFrom.stepName.toLowerCase().replace(/ /g, '-')

        if (isCheckCommit ||
            isCheckComplete ||
            isRemoveSource ||
            isCopy ||
            isCopyAuto ||
            isSelfCopy ||
            isSelfCopyWithConfirmation ||
            isCopyFinal ||
            isAddSourceAtNextStep ||
            isEmail ||
            isBorrower ||
            isDiff ||
            isCopyBackwards
        ) {
            setIsCheckingActions(true)
            needRenderOkButton = true
        }
        try {
            const transitionData = [{
                connection,
                stepFrom,
                stepTo,
                task
            }]
            // apply general actions for current locale workflow
            const commitId = await applyTransitionAndActionsHelper({
                transitionData,
                isBorrowerLocale: false,
                setAllTargetFilesRecentlySavedStatus,
                setAllTranslationsCompleteStatus,
                setRemoveSourceStatus,
                setCopyFileSelfStatus,
                setCopyFileStatus,
                setCopyBackwardsStatus,
                setCopyFileFinalStatus,
                setAddSourceAtNextStepStatus,
                setArrangeTmxFilesStatus,
                checkedCopyWithConfirmation
            })
            if (isCreatePreviewTag) {
                setCreatePreviewTagStatus('done')
            }
            formData.append('commitId', commitId)
            // if there are borrower locales, get the borrower locales data and apply actions for them
            if (isBorrower) {
                const response = await checkBorrowerTransitionDataService({
                    projectName,
                    localeUuid,
                    fromStepName,
                    taskUuid
                })
                if (response.data.borrowerTransitionData) {
                    const transitionData = response.data.borrowerTransitionData
                    await applyTransitionAndActionsHelper({
                        transitionData,
                        isBorrowerLocale: true,
                        setAllTargetFilesRecentlySavedStatus,
                        setAllTranslationsCompleteStatus,
                        setRemoveSourceStatus,
                        setCopyFileSelfStatus,
                        setCopyFileStatus,
                        setCopyBackwardsStatus,
                        setCopyFileFinalStatus,
                        setAddSourceAtNextStepStatus,
                        setArrangeTmxFilesStatus,
                        checkedCopyWithConfirmation
                    })
                    setUpdateBorrowerLocaleStatus('done')
                }
            }
            if (isDiff && connection.diffComparedStep && connection.diffComparedStep) {
                const diffReportFileName = `diff_report_${task.taskName}_${connection.diffComparedStep.label}_${connection.diffOriginalStep.label}_${Date.now().toString()}.html`
                await generateDiffreportService({
                    taskUuid,
                    fromStepUuid: stepFrom.stepUuid,
                    editStepUuid: connection.diffComparedStep.value,
                    originalStepUuid: connection.diffOriginalStep.value,
                    commitId,
                    fileName: diffReportFileName
                })
                setValidateDiffReportStatus('done')
                const diffReportFileObj = {
                    originalname: diffReportFileName,
                    key: diffReportFileName,
                    editStepUuid: connection.diffComparedStep.value,
                    originalStepUuid: connection.diffOriginalStep.value,
                }
                formData.append('diffReportFileObj', JSON.stringify(diffReportFileObj))
            }

            await updateOnStepForTaskService(taskUuid, formData)
        } catch (error) {
            console.log('Something went wrong', error)
            setError(error.response?.data ?? 'Something went wrong')
            setIsTransitionError(true)
        } finally {
            if (!needRenderOkButton) {
                await fetchTasks()
                setIsNowLoading(false)
                setRenderTransitionModal(false)
            } else {
                setIsRenderOkButton(true)
            }
            setIsNowLoading(false)
        }
    }

    async function handleOkButtonClicked() {
        setIsCheckingActions(false)
        setRenderTransitionModal(false)
        if (!isTransitionError) {
            await fetchTasks()
        }
        setIsTransitionError(false)
    }

    async function handleCloseTransitionModalClicked() {
        setRenderTransitionModal(false)
        if (isCheckingActions && isRenderOkButton && !isTransitionError) {
            await fetchTasks()
        }
        setIsTransitionError(false)
    }

    function handleCommentChange(e) {
        setComment(e.target.value)
    }

    function handleCheckCopyConfirmationChange() {
        setCheckedCopyWithConfirmation(!checkedCopyWithConfirmation)
    }

    return (
        <>
            <Modal modalTitle='Task transition' onClickClose={handleCloseTransitionModalClicked}>
                <div className='pl2 pr2 pb2'>
                    {!isCheckingActions &&
                        <div>
                            <p className='dark-gray mb4'>You are about to action the following transition on the task <strong>{task.taskName}</strong>.</p>
                            <Step step={stepFrom} bg='bg-light-secondary' />
                            <ArrowDown />
                            <Step step={stepTo} bg='bg-white' />
                            {messageHTML &&
                                <div className='mt4'>
                                    {reactHtmlParser(messageHTML)}
                                </div>}
                            <div className='mt4'>
                                <p className='b mb1'>Comment:</p>
                                <textarea
                                    className='transitionTextarea'
                                    style={{ width: '100%', padding: '10px', border: '1px solid #00699F', borderRadius: '0.25rem' }}
                                    cols='80'
                                    rows='3'
                                    maxLength='2000'
                                    name='comment'
                                    onChange={handleCommentChange}
                                    value={comment}
                                    placeholder='You can add a comment for this transition.' />
                            </div>
                            {isSelfCopyWithConfirmation && isTargetFilesExist &&
                                <div className='mt-2'>
                                    <label>
                                        <input type="checkbox" checked={checkedCopyWithConfirmation} onChange={handleCheckCopyConfirmationChange} className='mr-2' />
                                        IMPORTANT: Check this box if you want to use the translations/target files from <strong>the previous step</strong>.
                                        (We have detected existing files at this step, checking this will replace them)
                                    </label>
                                </div>
                            }
                            {(isUpload || isUploadMandatory) &&
                                <div className='mt1'>
                                    <p className='b mb1'>{`Upload files${isUploadMandatory ? '*' : ''}:`}</p>
                                    <div className='mt2'>
                                        <input className='upload-files-button' type="file" name="multiFiles" ref={fileInput} multiple accept=".xlsx, .docx, .pdf, .doc, .xls, .csv, .xlsm" />
                                        <span className='b dark-red' id='fileError' />
                                    </div>
                                </div>
                            }
                            {!error && !checktargetFilesLoading &&
                                <div style={{ marginTop: '45px' }}>
                                    <CreateCancelSubmitLoading
                                        buttonRef={applyTransitionButtonRef}
                                        isSomethingWentWrong={false}
                                        isNowLoading={isNowLoading}
                                        createButtonText={<div className='pl3 pr3 pt1 pb1 b'>Apply transition</div>}
                                        errorMessage=''
                                        handleCreateResourceClick={handleMoveStepClick}
                                        handleCloseErrorBoxClick={_ => { }}
                                        buttonPosition='centered' />
                                </div>}
                            {checktargetFilesLoading && <div style={{ marginTop: '45px' }}>
                                Checking if current step has target files...
                            </div>}
                        </div>}
                    {isCheckingActions &&
                        <div className='flex flex-column justify-between' style={{ width: '620px', minHeight: '310px' }}>
                            <div className='flex flex-column align-right'>
                                <p className='dark-gray'>Please wait while some important actions on this step are completed.</p>
                                <p className='dark-gray'><strong>This may take several seconds.</strong> Please do not leave this page until this is complete.</p>
                                {isCheckCommit && <ImportantAction actionDescription='Checking target files were recently saved' status={allTargetFilesRecentlySavedStatus} />}
                                {isCheckComplete && !checkedCopyWithConfirmation && <ImportantAction actionDescription='Checking all translations are complete' status={allTranslationsCompleteStatus} />}
                                {isSelfCopy && <ImportantAction actionDescription='Self-copying translation files within current repository' status={copyFileSelfStatus} />}
                                {isSelfCopyWithConfirmation && <ImportantAction actionDescription='Self-copying translation files within current repository' status={copyFileSelfStatus} />}
                                {isCopy && <ImportantAction actionDescription='Copying translation files between repositories' status={copyFileStatus} />}
                                {isCopyAuto && <ImportantAction actionDescription='Copying translation files between repositories' status={copyFileStatus} />}
                                {isCopyFinal && <ImportantAction actionDescription='Copying translation files to the final repository' status={copyFileFinalStatus} />}
                                {isCopyBackwards && <ImportantAction actionDescription='Coping translation files backwards' status={copyBackwardsStatus} />}
                                {isRemoveSource && <ImportantAction actionDescription='Removing the availability of current task in the current step' status={removeSourceStatus} />}
                                {isAddSourceAtNextStep && <ImportantAction actionDescription='Making the current task available in the next step' status={addSourceAtNextStepStatus} />}
                                {isCreatePreviewTag && <ImportantAction actionDescription='Creating tag in previewer' status={createPreviewTagStatus} />}
                                {isBorrower && <ImportantAction actionDescription='Updating borrower locales workflow' status={updateBorrowerLocaleStatus} />}
                                {isDiff && <ImportantAction actionDescription='Validating data for generating diff report' status={validateDiffReportStatus} />}
                                {(stepFrom.queueEnabled || stepTo.queueEnabled) && <ImportantAction actionDescription='Rearranging translation files' status={arrangeTmxFilesStatus} />}
                                {isRenderOkButton && <p><strong>Task transition is completed.</strong></p>}
                            </div>
                            <div>
                                {isRenderOkButton && <PrimaryButton ref={okButtonRef} onClick={handleOkButtonClicked} buttonText='OK' />}
                            </div>
                        </div>}
                </div>
            </Modal>
        </>
    )
}

export default TransitionModal