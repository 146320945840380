import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import PrimaryButton from '../../../Shared/PrimaryButton'
import PrimaryButtonNoClick from '../../../Shared/PrimaryButtonNoClick'
import NowLoading from '../../../Shared/NowLoading'

import { deleteProjectService } from '../../../Services/WorkflowAppServices'

function DeleteProjectDetails(props){
    const navigateTo = useNavigate()

    const { projectName } = useParams()

    const [typedProjectNameToDelete, settypedProjectNameToDelete] = useState('')
    const [displayDeleteButton, setDisplayDeleteButton] = useState(false)
    const [isNowLoading, setIsNowLoading] = useState(false)

    function updatetypedProjectNameToDelete(e){
        const typedSessionName = e.target.value
        settypedProjectNameToDelete(typedSessionName)
        typedSessionName === projectName ? setDisplayDeleteButton(true) : setDisplayDeleteButton(false)
        e.preventDefault()
    }

    async function handleDeleteProjectButtonClick(){

        setIsNowLoading(true)
        let serviceProps = { projectName }
        let response = await deleteProjectService(serviceProps)
        // setTriggerProjectListRefresh(prevValue => !prevValue)
        // TODO handle error case
        // if (response.status === 400) {
        //     setIsSomethingWentWrong(true)
        //     setProjectName('')
        // }

        if (response.status === 200) {
            navigateTo('/projects')
        }
    }

    return (
        <div className='pa1 pl2 pr2'>            
            <div className='dark-gray mb4'>
                <p className='b dark-red'>&#9888; All data will be deleted for this project.</p>
                <p className='b dark-red'>Please make sure you know what you are doing before proceeding.</p>
                <p>To confirm, type the project code <b>{projectName}</b> below and click delete.</p>
                <div className='mt3'>
                    <label className="mr1">Project name:</label>
                    <input 
                        className='tc delete-session-input' 
                        value={typedProjectNameToDelete} 
                        onChange={updatetypedProjectNameToDelete}
                        style={{width: '320px'}}/>
                </div>
            </div>
            <div style={{height: '50px'}}>
                {isNowLoading && 
                    <div className='flex justify-center' >
                        <NowLoading />
                    </div>}
                {!isNowLoading &&
                <div className="flex flex-start items-center mt3">
                    <div onClick={handleDeleteProjectButtonClick} className='delete-session-final'>
                        {displayDeleteButton && <PrimaryButton buttonText='Delete' />}
                    </div>
                    <div>
                        {!displayDeleteButton && <PrimaryButtonNoClick buttonText='Delete' />}
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default DeleteProjectDetails;