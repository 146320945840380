import { useQuery } from '@tanstack/react-query'
import Services from '../Services/TaskService'

export const useTasks = (projectUuid) => {
    const getTasksQuery = useQuery({
        queryKey: ['tasks', projectUuid],
        queryFn: async () => {
            const response = await Services.find({ projectUuid })
            return response.data
        },
        staleTime: 1000,
        refetchOnWindowFocus: false,
    })
    return {
        tasks: getTasksQuery.data,
    }
}