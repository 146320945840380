function InitialsInCircle(props){

    const initials = props.initials
    const onClick = props.onClick

    return (
        <div onClick={onClick} className='show-account-menu' style={{marginTop: '8px', marginRight: '6px'}}>
            <div className='b--white ba flex justify-center items-center pointer dim' style={{borderRadius: '50%', width: '33px', height: '33px'}}>
                <span className='white' style={{fontSize: '13px', marginTop: '2px'}}>
                    {initials}
                </span>
            </div>
        </div>
    )
}

export default InitialsInCircle