import Cross from "./Cross";

function Modal(props) {
    const onClickClose = props.onClickClose
    const modalTitle = props.modalTitle
    
    return (
            // <div className='bw1 ba b--primary br2 bg-white modal selectedBox shadow-4' onClick={e => e.stopPropagation()}>
            <div className='br3 bg-white modal selectedBox shadow-4'>
                <div className='mt3 mb2 ml3'>
                    <div className="flex justify-between items-center mr2">
                        <div className='primary b f4'>{modalTitle}</div>
                        <Cross onClick={onClickClose} />
                    </div>
                    <div 
                        className='ml2 dark-gray mb4' 
                        style={{overflow: 'auto', maxHeight: '80vh'}}>
                        <div className='mr3'>
                            {props.children}
                        </div>
                    </div>
                </div>           
            </div>
    );
}

export default Modal;