import React, { useState, useEffect, useRef, useCallback } from 'react'
import reactHtmlParser from 'react-html-parser'
import { DataTable } from 'primereact/datatable'
import { classNames } from 'primereact/utils'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Editor } from "primereact/editor"
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import WorkflowStepService from '../../../Services/WorkflowStepService'
import { validateFieldsStartChar } from '../../../Shared/validators'

export default function ItemTable(props) {
    let emptyItem = {
        _id: null,
        stepName: null,
        stepDescription: null,
        stepQueues: [],
        queueEnabled: false,
        projectUuid: props.project.projectUuid
    }
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [item, setItem] = useState(emptyItem)
    const [error, setError] = useState(null)
    const [itemDialog, setItemDialog] = useState(false)
    const [deleteItemDialog, setDeleteItemDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const toast = useRef(null)
    const [projectPermissions, setProjectPermissions] = useState({})
    const [filters] = useState({
        stepName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        stepDescription: { value: null, matchMode: FilterMatchMode.CONTAINS },
        queueEnabled: { value: null, matchMode: FilterMatchMode.EQUALS }
    })
    const [stepDescription, setStepDescription] = useState()
    const [checked, setChecked] = useState(false)

    const fetchItems = useCallback(async () => {
        setLoading(true)
        try {
            let response = await WorkflowStepService.find({ projectUuid: props.project.projectUuid })
            const items = response.data.map(item => {
                return {
                    ...item,
                    queueEnabled: item?.queueEnabled || false
                }
            })
            setItems(items)
        } catch (err) {
            console.error(err)
            setError(err.response.data)
        } finally {
            setLoading(false)
        }
    }, [props.project])

    useEffect(() => {
        setProjectPermissions(props.projectPermissions)
        fetchItems()
    }, [props.projectPermissions, fetchItems])

    const editItem = (item) => {
        setChecked(item?.queueEnabled)
        setItem({ ...item })
        setItemDialog(true)
    }

    const confirmDeleteItem = (item) => {
        setItem(item)
        setDeleteItemDialog(true)
    }
    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false)
    }

    const hideItemDialog = () => {
        setSubmitted(false)
        setItemDialog(false)
    }

    const saveItem = async () => {
        setSubmitted(true)
        const result = validateFieldsStartChar(item)
        if (!result.isValid) {
            toast.current.show({ severity: 'error', summary: 'Invalid value', detail: `The value of ${result.key} cannot starts with =, +, - or @: ${result.value}`, life: 5000 })
            setSubmitted(false)
            return
        }
        item.stepDescription = stepDescription || item.stepDescription
        try {
            if (item.stepUuid) {
                await WorkflowStepService.update(item.stepUuid, item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Updated', life: 3000 })
            } else {
                await WorkflowStepService.create(item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Created', life: 3000 })
            }
            setItemDialog(false)
            setItem(emptyItem)
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 })
        }
    }

    const deleteItem = async () => {
        try {
            await WorkflowStepService.delete(item.stepUuid).then(data => fetchItems())
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 3000 })
            setDeleteItemDialog(false)
            setItem(emptyItem)
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 })
        }

    }
    const itemDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideItemDialog} />
            <Button label="Save" icon="pi pi-check" disabled={item.stepName?.length && !item.stepName?.includes('_') ? false : true} onClick={saveItem} />
        </React.Fragment>
    )

    const deleteItemDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteItem} />
        </React.Fragment>
    )

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || ''
        let _item = { ...item }

        _item[`${name}`] = val

        setItem(_item)
    }

    const onQueueEnabledChange = (e) => {
        let _item = { ...item }
        _item.queueEnabled = e.checked
        setItem(_item)
        setChecked(e.checked)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <>
                    {projectPermissions?.canUpdateWorkflowSteps &&
                        <Button icon="pi pi-pencil" outlined className="mr-2 bg-primary right-0" onClick={() => editItem(rowData)} />}
                    {projectPermissions?.canDeleteWorkflowSteps &&
                        <Button icon="pi pi-trash" outlined severity="danger bg-primary right-0" onClick={() => confirmDeleteItem(rowData)} />}
                </>
            </React.Fragment>
        )
    }

    const openNew = () => {
        setItem(emptyItem)
        setChecked(false)
        setSubmitted(false)
        setItemDialog(true)
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                {projectPermissions?.canCreateWorkflowSteps &&
                    <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
                }
            </div>
        )
    }

    const descriptionBodyTemplate = (rowData) => {
        return reactHtmlParser(rowData.stepDescription)
    }

    const queueEnabledBodyTemplate = (rowData) => {
        const queueEnabled = rowData?.queueEnabled ? 'Yes' : 'No'
        return queueEnabled
    }

    const queueEnabledRowFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };


    const renderEditorHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                <button className="ql-clean" aria-label="Clean"></button>
            </span>
        );
    };

    const editorHeader = renderEditorHeader();

    return (
        <>
            <div className="card" style={{ height: "calc(100vh - 300px)" }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                <DataTable value={items} tableStyle={{}} loading={loading}
                    scrollable scrollHeight="flex"
                    filters={filters} filterDisplay="row"
                    sortField="updatedAt" sortOrder="-1">
                    <Column field="stepName" filter showFilterMenu={false} sortable header="Name"></Column>
                    <Column field="stepDescription" filter showFilterMenu={false} sortable header="Description" body={descriptionBodyTemplate} ></Column>
                    <Column field="queueEnabled" header="Queue enabled" dataType="boolean" style={{ minWidth: '6rem' }} body={queueEnabledBodyTemplate} sortable filter filterElement={queueEnabledRowFilterTemplate} />
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={deleteItemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {item && (
                        <span>
                            Are you sure you want to delete <b>{item.stepName}</b>?
                        </span>
                    )}
                    {error && <small className="p-error">{error}</small>}
                </div>
            </Dialog>

            <Dialog visible={itemDialog} style={{ width: '44em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Details" modal className="p-fluid" footer={itemDialogFooter} onHide={hideItemDialog}>
                <div className="field">
                    <label htmlFor="stepName" className="font-bold">
                        Name
                    </label>
                    <InputText id="stepName" value={item.stepName} onChange={(e) => onInputChange(e, 'stepName')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.stepName })} />
                    {submitted && !item.stepName && <small className="p-error">Name is required.</small>}
                    {item.stepName && item.stepName.includes('_') && <small className="p-error">Step name cannot include '_'.</small>}
                </div>
                <div className="flex align-items-center mb-3">
                    <label htmlFor="queueEnabled" className="font-bold mr-2">
                        Queue enabled
                    </label>
                    <Checkbox id="queueEnabled" value={item?.queueEnabled}
                        onChange={e => onQueueEnabledChange(e)}
                        checked={checked}></Checkbox>
                </div>
                <div className="field">
                    <label htmlFor="stepDescription" className="font-bold">
                        Description
                    </label>
                    <div className="card">
                        <Editor id="stepDescription" value={item.stepDescription} onTextChange={(e) => setStepDescription(e.htmlValue)} headerTemplate={editorHeader} style={{ height: '200px' }} />
                    </div>
                </div>
            </Dialog>
        </>

    )
}
